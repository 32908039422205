<nav class="offcanvas offcanvas-end d-block d-md-none" tabindex="-1" id="mobilemenu" aria-label="mobile menu" *ngIf="isMobile=='true'">
    <div class="d-flex justify-content-end mt-2">
        <button type="button" class="btn-close text-reset px-4 pt-3" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body pt-0">

        <div class="dropdown py-2 px-3" data-cy="userDropdownMenuMobile">
            <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUserMobile" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill fs-5" viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg> {{ userName }}
            </a>
            <ul class="dropdown-menu text-small" aria-labelledby="dropdownUserMobile">
                <li><button class="dropdown-item" (click)="go(myProfileLink)" data-bs-dismiss="offcanvas" aria-label="Close">My Profile</button></li>
                <li><button class="dropdown-item" (click)="go(helpLink)" data-bs-dismiss="offcanvas" aria-label="Close">Help</button></li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" [routerLink]="['/logout']">Sign out</a></li>
            </ul>
        </div>

        <ul class="nav flex-column">
            <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <a class="nav-link text-dark active" aria-current="page" [routerLink]="['/']" data-bs-dismiss="offcanvas" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="1em" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                    <path
                        d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z">
                    </path>
                </svg> Home</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link text-dark" [routerLink]="['/search']" data-bs-dismiss="offcanvas" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                    <path
                        d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                </svg> Apps and services</a>
            </li>
            <li class="nav-item" *ngFor="let i of externalIcons" routerLinkActive="active">
                <a class="nav-link text-dark" (click)="go(getChannelURL(i))" title="{{ i.title }}" (click)="sendGAOrganizationFavoriteClickEvent(i.title)" data-bs-dismiss="offcanvas" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right float-end fs-5" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                    </svg>
                    {{ i.title }} <span *ngIf="isEmailIcon(i) && hasUnreadCount" class="badge rounded-pill text-bg-danger bg-danger">{{unreadCount}}</span>
                </a>
            </li>
            <li class="nav-item" *ngFor="let i of internalIcons" routerLinkActive="active" draggable="false">
                <a class="nav-link text-dark" [routerLink]="['/',getIconLink(i)]" title="{{ i.title }}" data-bs-dismiss="offcanvas" aria-label="Close" (click)="sendGAOrganizationFavoriteClickEvent(i.title)">{{ i.title }}</a>
            </li>
        </ul>
        <div class="h6 ps-3 pt-3 border-top text-uppercase">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
            </svg> Favourites
        </div>
        <ul class="nav flex-column">
            <li *ngFor="let f of favorites; index as i" class="nav-item" routerLinkActive="active" draggable="true" data-bs-dismiss="offcanvas" aria-label="Close"
            [ngClass]="{'list-group-item-success': this.currentDragged == f.id}"
            (click)="sendGAFavoriteClickEvent(f.title)"
            (drag)="onDrag($event)"
            (dragstart)="onDragStart($event)"
            (dragenter)="onDragEnter($event)"
            (dragover)="onDragOver($event)"
            (dragend)="onDragEnd($event)"
            (drop)="onDrop($event)">
                <a id="favorite_{{i}}" class="nav-link text-dark" [routerLink]="['/',f.goname]" title="{{ f.title }}" data-bs-dismiss="offcanvas" aria-label="Close"
                [ngClass]="{'invisible-text': this.currentDragged == f.id}">
                    {{ f.title }}
                </a>
            </li>
        </ul>
        <div class="h6 ps-3 pt-3 border-top text-uppercase" *ngIf="myCollege.length>0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bank2" viewBox="0 0 16 16">
                <path
                    d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
            </svg> My College
        </div>
        <ul class="nav flex-column" *ngIf="myCollege.length>0">
            <li *ngFor="let c of myCollege" class="nav-item" routerLinkActive="active">
                <a class="nav-link text-dark" [routerLink]="['/',c.goname]" title="{{ c.title }}" (click)="sendGAMyCollegeClickEvent(c.title)" data-bs-dismiss="offcanvas" aria-label="Close">{{ c.title }}</a>
            </li>
        </ul>
        <div class="h6 ps-3 pt-3 border-top text-uppercase" *ngIf="recentlyViewed.length>0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
            </svg> Recently Viewed
        </div>
        <ul class="nav flex-column" *ngIf="recentlyViewed.length>0">
            <li *ngFor="let rv of recentlyViewed" class="nav-item" routerLinkActive="active">
                <a class="nav-link text-dark" [routerLink]="['/',rv.goname]" title="{{ rv.title }}" (click)="sendGARecentlyViewedClickEvent(rv.title)" data-bs-dismiss="offcanvas" aria-label="Close">{{ rv.title }}</a>
            </li>
        </ul>

    </div>
</nav>
<nav id="navigation" aria-label="main menu" tabindex="-1" *ngIf="isMobile=='false'">
    <ul class="nav flex-column" aria-label="USask Favorites">
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" aria-current="page" [routerLink]="['/']"><svg xmlns="http://www.w3.org/2000/svg" width="1em" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"></path>
            </svg> Home</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-dark" [routerLink]="['/services']"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                <path
                    d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
            </svg> Apps and services</a>
        </li>
        <li class="nav-item" *ngFor="let i of externalIcons" routerLinkActive="active" data-cy="external-icon-link">
            <a class="nav-link text-dark" href="{{ getChannelURL(i) }}" target="_blank" rel="noopener noreferrer" title="{{ i.title }}" (click)="sendGAOrganizationFavoriteClickEvent(i.title)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right float-end fs-5" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                    <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                </svg>
                {{ i.title }} <span *ngIf="isEmailIcon(i) && hasUnreadCount" class="badge rounded-pill text-bg-danger bg-danger">{{unreadCount}}</span>
            </a>
        </li>
        <li class="nav-item" *ngFor="let i of internalIcons" routerLinkActive="active" data-cy="internal-icon-link">
            <a class="nav-link text-dark" [routerLink]="['/',getIconLink(i)]" title="{{ i.title }}" (click)="sendGAOrganizationFavoriteClickEvent(i.title)">
                {{ i.title }}
            </a>
        </li>
    </ul>
    <div class="h6 ps-3 pt-3 border-top text-uppercase" *ngIf="favorites.length > 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
            <path
                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg> Favourites
    </div>
<!--    <div *ngIf="favorites.length == 0">-->
<!--        <p class="ms-3 text-muted">Favorite channels will appear here</p>-->
<!--    </div>-->
    <ul class="nav flex-column" aria-label="My Favorite links">

        <li *ngFor="let f of favorites; index as i" class="nav-item" routerLinkActive="active" data-cy="favorite-link"
        (click)="sendGAFavoriteClickEvent(f.title)"
        [ngClass]="{'bg-paws': this.currentDragged == f.id}"
        draggable="true"
        (drag)="onDrag($event)"
        (dragstart)="onDragStart($event)"
        (dragenter)="onDragEnter($event)"
        (dragover)="onDragOver($event)"
        (dragend)="onDragEnd($event)"
        (drop)="onDrop($event)">
            <a id="favorite_{{i}}" class="nav-link text-dark" [routerLink]="['/',f.goname]" title="{{ f.title }}"
            [ngClass]="{'invisible-text': this.currentDragged == f.id}"
            >
                {{ f.title }}
            </a>
        </li>
    </ul>
    <div class="h6 ps-3 pt-3 border-top text-uppercase" *ngIf="myCollege.length>0" data-cy="mycollege-header">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bank2" viewBox="0 0 16 16">
            <path
                d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
        </svg> My College
    </div>
    <ul class="nav flex-column" *ngIf="myCollege.length>0" aria-label="My College Links">
        <li *ngFor="let c of myCollege" class="nav-item" routerLinkActive="active" data-cy="mycollege-link">
            <a class="nav-link text-dark" [routerLink]="['/',c.goname]" title="{{ c.title }}" (click)="sendGAMyCollegeClickEvent(c.title)">{{ c.title }}</a>
        </li>
    </ul>
    <div class="h6 ps-3 pt-3 border-top text-uppercase" *ngIf="recentlyViewed.length>0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
        </svg> Recently Viewed
    </div>
    <ul class="nav flex-column" *ngIf="recentlyViewed.length>0" aria-label="Recently viewed links">
        <li *ngFor="let rv of recentlyViewed" class="nav-item" routerLinkActive="active" data-cy="recently-viewed-link">
            <a class="nav-link text-dark" [routerLink]="['/',rv.goname]" title="{{ rv.title }}" (click)="sendGARecentlyViewedClickEvent(rv.title)">{{ rv.title }}</a>
        </li>
    </ul>
    <p class="ps-3 pt-3 mt-3 border-top d-none">
        <small>Last Published:<br />{{ pubDate }}</small><br />
        <small>Revision: {{ revision }}</small>
    </p>

</nav>
