import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from "@angular/core"
import {Observable, of, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {UserService} from "./user.service";
import {AppConfigService} from "../app-config.service";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private userService: UserService,
        private appConfig: AppConfigService
    ) {}

    private isWhitelistlUrl( whitelist: Array<string>, url: string ) {
        let ret = false;
        if ( whitelist && whitelist.length > 0 ) {
            whitelist.forEach((el: string) => {
                if (url.includes(el)) ret = true;
            });
        }
        return ret;
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        let gracefulURLS = [
            "/get-timeline",
            "/get-calendar",

        ];
        let ignoreURLS = [
            "/logout",
            "/log-frontend-error"
        ];

        let me = this;

        return next.handle(req).pipe(
            catchError((err: any) => {
                if(err instanceof HttpErrorResponse) {
                    let url = err.url;
                    let status = err.status;
                    let ok: boolean = err.ok;
                    let message = err.error.message;
                    if ( !this.isWhitelistlUrl( ignoreURLS, url ) ) {
                        if (status == 401) {
                            if (message.includes("IP Mismatch")) {
                                this.router.navigate(["/error"], {queryParams: {errorid: "mismatchip"}});
                                // this.router.navigateByUrl("/error?errorid=mismatchip" );
                            } else if (message.includes("Unauthorized user - Missing Token")) {
                                if ( !me.userService.hasAccessToken() ) {
                                    this.router.navigate(["/error"], {queryParams: {errorid: "notoken"}});
                                }
                            } else if (message.includes("No Tracking Info")) {
                                this.router.navigate(["/error"], {queryParams: {errorid: "notrackinginfo"}});
                            }
                        }

                        if (!this.isWhitelistlUrl(gracefulURLS,url) && !ok && status >= 500) {
                            window.location.href = this.appConfig.getSorryUrl();
                        }
                    }
                }
                return throwError(err);
            }));

    }

}
