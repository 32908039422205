import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {UserService} from "./user.service";
import {CookieService} from "./cookie.service";

/**
 * Service Handler intercepts all HTTP request calls using HttpClient. Appends API authorization token.
 * Requires a Provider declaration in app.module.ts -- ({ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true })
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private cookieService: CookieService,
        private userService: UserService
    ) {}

    /**
     * Intercept HTTP calls and append Authorization header.
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.headers.get("skip"))
            return next.handle(req);

        let newRequest = req.clone({
            setHeaders: {
                'Content-Type':  'application/x-www-form-urlencoded',
            },
            withCredentials: true,
        });
        if ( req.method == "POST" || req.method == "PUT" || req.method == "DELETE" || req.method == "PATCH" ) {
            let xrsfCookie = this.cookieService.getCookie("XSRF-TOKEN");
            newRequest = newRequest.clone( {
                setHeaders: {
                    'X-XSRF-TOKEN': xrsfCookie
                }
            } );
        }
        return next.handle(newRequest);
        // return next.handle(authReq).pipe( tap(() => {},
        //     (err: any) => {
        //         if (err instanceof HttpErrorResponse) {
        //             if (err.status !== 401) {
        //                 return;
        //             }
        //             console.log( 'Auth Intercept' );
        //             // this.router.navigate(["/login"]);
        //         }
        //     }))
        // ;
    }
}
