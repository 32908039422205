<div class="App">
    <app-notification type="sessionTimeout"></app-notification>
    <header class="py-0 border-bottom align-middle fixed-top bg-theme-{{ theme }}">
        <div class="visually-hidden-focusable overflow-hidden border-bottom">
            <a class="d-inline-flex p-2 m-1text-dark text-decoration-none" href="#content">Skip to main content</a>
            <a class="d-none d-md-inline-flex p-2 m-1 text-dark text-decoration-none" href="#navigation">Skip to navigation</a>
        </div>
        <nav class="navbar navbar-expand-lg py-0">
            <div class="container-fluid">
                <a class="navbar-brand border-end me-1 pe-2 my-0 py-2" href="https://usask.ca">
                    <img src="/assets/img/uofs-crest.webp" height="34" class="" alt="University of Saskatchewan" />
<!--                    <img src="/assets/img/uofs-logo.webp" height="34" class="d-lg-block d-none" alt="University of Saskatchewan" />-->
                </a>
                <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="/"><img src="{{ logoImg }}" height="26" style="margin:5px;" alt="Paws Home" /></a>
<!--                        <a class="nav-link d-md-none" href="/"><img src="{{ logoImgMobile }}" height="26" style="margin:5px;" alt="Paws Home" /></a>-->
                    </li>
                    <li>
                        <div class="me-1 pe-2 my-0 py-2 fs-3 d-none d-lg-block">
                        {{ headerText }}<span class="d-none d-md-inline-block" *ngIf="theme == 'NONE'">&nbsp;Environment</span>
                        </div>
                    </li>
                </ul>
                @if ( isLoggedIn() ) {
<!--                <div class="alert alert-success m-1 px-4 py-2 me-auto" id="paws-toast">This application has been updated!. <a href="/" class="btn btn-secondary btn-sm">Reload Now</a></div>-->
                <form class=" mx-auto d-sm-flex d-none" role="search" id="usask-search">
                    <input type="text" class="form-control me-0" placeholder="Search PAWS and Knowledgebase" [formControl]="searchTerm" (keyup.enter)="submitSearch()" />
                    <button class="btn btn-outline-primary" (click)="submitSearch()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16" style="font-size:1.1rem;">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </button>
                </form>
                <button class="btn btn-outline-primary me-0 ms-2 fw-bold d-md-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobilemenu" aria-controls="mobilemenu">
                    Menu</button>
                <ul class="navbar-nav ms-auto d-md-block d-none">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle fw-bold" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill fs-5 text-black" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg> {{ userName }}
                        </a>
                        <ul class="dropdown-menu dropdown-menu-lg-end bg-light position-absolute">
                            <li><a class="dropdown-item" href="{{ myProfileLink }}" target="_blank">My Profile</a></li>
                            <li><a class="dropdown-item" href="{{ helpLink }}" target="_blank">Help</a></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><a class="dropdown-item" [routerLink]="['/logout']">Sign out</a></li>
                        </ul>
                    </li>
                </ul>
                }
            </div>
        </nav>
        <div class="container-fluid d-sm-none">
            <form class="mx-0 my-2 d-flex" role="search" id="usask-search-mobile">
                <div class="input-group">
                <input type="text" class="form-control me-0" placeholder="Search PAWS and Knowledgebase" [formControl]="searchTerm" (keyup.enter)="submitSearch()" />
                <button class="btn btn-primary" (click)="submitSearch()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16" style="font-size:1.1rem;">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                </button>
                </div>
            </form>
        </div>

    </header>
    <div class="d-flex align-items-stretch bd-highlight row m-0" id="contentWrapper">
        <app-navigation isMobile="true"></app-navigation>
        <div class="p-2 pt-3 col-xxl-2 col-lg-3 col-md-4 col-xs-0 d-none d-md-block d-lg-block bg-paws" *ngIf="isLoggedIn()">
            <app-navigation isMobile="false"></app-navigation>
        </div>
        <div class="col-xs-12 p-0" [class.col-lg-9]="isLoggedIn()" [class.col-xxl-10]="isLoggedIn()" [class.col-md-8]="isLoggedIn()">
            <router-outlet></router-outlet>
        </div>

    </div>
    <div id="backtotop">
        <button class="btn btn-lg" (click)="scrollToTop()" *ngIf="showBackToTop()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
            </svg>
        </button>
    </div>
</div>
