
            <div class="d-flex border-bottom">
                <div class="me-auto">
                    <h1 class="h4 d-none d-lg-block py-2 my-1 px-3">Announcements</h1>
                </div>

                <div class="dropdown py-2 px-1">
                    <button href="#" class="d-block btn btn-light dropdown-toggle" id="dropdownMyAnnouncments" data-bs-toggle="dropdown" aria-expanded="false">
                        My Announcements
                    </button>
                    <ul class="dropdown-menu text-small" aria-labelledby="dropdownMyAnnouncments">
                        <li><a class="dropdown-item" [href]="newAnnouncementURL" target="_blank">Post an Announcement</a></li>
                        <li><a *ngIf="hasMyAnnoucements" class="dropdown-item" [href]="myAnnouncementURL" target="_blank">View Announcements</a></li>
                    </ul>
                </div>
                <div class="dropdown py-2 px-1">
                    <button href="#" class="d-block btn btn-light dropdown-toggle" id="announcementSettingsToggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" aria-label="Announcement Settings">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                        </svg>
                    </button>
                    <div class="dropdown-menu pull-right px-4" id="timelinePreferencesDropdown" style="">
<!--                        <button type="button" class="btn-close float-end" data-bs-toggle="dropdown" aria-label="Close"></button>-->
                        <ul class="list-inline" role="menu" aria-labelledby="announcementssettings-label">
                            <li class="dropdown-header ps-0"><div class="h6 text-secondary text-uppercase">Categories</div></li>
                            <li class="list-inline-item paws-w-33 ps-0 m-0" *ngFor="let cat of userCategoryList; let i = index">
                                <label>
                                    <input class="form-check-input" type="checkbox" value="1" [attr.checked]="( cat.optout ? null : 'checked' )" (click)="togglePreference(cat)" />
                                    <span *ngIf="!cat.optout" class="fa fa-check"></span>
                                    {{ cat.name }}
                                </label>
                            </li>
                            <li class="dropdown-divider"></li>
                            <li class="message-header"><div class="h6 text-secondary text-uppercase">Announcement text messages</div></li>
                            <li>
                                <a [href]="myProfileLink" target="_blank">Text Message Settings
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right fs-5 ms-3" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                    </svg>
                                </a>
                            </li>
                            <li class="dropdown-divider"></li>
                            <li class="dropdown-menu-footer mt-3">
                                <div class="row">
                                    <div class="col-sm-10">
                                        <div class="alert alert-secondary d-inline-block p-2" role="alert" *ngIf="prefLoading">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                            </svg> Your preferences are being saved .... Please wait.
                                        </div>
                                        <div class="alert alert-success d-inline-block p-2" role="alert" *ngIf="prefSuccess">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                                <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                                            </svg> Your preferences have been successfully updated !
                                        </div>
                                        <div class="alert alert-danger d-inline-block p-2" role="alert" *ngIf="prefFailed">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
                                                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                            </svg>  Sorry. There was a problem saving your preferences. Please try again or contact IT Support.
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-2 text-end"> -->
                                    <div class="col-md-2 text-end">
                                        <button class="btn btn-primary" (click)="savePreferences()">Save</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="d-grid gap-1 py-1" *ngIf="showLoadPrevious">
                <button class="btn btn-light btn-block load-more mx-4" value="3" (click)="loadPrevious()">Load Previous…</button>
            </div>

            <div *ngIf="timelineView.length>0">
            <article class="d-flex border-bottom p-4"
                     data-cy="timeline-data"
                     *ngFor="let item of timelineView; let i = index"
                     [@openCloseSticky]="isSticky(item) && item.collapsed ? 'closed' : 'open'"
                     [class.isCollapsed]="isSticky(item) && item.collapsed"
                     [class.isShown]="!isShown(item)"
                     [class.newsItem]="isNews(item)"
                     [class.bulletin]="isBulletin(item)"
                     [class.announcement]="isAnnouncement(item)"
                     [class.sticky]="isSticky(item)"
                     [class.alert]="isAlert(item)"
                     [class.m-5]="isSticky(item)"
            >
                <div class="me-4" *ngIf="item.thumb!=''">
                    <div class="article-thumb-wrapper" [ngStyle]="{ 'background-image':'url(' + item.thumb + ')' }"></div>
                </div>
                <div>
                    <div class="small text-muted">
                        <img *ngIf="item.categoryCode=='UOFS'" src="/assets/img/uofs-crest.png" height="16" width="14" class="me-2 d-inline-block" />
                        <svg *ngIf="isAlert(item)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-exclamation-triangle-fill text-danger" viewBox="0 0 16 16" style="width:16px;height:16px;margin-right: 10px;">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                        <b class="message-category" [class.usaskNotice]="item.categoryCode=='UOFS'" [class.text-danger]="item.isAlert">{{ item.category }}</b> - <time class="message-pubdate text-muted">{{ item.pubDate }}</time>
                    </div>
                    <a *ngIf="item.type=='news'" class="message-title btn btn-link px-0 " href="{{ item.link }}" target="_blank">
                        <span id="announcement-title" class="h5">{{ item.title }}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right ms-2" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                        </svg>
                    </a>
                    <button *ngIf="!isNews(item) && !item.isAlert" class="message-title btn btn-link px-0" data-bs-toggle="collapse" [attr.data-bs-target]="'#content_'+item.id" aria-expanded="false" [attr.aria-controls]="'content_'+item.id" (click)="toggleCollapsed(item)">
                        <span [class.h5]="!item.isSticky" [class.h3]="item.isSticky">{{ item.title }}</span>
                    </button>
                    <div *ngIf="item.isAlert" class="message-title px-0" >
                        <span [class.h5]="!item.isSticky" [class.h3]="item.isSticky">{{ item.title }}</span>
                    </div>

                    <div *ngIf="!isNews(item)" class="message-body" [class.collapse]="item.collapsed==true && !item.isSticky" id="content_{{ item.id }}" [attr.aria-labelledby]="'heading_'+item.id" role="article">
                        <div class="d-grid d-flex justify-content-end p-1 mb-3 border-bottom d-none">
                            <button type="button" class="btn-sm btn-close" aria-label="Close" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#content_'+item.id"></button>
                        </div>
                        <div class="itemBodyWrapper" [innerHTML]="item.body"></div>
                        <button *ngIf="item.isSticky && item.actionLabel!=''" class="btn btn-lg btn-primary me-2 mb-2" (click)="doStickyAction(item)">{{ item.actionLabel }}</button>
                        <button data-cy="sticky-dismiss" *ngIf="item.isSticky && item.dismissLabel!=''" class="btn btn-lg btn-outline-secondary mb-2" (click)="doStickyDissmiss(item)">{{ item.dismissLabel }}</button>
                        <hr *ngIf="item.sentBy!=''">
                        <p *ngIf="item.sentBy!=''"><span class="text-muted">Posted by:</span><br> {{ item.sentBy }} </p>
                        <p *ngIf="!isNews(item) && item.attachment != ''" [innerHTML]="item.attachment"></p>
                        <ng-container *ngIf="isBulletin(item)">
                            <p class="message-disclaimer text-muted">
                                The content, message, views or opinions contained in this post are solely those of the poster and are not approved or endorsed by the university and do not necessarily represent the position of the university.
                                <a class="btn btn-link" (click)="showReportForm( item.id )">Report this Announcement</a>
                            </p>
                            <form class="form" *ngIf="showReportFormId == item.id" [formGroup]="reportAnnouncementForm">
                                <hr />
                                <label for="exampleFormControlTextarea1" class="form-label">Please tell us why you are reporting this Announcement:</label>
                                <div>
                                    <label class="radio inline"><input type="radio" name="reason" value="Incorrect" formControlName="reason" />The content is incorrect</label><br />
                                    <label class="radio inline"><input type="radio" name="reason" value="Inappropriate" formControlName="reason" />The content is inappropriate</label>
                                </div>
                                <label>Please provide us with any additional information. Your feedback will help us to take action.</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="comment"></textarea>
                                <button class="btn btn-primary mt-2" (click)="sendReport()">Send</button>
                                <button class="btn btn-light ms-2 mt-2" (click)="cancelReportForm()">Cancel</button>
                                <div class="text-center palg" *ngIf="reportAbuseSending">
                                    <h3>Sending Report...</h3>
                                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    <p class="mtlg">Please wait</p>
                                </div>
                                <div class="text-center palg" *ngIf="reportAbuseSuccess">
                                    <h3>Report Successful !</h3>
                                    <p>Your feedback was sent to PAWS announcers who will review your concern.</p>
                                </div>
                                <div class="text-center palg" *ngIf="reportAbuseError">
                                    <h3>Save Failed !</h3>
                                    <p>An unknown error has prevented your concern from being submitted. You can email your concern to <a href="mailto:paws_announce@usask.ca">paws_announce&#64;usask.ca</a> and we will review your concern. We apologize for the inconvenience.</p>
                                </div>
                            </form>
                        </ng-container>
                    </div>
                </div>
            </article>
            </div>
<!--
            <div class="modal-body">
                <div class="text-center hide palg" id="reportAbuseSending">
                    <h3>Sending Report...</h3><i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i><p class="mtlg">Please wait</p>
                </div>
                <div class="text-center hide palg" id="reportAbuseSuccess">
                    <h3>Report Sucessful !</h3><i class="fa fa-thumbs-up fa-4x text-success"></i><p>Your feedback was sent to PAWS announcers who will review your concern.</p>
                </div>
                <div class="text-center hide palg" id="reportAbuseError">
                    <h3>Save Failed !</h3><i class="fa fa-thumbs-down fa-4x text-danger"></i><p>An unknown error has prevented your concern from being submitted. You can email your concern to <a href="mailto:paws_announce@usask.ca">paws_announce@usask.ca</a> and we will review your concern. We apologize for the inconvenience.</p>
                </div>
                <form method="post" id="report_abuse_post">
                    <label>Please tell us why you are reporting this Announcement:</label>
                    <span style="color:red"></span>
                    <label class="radio inline"><input type="radio" name="reportReason" value="Incorrect">The content is
                        incorrect</label>
                    <label class="radio inline"><input type="radio" name="reportReason" value="Inappropriate">The content is
                        inappropriate</label>
                    <br>
                    <label>Please provide us with any additional information. Your feedback will help us to take
                        action.</label>
                    <span style="color:red"></span>
                    <textarea class="form-control" style="width:100%;height:150px" name="comment"></textarea>
                    <input type="hidden" name="announcementId" value="" />
                </form>
            </div>
-->

            <div class="m-5 p-5" style="text-align: center;" *ngIf="isLoading">
                <p class="h4 text-muted">Finding your latest news and announcements</p>
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <p class="h5 text-muted">Please wait</p>
            </div>

            <div class="m-5 p-5" style="text-align: center;" *ngIf="hasFailed">
                <p class="h4 text-muted">There was a problem finding your news and announcements</p>
                <p class="h5 text-muted">Please contact IT Support for assistance.</p>
            </div>

            <div class="m-5 p-0" style="text-align: center;" *ngIf="showNoEventsToday">
                <p class="h4 text-muted">No announcements found for {{ currentDateFormated }}</p>
                <p class="h5 text-muted">Please load more</p>
            </div>

            <div class="d-grid gap-1 py-4" *ngIf="showLoadMore">
                <div
                *ngIf="showSuccess" class="alert alert-success text-center mx-4 " role="alert">
                    Announcements loaded from {{currentDate.toDateString()}} to present.
                </div>
                <button class="btn btn-light btn-block load-more mx-4" value="3" (click)="loadMore()">Load More…</button>
            </div>


