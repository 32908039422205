<main id="content" tabindex="-1" class="row m-0">

    <div class="bg-white content-body">

        <div *ngIf="isErrorNotFound()" class="backgroundMask text-center">
            <div class="h2">Channel Not Found</div>
            <div class="mtlg h5">This can happen if the URL is not correct. Check the <a [routerLink]="['/search']">Apps & Services</a> page.</div>
            <div class="mtlg h5">This could also be because you do not have permission to access this channel. You can contact <a href=''>IT Support</a> for help.</div>
        </div>
        <div *ngIf="isErrorMismatch()" class="backgroundMask text-center">
            <div class="h2">Another Login Found</div>
            <div class="mtlg h5">You have logged into PAWS on another computer or another browser.<br />You will logged out on this session in 5 seconds.</div>
            <div class="h4">Logging out of PAWS ... </div>
            <div class="mtlg h5">Please wait.</div>
        </div>
        <div *ngIf="isNoTrackingInfo()" class="backgroundMask text-center">
            <div class="h2">Session Expired</div>
            <div class="mtlg h5">Your login session has expired. Please login again.</div>
            <div class="h4">Logging out of PAWS ... </div>
            <div class="mtlg h5">Please wait.</div>
        </div>
        <div *ngIf="isTokenExpired" class="backgroundMask text-center">
            <div class="h2" data-cy="errorTitle">Your Authentication token is missing or expired.</div>
            <div class="h4">We will try to renew your token ...</div>
            <div class="mtlg h5">Please wait.</div>
            <p>&nbsp;</p>
            <div class="mt-3">
                <a class=" me-2" [routerLink]="['/login']" queryParamsHandling="preserve">Login Again</a>
                <a class="" href="{{myProfileLink}}">My Profile</a>
            </div>
        </div>
    </div>
</main>
