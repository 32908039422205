import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

    constructor() { }

    public is_production(): Boolean {
        return environment.production;
    }
    public is_testing(): Boolean {
        return environment.testing;
    }
    public is_developement(): Boolean {
        return environment.development;
    }
    public is_training(): Boolean {
        return environment.training;
    }
    public hasServiceWorker(): Boolean {
        return environment.hasServiceWorker;
    }
    public getTheme(): string {
        return environment.theme;
    }
    public getEnv() : string {
        if ( this.is_developement() ) return 'dev';
        if ( this.is_production() ) return 'prod';
        if ( this.is_testing() ) return 'test';
        if ( this.is_training() ) return 'trng';
        else return 'local';
    }
    public getDomain(): string {
        return environment.domain;
    }
    public getAppUrl(): string {
        return "https://" + environment.domain + ( this.getTheme() == 'local' ? ":4200" : "" ) + "/login";
    }
    public getCasUrl():string {
        return environment.casUrl;
    }
    public getCasValidateUrl(): string {
        return environment.casValidateUrl;
    }
    public getCasClientId(): string {
        return environment.casClientID;
    }
    public getPublishedDate(): string {
        return environment.pubDate;
    }
    public getRevision(): string {
        return environment.revision;
    }
    public getApiUrl(): string {
        return environment.apiURL; //"https://" + environment.domain + ( this.getTheme() == 'local' ? ":8443" : "" ) + "/pawsapi/api";
    }
    public getMyProfileLink(): string {
        return environment.myProfileLink;
    }
    public getHelpLink(): string {
        return environment.helpLink;
    }
    public getSorryUrl(): string {
        return environment.sorryLink;
    }
    public getApplicationCategories(): string[] {
        return environment.applCategories.split(",");
    }
    public getCountDownEventsUrl(): string {
        return environment.countdownEventsUrl;
    }
    public getAnnouncementsUrl(): string {
        return environment.announcementURL;
    }
    public getTARole(): string {
        return environment.TARole;
    }
    public getMaxRecentlyViewedSize(): number {
        return environment.maxRecentlyViewedSize;
    }
    public getEmailIconName(): string {
        return environment.emailIconName;
    }
    public getUnreadExpiryTimeInSeconds(): number {
        return environment.unreadExpiryTime;
    }
    public getPageTitle(): string {
        return environment.pageTitle;
    }
    public getEnableGATracking(): Boolean {
        return environment.enableGA;
    }
    public getGATrackingID(): string {
        return environment.gaTrackingId;
    }
    public getNotificationTimeoutInMiliSeconds(): number {
        return environment.notificationTimeout;
    }
    public getCheckForUpdatesIntervalInMilliSeconds(): number {
        return environment.checkForUpdatesInterval;
    }
    public getLogLevel(): string {
        return environment.logLevel;
    }
    public getRefreshTokenTTLInMinutes(): number {
        return environment.RTLifetimeInMinutes;
    }
    public getSessionTimeoutNotificationInSeconds(): number {
        return environment.sessionTimeoutShowNotificationInSeconds;
    }
    public getShowIneligibleChannels(): Boolean {
        return ( environment.showIneligibleChannels );
    }
    public getShowChannelEligibility(): Boolean {
        return environment.showChannelEligibility;
    }
    public getMuleURL() {
        return environment.muleUrl;
    }
    public isUsaskSearchEnabled() {
        return environment.feature_UsaskSearch;
    }
    public isSearchFeedbackEnabled(): boolean {
        return environment.feature_SearchFeedback;
    }
    public getFeedbackPostURL() {
        return environment.feedbackPostURL;
    }
    public getSearchPageSize() {
        return environment.searchPageSize;
    }
    public getCP_KB_Url() {
        return environment.CPKBUrl;
    }
    public getLIB_KB_Url() {
        return environment.LBKBUrl;
    }
    public getITS_KB_Url() {
        return environment.ITSUrl;
    }
    public getSTU_KB_Url() {
        return environment.STUKBUrl;
    }
}
