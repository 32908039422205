import {Component, Inject, OnInit} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ApiRequestService } from "../../services/api-request.service";
import {Router,Params, ActivatedRoute} from "@angular/router";
import {AppConfigService} from "../../app-config.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    private window;
    private interval_1: any;

    constructor(
        @Inject('Window') window: Window,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private apiService: ApiRequestService,
        private appConfig: AppConfigService
    ) {
        this.window = window;
    }
    ngOnDestroy() {
        clearInterval( this.interval_1 );
    }
    ngOnInit(): void {
        let me = this;
        this.apiService.logout().subscribe( {
            complete: () => {
            }, error: () => {
            }
        } );
        me.userService.logout();
        // console.log( "LOGOUT !! ");

        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                me.interval_1 = setTimeout( () => {
                    // me.window.location.href = me.casLogoutUrl;
                    me.casLogout(params);
                }, 1000 );
            },
            error: (err: Error) => console.error('Observer got an error: ' + err),
            complete: () => {
           }
        });
        // me.window.location.href = me.casLogoutUrl;
        // me.router.navigate(["/login"]);
    }

    public get casLogoutUrl() {
        return this.appConfig.getCasUrl() + "/logout?url=" + this.appConfig.getAppUrl();
    }

    public casLogout( params:Params ) {
        let url = this.casLogoutUrl;
        let path: string = "";
        // DO NOT include '/logout'
        if ( params['channel'] != undefined && params['channel'] != '' && params['channel'] != 'logout') {
            url = url + "?channel="+params['channel'];
        } else {
            url = url + "";
        }
        this.window.location.href = url;
    }
}
