<div id="recommendations" class="bg-paws p-2">
    <div *ngIf="recommendedChannels?.length > 0" class="recommendations small text-secondary mt-1">
        <span class="mrmd">You may be interested in: </span>
        <span class="portlet-item mrlg" *ngFor = "let recommendedChannel of recommendedChannels">
            <a [title]="recommendedChannel.title" [routerLink]="['/', recommendedChannel.goname]" class="text-decoration-none fw-bold px-2" (click)="sendGAClickEvent(recommendedChannel.title)">
                <span *ngIf="recommendedChannel.is_new" class="text-white small bg-info new label label-info px-1 py-1">NEW</span>
                {{recommendedChannel.title}}
            </a>
        </span>
    </div>
</div>
