import {Injectable} from '@angular/core';
import {ApiRequestService} from './api-request.service';
import {Channel} from '../model/channel';
import {Observable, Subject} from 'rxjs';
import {UserService} from './user.service';
import arrayShuffle from 'array-shuffle';
import {CustomChannelWithFavorites} from "../model/customChannelWithFavorites";
import {AppConfigService} from "../app-config.service";

@Injectable({
    providedIn: 'root'
})
export class ChannelsService {

    private CHANNELS: Array<CustomChannelWithFavorites> = [];
    /**
    * Channels Observer/Observable worker.
    * @type {Subject<any>}
    * @private
    */
    private _channelsOut: Subject<Array<CustomChannelWithFavorites>> = new Subject();
    /**
     * Channels Observable. Used for outside watchers to subscribe to.
     * @type {Observable<any>}
     */
    public channelsReady$: Observable<Array<CustomChannelWithFavorites>> = this._channelsOut.asObservable();

    private RECENTLY_VIEWED: Array<number> = null;
    private _recentlyViewedOut: Subject<Array<number>> = new Subject();
    public recentlyViewedsReady$: Observable<Array<number>> = this._recentlyViewedOut.asObservable();

    private LIVE_SEARCH: string = null;
    private _liveSearchOut: Subject<string> = new Subject();
    public liveSearchReady$: Observable<string> = this._liveSearchOut.asObservable();
    public liveSearchReady( keywords: string ) {
        this._liveSearchOut.next( keywords );
    }

    public channelLoadComplete: boolean = false;
    /**
     * Channels emitter function. Used internally to notify watchers..
     * @param channels
     */
    public channelsReady( channels: Array<CustomChannelWithFavorites> ) {
        this._channelsOut.next( channels );
    }

    /**
     * Recently viewed emitter function. Used internally to notify watchers.
     */
    public recentlyViewedsReady( recentlyVieweds: Array<number>){
        this._recentlyViewedOut.next(recentlyVieweds);
    }

    /**
     * Service constructor
     * Function login is called when app is reloaded in browser. Calls to re-fetch channel list.
     * @param api
     *
     * @param userService
     */
    constructor(
        private api: ApiRequestService,
        private userService: UserService,
        private appConfig: AppConfigService
    ) {
        if ( this.userService.isLoggedIn() && this.CHANNELS.length == 0 ) {
            let me = this;
            me.api.getChannels().subscribe({
                next: ( resp:Array<CustomChannelWithFavorites> ) => {
                    me.processChannels( resp );
                    me.reloadRecentlyVieweds(userService.getRecentlyViewed());
                }
            } );
        }
    }

    ngOnInit() {

    }

    /**
     * Public facing recently viewed reload
     * @param newArray Array<number>
     */
    public reloadRecentlyVieweds(newArray:Array<number>){
        this.processRecentlyVieweds(newArray);
    }

    /**
     * Public facing function reload channel list
     * Processes list and calls Observable
     * @param channels
     */
    public reloadChannels( channels:Array<CustomChannelWithFavorites> ) {
        this.processChannels( channels );
    }

    /**
     * Process Recently Viewed Channels
     * @param newArray
     */
    private processRecentlyVieweds(newArray:Array<number>){
        let me = this;
        let tempArray:Array<number> = new Array<number>();


        // console.log(newArray);
        if(newArray != null || Array.isArray(newArray)){

            newArray.forEach(element => {
                //console.log(element);
                let currentChannels = me.CHANNELS;
                //console.log(currentChannels);
                if(currentChannels.filter(e => e.id == element).length > 0){
                    let currentChannel = currentChannels.find(x => x.id == element);
                    if(!currentChannel.is_favorite && !currentChannel.is_icon && !(currentChannel.category == "My College"))
                        tempArray.push(element);
                }
            });
            if(tempArray.length > this.appConfig.getMaxRecentlyViewedSize()){
                tempArray = tempArray.splice(0, this.appConfig.getMaxRecentlyViewedSize());
            }


        }
        me.RECENTLY_VIEWED = tempArray;
        me.recentlyViewedsReady(me.RECENTLY_VIEWED );
    }

    /**
     * Deletes a recently viewed entry
     * @param channelID - the channelID to delete if it exists
     */
    public deleteRecentlyViewed(channelID: number){
        let me = this;
        let newArray = me.userService.getRecentlyViewed();
        let index = newArray.indexOf(channelID);

        if(index > -1){
            newArray.splice(index, 1);
        }

        me.userService.storeRecentlyViewed(newArray);

        me.processRecentlyVieweds(newArray);

    }

      /**
     * Updates the recently viewed locally.
     *
     * Requirements:
     * The array can only accept numbers and have a length of 3.
     * @param newChannelID - The new channel ID to add to the array.
     *  Will not add to the array if a favorite, an icon, or part of my college.
     */
    public updateRecentlyViewed(newChannelID: number){
        let me = this;
        let currentChannel = me.CHANNELS.find(x => x.id == newChannelID);

        if (!currentChannel.is_favorite && !(currentChannel.category == "Icon") && !(currentChannel.category == "My College")){
            let newArray = me.userService.getRecentlyViewed();

            if(newArray.includes(newChannelID)){
                let index = newArray.indexOf(newChannelID);
                if(index > -1){
                    newArray.splice(index, 1);
                }
            }
            newArray.unshift(newChannelID);
            me.userService.storeRecentlyViewed(newArray);

            if(newArray.length > this.appConfig.getMaxRecentlyViewedSize()){
                newArray = newArray.splice(0, this.appConfig.getMaxRecentlyViewedSize());
            }

            me.api.updateRecentlyViewed(newArray).subscribe();
            me.RECENTLY_VIEWED = newArray;
            me.recentlyViewedsReady(me.RECENTLY_VIEWED );
        }

    }

    /**
     * Private function to take an array of channels from API and format them into and array matching interface
     * @param channels
     * @private
     */
    private processChannels( channels:Array<CustomChannelWithFavorites> ) {
        let me = this;
        // console.log( "PROCESS CHANNELS", channels );
        me.CHANNELS = [];
        if ( channels.length > 0 ) {
            channels.forEach( (element:CustomChannelWithFavorites) => {
                me.CHANNELS.push( {
                    id: element['id'],
                    title: element['title'],
                    category: element['category'],
                    goname: element['goname'].replace( '#', '' ),
                    description: element['description'],
                    keywords: element['keywords'],
                    url: element['url'],
                    // url_dev: element['url_dev'],
                    // url_test: element['url_test'],
                    // url_prod: element['url_prod'],
                    // url_trng: element['url_trng'],
                    is_favorite: element['is_favorite'] ,
                    is_icon: element['is_icon'],
                    is_public: element['is_public'],
                    display_order: element['display_order'], // element['isicon'] // ( element['isIcon'] == 'TRUE' ? true : false )
                    is_recommended: element['is_recommended'],
                    priority: element['priority'],
                    is_new: element['is_new'],
                    include_roles: element['include_roles'],
                    exclude_roles: element['exclude_roles'],
                    eligibility: element['eligibility'],
                    has_access: me.hasChannelAccess( element )
                } );
            });
            me.channelLoadComplete = true;
            me._channelsOut.next( channels );
        }
        // this.CHANNELS = channels;
        // console.log( "PROCESS CHANNELS COMPLETE", this.CHANNELS );
    }

    public hasChannelAccess( channel:CustomChannelWithFavorites ): boolean {
        // console.log( "Has Access " + channel.title );
        let me = this;
        let has_access = false;
        let include_roles = ( channel.include_roles == null || channel.include_roles == '' ? [] : channel.include_roles.split(",") );
        let exclude_roles = ( channel.exclude_roles == null || channel.exclude_roles == '' ? [] : channel.exclude_roles.split(",") );
        // console.log( "channel.is_public: " + channel.is_public );
        // console.log( me.userService.getRoles() );
        if ( channel.is_public === true ) {
            has_access = true;
        } else {
            // If the user has any of these roles, grant access
            include_roles.forEach((c) => {
                // console.log( "Has " + c + ": " + me.userService.hasRole(c))
                if ( c != "" && me.userService.hasRole(c)) {
                    has_access = true;
                }
            });
            // If the user has none of these roles, grant access.
            if ( exclude_roles.length > 0 ) {
                let exclude: boolean = false;
                exclude_roles.forEach((c) => {
                    // console.log( "Exclude " + c + ": " + me.userService.hasRole(c))
                    if (c != "" && me.userService.hasRole(c)) {
                        exclude = true;
                    }
                });
                if (!has_access && !exclude) has_access = true;
            }
        }
        return has_access;
    }

    /**
     * Finds and returns a channel with matching golink ( goname ) from the current list of channels
     * @param golink
     */
    public getChannel( golink: string|null ): CustomChannelWithFavorites {
        let ret = null;
        this.CHANNELS.forEach( channel => {
            if ( channel.goname == golink ) {
                ret = channel;
            }
        } );
        return ret;
    }


    /**
     * Returns the full list of available channels
     * @return Array<Channel>
     */
    public getAllChannels() {
        return this.CHANNELS;
    }

    /**
     * Returns all the recently viewed
     * @return Array<number>
     */
    public getAllRecentlyViewed(){
        return this.RECENTLY_VIEWED;
    }

    /**
     * Returns all recently viewed as a custom channel favs array
     * @Return Array<CustomChannelWithFavorites>
     */
    public getRecentlyViewedAsChannelArray(): Array<CustomChannelWithFavorites>{
        let ret: Array<CustomChannelWithFavorites>  = [];
        //console.log(this.RECENTLY_VIEWED);
        if (this.RECENTLY_VIEWED != null){
            this.RECENTLY_VIEWED.forEach((element) => {
                ret.push(this.CHANNELS.find(x => x.id == element));
            });
        }

        return ret;
    }

    /**
     * Changes a favorite on a channel given the goname
     * @param isFavorite - If the channel is currently a favorite
     * @param goname - The channel we are changing the favorite and display order properties on
     * @param displayOrder - The position of the favorite
     */
    public setFavorite(isFavorite: boolean, goname: string, displayOrder: number){
        let updateChannelIndex = this.CHANNELS.findIndex(channel => channel.goname == goname);
        this.CHANNELS[updateChannelIndex].display_order = displayOrder;
        this.CHANNELS[updateChannelIndex].is_favorite = isFavorite;
        this.channelsReady(this.CHANNELS);
    }

        /**
     * Changes a favorite on a channel given the channel id
     * @param isFavorite - If the channel is currently a favorite
     * @param channelId - The channel id
     * @param displayOrder - The position of the favorite
     */
    public setFavoriteByChannelId(isFavorite: boolean, channelId: number, displayOrder: number){
        let updateChannelIndex = this.CHANNELS.findIndex(channel => channel.id == channelId);
        this.CHANNELS[updateChannelIndex].display_order = displayOrder;
        this.CHANNELS[updateChannelIndex].is_favorite = isFavorite;
        this.channelsReady(this.CHANNELS);
    }

    /**
     * Returns a filtered list of ICON channels
     * @return Array<Channel>
     */
    public getIcons() {
        let icons: Array<CustomChannelWithFavorites> = [];
        let me = this;
        me.CHANNELS.forEach( (el:CustomChannelWithFavorites) => {
            if ( el.has_access === true ) {
                if (el.is_icon == true) icons.push(el);
                else if (el.category == 'Icon') icons.push(el);
            }
        } );
        return icons;
    }

    /**
     * Gets up to three channels to recommend. Determined by priority and if there are more than three top priorities that are identical
     * it will pick three random channels.
     * @returns Three channels that are recommended
     */
    public getRecommendedChannels(): Array<CustomChannelWithFavorites> {
        let recommendedChannels: Array<CustomChannelWithFavorites> = [];
        let me = this;

        me.CHANNELS.forEach( (el:CustomChannelWithFavorites) => {
            if ( el.has_access === true && el.is_recommended === true ) recommendedChannels.push( el );
        } );
        let recommendedChannelsToShow = 3;
        if(recommendedChannels.length > recommendedChannelsToShow){
            recommendedChannels.sort((a,b) => b.priority - a.priority);
            let highestPriority = recommendedChannels[0].priority;
            let highestPriorityChannels = recommendedChannels.filter(channel => channel.priority == highestPriority);

            if(highestPriorityChannels.length > recommendedChannelsToShow){
                highestPriorityChannels = arrayShuffle(highestPriorityChannels);
                recommendedChannels = highestPriorityChannels.splice(0, recommendedChannelsToShow);

            } else {
                recommendedChannels = recommendedChannels.splice(0, recommendedChannelsToShow);
            }
        }
        return recommendedChannels;
    }

    /**
     * Returns a filtered list of isFavourite Channels
     * @return Array<Channel>
     */
    public getFavorites() {
        let favorites: Array<CustomChannelWithFavorites> = [];
        let me = this;
        me.CHANNELS.forEach( (el:CustomChannelWithFavorites) => {
            if ( el.has_access === true && el.is_favorite === true ) favorites.push( el );
        } );
        favorites.sort((a,b) => a.display_order - b.display_order) ;
        return favorites;
    }

    /**
     * Returns a list of My College Channels
     * @return Array<Channel>
     */
    public getMyCollege() {
        let myCollege: Array<CustomChannelWithFavorites> = [];
        let me = this;
        me.CHANNELS.forEach( (el:CustomChannelWithFavorites) => {
            if ( el.has_access === true && el.category == 'My College' ) myCollege.push( el );
        } );
        return myCollege;
    }

    /**
     * Adds a new favorite based on the channelID
     * @param channelID - The ID of the channel
     * @returns Observable<Any>
     */
    public addFavorite(channelID: number){
        let me = this;
        return me.api.addFavorite(channelID);
    }

    /**
     * Deletes a new favorite based on the channelID
     * @param channelID - The ID of the channel
     * @returns Observable<Any>
     */
    public deleteFavorite(channelID: number){
        let me = this;
        return me.api.deleteFavorite(channelID);
    }

    /**
     * Updates favorites based on the array given
     * @param channelIDs - Array of channel IDs to be updated as favorites
     * @returns - Observable<UserChannel[]>
     */
    public updateFavorite(channelIDs: number[]){
        let me = this;
        return me.api.updateFavorite(channelIDs);
    }

    /**
     * Does the provided channel have an alternate Icon Channel pointing to it.
     * @param channel
     * @returns boolean
     */
    public hasAlternateIcon( channel: Channel ) {
        let me = this;
        let icons = this.getIcons();
        let ret = false;
        icons.forEach( (i:CustomChannelWithFavorites) => {
            if ( me.getChannelURL(i).startsWith("/"+channel.goname) )
                ret = true;
        } );
        return ret;
    }

    /**
     * Helper method to return channel URL. Will consider ENV
     */
    private getChannelURL(c:Channel|CustomChannelWithFavorites): string {
        if ( c != null ) {
            return c.url;
        }
        else return '';
    }


}
