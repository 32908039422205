<div class="bg-paws-header border-bottom content-header">
    <h1 class="d-inline-flex pe-3 mb-0">Apps and Services</h1>
</div>
<div class="bg-white content-body">
    <div class="portlets-list maxWidthWrapper" id="portlet-search-panel">
        <div class="row align-items-start">
            <div *ngFor="let cat of viewedList" class="category-section" id="category-section-{{ cat.title | removewhitespaces }}">
                <h3 class="h4 portlet-category border-bottom mt-2" [ngClass]="{ 'd-none': !cat.is_visible }">{{ cat.title }}</h3>
                <div *ngFor="let c of cat.items" class="portlet-item my-4" [ngClass]="{ 'd-none':!c.is_visible }">
                    <ng-container *ngTemplateOutlet="channelListingTemplate;context:{channel:c}"></ng-container>
                </div>
                <div *ngFor="let c of cat.hidden_items" class="portlet-item my-4" [ngClass]="{ 'd-none':!c.is_visible }">
                    <ng-container *ngTemplateOutlet="ineligibleChannelListingTemplate;context:{channel:c}"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #channelListingTemplate let-channel="channel">
    <p class="h5 mb-0">
        <a [routerLink]="['/', channel.goname]" class="portlet-title h5 " (click)="sendGAClickEvent(channel.title)"><b>{{ channel.title }}</b></a>
    </p>
    <p class="portlet-description mb-0">{{ channel.description }}</p>
    <p *ngIf="showChannelEligibility" class="portlet-description mb-0 text-muted"><strong>Eligibility:</strong> {{ channel.eligibility }}</p>
</ng-template>
<ng-template #ineligibleChannelListingTemplate let-channel="channel">
    <p class="h5 mb-0 text-muted">
        <span *ngIf="!channel.has_access" class="h5"><b>{{ channel.title }}</b></span>
    </p>
    <p class="portlet-description mb-0 text-muted">{{ channel.description }}</p>
    <p class="portlet-description mb-0 text-muted"><strong>Eligibility:</strong> {{ channel.eligibility }}</p>
    <p class="portlet-description mb-0 text-muted small"><strong>GoName:</strong> /{{ channel.goname }}</p>
</ng-template>
<ng-template #eligibilityTemplate let-channel="channel">
    <p class="portlet-description mb-0"><strong>Eligibility:</strong> {{ channel.eligibility }}</p>
    <div class="card p-2">
        <p class="card-text mb-0"><strong>Eligibility:</strong> {{ channel.eligibility }}</p>
        <p class="card-text">
            <span *ngIf="channel.is_public" class="ms-2 badge text-bg-info">PUBLIC</span>
            <span *ngFor="let r of channel.included_roles" class="ms-2 badge" [class.text-bg-success]="hasRole(r)" [class.text-bg-light]="!hasRole(r)">{{ r | uppercase }}</span>
            <span *ngFor="let r of channel.excluded_roles" class="ms-2 badge" [class.text-bg-danger]="hasRole(r)" [class.text-bg-light]="!hasRole(r)">{{ r | uppercase }}</span>
        </p>
    </div>
</ng-template>
