import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    constructor(

    ) {
    }

    public clearCacheAll() {
        let me = this;

        caches.keys().then(function (cacheNames) {
            return Promise.all(
                cacheNames.filter(function (cacheName) {
                    return true;
                }).map(function (cacheName) {
                    return caches.delete(cacheName).then((res) => me.logDelete(res, cacheName));
                })
            );
        })
    }
    public clearCacheByName(nameCacheParam: string) {
        let me = this;

        caches.keys().then(function (cacheNames) {
            return Promise.all(
                cacheNames.filter( (cacheName) => {
                    if (nameCacheParam == cacheName) return true;
                    else if ( cacheName.includes( nameCacheParam ) ) return true;
                    else return false;
                }).map(function (cacheName) {
                    return caches.delete(cacheName).then((res) => me.logDelete(res, cacheName));
                })
            );
        })
    }
    public clearCacheByUrl(nameCache:string, url:string) {
        let me = this;
        caches.open(nameCache).then((c) => {
                c.keys().then((keys) => {
                    keys.filter((p) => { return p.url.includes(url) })
                        .map((keySearched) => c.delete(keySearched.url).then((res) => this.logDelete(res, keySearched)))
                })
            }
        );
    }
    private logDelete(result: boolean, cache: any) {
        console.log(`CLEARED CACHE: ${cache} =>`, (result ? 'Success' : 'Failed'));
    }
}
