import {Component, HostListener, Inject} from '@angular/core';
import {UserService} from './services/user.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Params, Router} from '@angular/router';
import {getCLS, getFCP, getFID, getLCP, getTTFB} from 'web-vitals';
import {MetricsService} from "./services/metrics.service";
import {User} from './model/user';
import {Title} from '@angular/platform-browser';
import {ChannelsService} from "./services/channels.service";
import {MailNotificationService} from "./services/mail-notification.service";
import {CustomChannelWithFavorites} from "./model/customChannelWithFavorites";
import {AppConfigService} from "./app-config.service";
import {FormControl} from "@angular/forms";
import {PromptUpdateService} from "./services/prompt-update.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    public title = "PAWS";
    private logos = {
        'zoot': "assets/img/header-logo-zoot-only@2x.png",
        'beaker': "assets/img/header-logo-beaker-only@2x.png",
        'trng': "assets/img/header-logo-beaker-only@2x.png",
        'paws': "assets/img/PAWS_logo-green.png",
        'paws_mobile': "assets/img/paw-green.png"
    };
    public logoImg = this.logos.paws;
        public logoImgMobile = this.logos.paws_mobile;
    public userName: string = '';
    public myProfileLink: String = "";
    public helpLink: String = "";
    public headerText: String = "";
    public searchTerm = new FormControl('');
    /**
     * Current revision number
     * @type {string}
     */
    private revision: string;
    /**
     * Revision Publish date
     */
    private pubDate: string;

    private intervalID: any;

    private window;

    private scollPos: any;
    @HostListener('window:scroll') onScroll(e: Event): void {
        if ( e != undefined ) {
            this.scollPos = this.getYPosition(e);
        }
    }
    getYPosition(e: Event): number {
        return (e.target as Element).scrollTop;
    }

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private channelService: ChannelsService,
        private metricService: MetricsService,
        private titleService: Title,
        private mailNotificationService: MailNotificationService,
        private appConfig: AppConfigService,
        private promptForUpdates: PromptUpdateService,
        @Inject('Window') window: Window,
    ) {
        this.window = window;
        this.title = this.appConfig.getPageTitle();
        performance.mark("constructor");
        switch ( this.appConfig.getTheme() ) {
            case 'local':
                // this.logoImg = this.logos.paws;
                this.headerText = "LOCAL";
                break;
            case 'dev':
                // this.logoImg = this.logos.zoot;
                this.headerText = "Development";
                break;
            case 'test':
                // this.logoImg = this.logos.beaker;
                this.headerText = "Test";
                break;
            case 'trng':
                // this.logoImg = this.logos.trng;
                this.headerText = "Training";
                break;
            case 'paws':
                this.logoImg = this.logos.paws;
                break;
        }
        let me = this;
        this.userService.userReady$.subscribe({
            next: (user: User) => {
                me.userName = user.displayName;
            }
        });
        this.userName = this.userService.getUserName();
        this.myProfileLink = this.appConfig.getMyProfileLink(); // appConfig.getEnv("myProfileLink");
        this.helpLink = this.appConfig.getHelpLink(); // appConfig.getEnv("helpLink");
        if ( this.appConfig.getRevision() != '' ) this.revision = this.appConfig.getRevision(); // appConfig.getEnv("revision");
        this.pubDate = this.appConfig.getPublishedDate(); // appConfig.getEnv("pubDate");
        this.intervalID = setInterval( () => {
            me.mailNotificationService.getUnreadCount();
        }, ( 1000 * 60 * this.appConfig.getUnreadExpiryTimeInSeconds() ) + 5000 );
        this.intervalID = setTimeout( () => {
            me.mailNotificationService.getUnreadCount();
        }, ( 4000 ) );

        this.activatedRoute.queryParams.subscribe( {
            next: (routeParams: Params) => {
                if ( routeParams['keyword'] != undefined ) {
                    me.searchTerm.setValue(routeParams['keyword'].replace('#', ''));
                }
            }
        });
        me.searchTerm.valueChanges.subscribe( (v) => {
            if ( this.router.url.includes("/search") && v.length > 3 ) {
                this.channelService.liveSearchReady( v );
            }
        } );
    }

    ngOnDestroy() {
        clearInterval( this.intervalID );
    }

    ngOnInit() {
        // document.querySelector(".content-body").style.height = "83vh";
        // console.log( "In APP: " + VERSION );
        let me = this;
        this.router.events.subscribe((event): void => {
            if (event instanceof NavigationStart) {
                if (!!event.url && event.url.match(/^\/#/) ) {
                    me.router.navigate([event.url.replace('/#', '/')]);
                }
                if (!!event.url && event.url.match(/^\/%23/) ) {
                    me.router.navigate([event.url.replace('/%23', '/')]);
                }
            }
            if ( event instanceof NavigationEnd ) {
                // console.log( event.urlAfterRedirects );
                if ( !event.urlAfterRedirects.includes('/search') ) me.searchTerm.setValue("");
                let url = event.urlAfterRedirects.replace('/','').split("/");
                if ( url[0] == "" || url[0] == "login" || url[0] == "logout" ) {
                    me.setTitle( this.title );
                } else if ( url[0] == "search" ) {
                    me.setTitle( this.title + " > Apps & Services" );
                } else {
                    let goname = url[0];
                    let channel: CustomChannelWithFavorites;
                    if ( me.channelService.channelLoadComplete ) {
                        channel = me.channelService.getChannel(goname);
                        if ( channel != null ) {
                            me.setTitle( me.title + " > " + channel.title + ( url.length == 2 ? " > " + url[1] : "" ) );
                        }
                    } else {
                        me.channelService.channelsReady$.subscribe( {
                            next: () => {
                                channel = me.channelService.getChannel(goname);
                                if ( channel != null ) {
                                    me.setTitle( this.title + " > " + channel.title + ( url.length == 2 ? " > " + url[1] : "" ) );
                                }
                            }
                        });
                    }
                }
            }
        });

        performance.mark('OnInit');
        performance.measure('myNiftyMeasure', 'constructor', 'OnInit');
        performance.measure('DOMMeasure', 'DOMStart', 'OnInit');
        getCLS(this.metricService.logMetric);
        getFID(this.metricService.logMetric);
        getLCP(this.metricService.logMetric);
        getFCP(this.metricService.logMetric);
        getTTFB(this.metricService.logMetric);
    }
    public submitSearch() {
        let queryParams: Params = {};
        if ( this.searchTerm.getRawValue() != '' ) {
            queryParams['keyword'] = this.searchTerm.getRawValue();
            if ( this.router.url.includes("/search") ) {
                this.channelService.liveSearchReady( this.searchTerm.getRawValue() );
            } else {
                this.router.navigate(['/search'],{queryParams: queryParams});
            }
        } else {
            this.router.navigate(['/services']);
        }
    }
    public scrollToTop() {
        this.window.scrollTo(0, 0);
    }

    public showBackToTop() {
        // console.log(this.window.pageYOffset);
        if ( this.window.pageYOffset > 500 ) {
            return true;
        } else {
            return false;
        }
    }

    public setTitle( title: string ) {
        this.titleService.setTitle( title );
    }

    public get theme() {
        return this.appConfig.getTheme();
    }

    public isLoggedIn() {
        return this.userService.hasAccessToken();
    }
    /**
     * Return if Publish Date has been set
     * @returns {boolean}
     */
    public hasPubDate(): boolean {
        if ( this.pubDate != '@PUB_DATE@' ) return true;
        else return false;
    }
    /**
     * Return the Publish Date.
     * @returns {string}
     */
    public getPublicationDate() {
        if ( this.pubDate != '@PUB_DATE@' ) return this.pubDate;
        else return '';
    }

    /**
     * Return if the SVN revision number is set.
     * @returns {boolean}
     */
    public hasRevNumber(): boolean {
        // return false;
        if ( this.revision != undefined ) return true;
        else return false;
    }

    /**
     * Return the SVN revision number;
     * @returns {any}
     */
    public getRevisionNumber() {
        if ( this.revision != undefined ) return this.revision;
        else return '';
    }
}
