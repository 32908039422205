import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatHtml'
})
export class FormatHtmlPipe implements PipeTransform {

    transform(value: string ): string {
        let ret = value.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>');
        return ret;
    }

}
