export const environment = {
    production: true,
    testing: false,
    development: false,
    training: false,
    hasServiceWorker: true,
    theme: 'paws',
    appUrl: 'https://paws2.usask.ca/login', //Example: https://myapp.com
    casUrl: 'https://cas.usask.ca/cas', //Example: https://mycas.com/cas
    casValidateUrl: 'https://cas.usask.ca/cas/serviceValidate', //Example: https://mycas.com/cas/serviceValidate
    muleUrl: "https://api.usask.ca",
    casClientID: "paws2",
    pubDate: "Wed 19 Jun 2024 11:47:15 PM CST",
    revision: "1.1.1.1718862435",
    webPath: "/",
    domain: "paws2.usask.ca",
    apiURL: "https://paws2.usask.ca/pawsapi/api",
    myProfileLink: "https://myprofile.usask.ca/home.jsf",
    sorryLink: "https://sorry.usask.ca/paws_down.html",
    helpLink: "https://teamdynamix.usask.ca/TDClient/33/Portal/KB/ArticleDet?ID=274", //https://wiki.usask.ca/x/AIGybw",
    oldPAWSLink: "https://paws5.usask.ca",
    applCategories: "PASS,EVAL,OTPT,EMRG,UOFS,NEWS",
    countdownEventsUrl: "https://portalapps.usask.ca/countdown_events/countdown_eventgenerator.php?v=4",
    announcementURL: "https://portalapps.usask.ca/announcements/",
    TARole: "imported_groups",
    maxRecentlyViewedSize: 3,
    pageTitle: "PAWS",
    emailIconName: "Email",
    unreadExpiryTime: 15,
    enableGA: true,
    gaTrackingId: "G-7P8QY8C9QK",
    notificationTimeout: 5000,
    checkForUpdatesInterval: ( 5 * 60 * 1000 ),
    logLevel: "ERROR",
    RTLifetimeInMinutes: 90,
    sessionTimeoutShowNotificationInSeconds: 90,
    showIneligibleChannels: false,
    showChannelEligibility: false,
    feature_UsaskSearch: true,
    feature_SearchFeedback: true,
    feedbackPostURL: "https://phpapps.usask.ca/site-feedback/feedback_post.php",
    searchPageSize: 15,
    CPKBUrl: "https://jira.usask.ca/servicedesk/customer/portal/1",
    LBKBUrl: "https://jira.usask.ca/servicedesk/customer/portal/13",
    ITSUrl: "https://teamdynamix.usask.ca/TDClient/33/Portal/KB/?CategoryID=1",
    STUKBUrl: "https://jira.usask.ca/servicedesk/customer/portal/7"

};
