import {Inject, Injectable} from '@angular/core';
import {ApiRequestService} from "./api-request.service";
import {environment} from "../../environments/environment";

declare const gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class MetricsService {

    public GA_PAWS_LOGIN: string = "paws_login";
    public GA_FAVORITE_CLICK: string = "paws_favorite_click";
    public GA_ORG_FAVORITE_CLICK: string = "paws_orgfavorite_click";
    public GA_MYCOLLEGE_CLICK: string = "paws_mycollege_click";
    public GA_RECENTLYVIEWED_CLICK: string = "paws_recentlyviewed_click";
    public GA_APPSSERVICES_CLICK: string = "paws_appsandservices_click";
    public GA_ANNOUNCEMENT_VIEW: string = "paws_announcement_view";
    public GA_FAVORITE_ADD: string = "paws_favorite_add";
    public GA_CALENDAR_VIEW: string = "paws_calendarevent_view";
    public GA_RECOMMENDER_VIEW: string = "paws_recommender_view";
    public GA_COUNTDOWNEVENT_CLICK: string = "paws_countdownevent_click";
    public GA_SEARCH_TERM_VIEW: string = "paws_search_view";

    public window: Window;

    constructor(
        @Inject('Window') window: Window,
        private api: ApiRequestService
    ) {
        this.window = window;
    }

    public initGA( roles: Array<String> ) {
        if ( environment.enableGA ) {
            let affiliates = this.getAffiliate(roles);
            gtag('config', environment.gaTrackingId, {'user_properties': affiliates});
        }
    }

    public logMetric(metric: any) {
        switch ( environment.logLevel ) {
            case 'FATAL':
                break;
            case 'ERROR':
                break;
            case 'WARN':
                break;
            case 'INFO':
                break;
            case 'DEBUG':
            case 'TRACE':
                console.log(metric.name + ": " + metric.value, metric.obj );
        }
    }

    public sendError( message: string ) {
        this.api.logFrontEndError(message).subscribe({
            next: () => { },
            error: (err: any) => {
                console.log( message );
            }
        });
    }

    public getAffiliate( roles: Array<String> ) {
        let affiliates: any = {};
        if (roles.length > 0) {
            affiliates.student = ( roles.includes("student") ? true : false );
            affiliates.employee = ( roles.includes("employee") ? true : false );
            affiliates.faculty = ( roles.includes("faculty") ? true : false );
            affiliates.alumni = ( roles.includes("alumni") ? true : false );
            // gtag('set', this.GA_PAWS_LOGIN, affiliates);
        }
        return affiliates;
    }

    public sendChannelFavoriteClick(channel: string): void {
        if ( environment.enableGA ) {
            gtag('event', this.GA_FAVORITE_CLICK, {channel_name: channel});
        }
    }

    public sendOrganizationFavoriteClick(channel: string): void {
        if ( environment.enableGA ) {
            gtag('event', this.GA_ORG_FAVORITE_CLICK, {channel_name: channel});
        }
    }

    public sendChannelMyCollegeClick(channel: string): void {
        if ( environment.enableGA ) {
            gtag('event', this.GA_MYCOLLEGE_CLICK, {channel_name: channel});
        }
    }

    public sendChannelRecentlyViewedClick(channel: string): void {
        if ( environment.enableGA ) {
            gtag('event', this.GA_RECENTLYVIEWED_CLICK, {channel_name: channel});
        }
    }

    public sendChannelListClick(channel: string): void {
        if ( environment.enableGA ) {
            gtag('event', this.GA_APPSSERVICES_CLICK, {channel_name: channel});
        }
    }

    public sendAnnouncementView(id: any) {
        if ( environment.enableGA ) {
            gtag('event', this.GA_ANNOUNCEMENT_VIEW, {'announcement_id': id});
        }
    }

    public sendFeedback(event: string) {
        if ( environment.enableGA ) {
            gtag('event', event);
        }
    }

    public sendAddFavorite(title: string) {
        if ( environment.enableGA ) {
            gtag('event', this.GA_FAVORITE_ADD, {'channel_name': title});
        }
    }

    public sendRemoveFavorite(title: string) {
        if ( environment.enableGA ) {
            gtag('event', this.GA_FAVORITE_ADD, {'favorite_remove': title});
        }
    }

    public sendCalendarEventView(summary: string) {
        if ( environment.enableGA ) {
            gtag('event', this.GA_CALENDAR_VIEW, {'event_name': summary});
        }
    }

    public sendRecommenderClick(title: string) {
        if ( environment.enableGA ) {
            gtag('event', this.GA_RECOMMENDER_VIEW, {'channel_name': title});
        }
    }

    public sendCountdownEventClick(title: string) {
        if ( environment.enableGA ) {
            gtag('event', this.GA_COUNTDOWNEVENT_CLICK, {'event_name': title});
        }
    }

    public sendSearchTermView(term: string) {
        if ( environment.enableGA ) {
            gtag('event', this.GA_SEARCH_TERM_VIEW, {'search_term': term});
        }
    }

}
