import {Component, Inject, NO_ERRORS_SCHEMA, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UserService} from "../../services/user.service";
import {ApiRequestService} from "../../services/api-request.service";
import {AppConfigService} from "../../app-config.service";

@Component({
    selector: 'app-errorpage',
    templateUrl: './errorpage.component.html',
    styleUrls: ['./errorpage.component.scss'],
})
export class ErrorpageComponent implements OnInit, OnDestroy {

    private ids = [
        'mismatchip',
        'notfound',
        'notrackinginfo'
    ]
    public errorMessage: string = '';

    private window;

    private forceLogout: Boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private appConfig: AppConfigService,
        @Inject('Window') window: Window,
    ) {
        this.window = window;
    }

    ngOnDestroy() {
        // this.window.location.href = this.casLogoutUrl;
    }

    ngOnInit(): void {
        let me = this;
        this.activatedRoute.queryParams.subscribe({
            next: (params) => {
                if ( params['errorid'] ) {
                    me.errorMessage = params['errorid'];
                    me.userService.logout();
                    me.forceLogout = true;
                    me.userService.destroyAccessToken();

                    if ( this.errorMessage == 'notoken' ) {
                        setTimeout( () => {
                            me.router.navigate(["/login"]);
                        }, 1000 );
                    }
                }
            }
        });
    }

    public get myProfileLink() {
        return this.appConfig.getMyProfileLink();
    }

    // public logout() {
    //     let me = this;
    //     this.apiService.logout().subscribe( {
    //         next: () => {
    //             me.userService.logout();
    //         }
    //     } );
    // }
    private get casLogoutUrl() {
        return this.appConfig.getCasUrl() + "/logout?url=" + this.appConfig.getAppUrl();
    }

    public isErrorMismatch(): Boolean {
        return ( this.errorMessage == 'mismatchip' ? true : false );
    }
    public isErrorNotFound(): Boolean {
        return ( this.errorMessage == 'notfound' ? true : false );
    }
    public isNoTrackingInfo(): Boolean {
        return ( this.errorMessage == 'notrackinginfo' ? true : false );
    }
    public isTokenExpired(): Boolean {
        return ( this.errorMessage == 'notoken' ? true : false );
    }
}
