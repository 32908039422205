<div class="bg-white content-header pt-3 pb-0">
    <h1 class="d-inline-flex pe-3 mb-0">Search Results</h1>
</div>
<div class="bg-white content-body pt-4">
    <p class="mb-0">Results for "{{ getSearchTerms() }}"</p>
    <div class="portlets-list maxWidthWrapper" id="portlet-search-panel">
        <div class="row align-items-start">
            <div class="col-sm-12 col-lg-6">
                <div *ngFor="let c of primarySearchResults" class="portlet-item my-4">
                    <ng-container *ngTemplateOutlet="channelListingTemplate;context:{channel:c}"></ng-container>
                </div>
<!--                <hr *ngIf="primarySearchResults.length>0" />-->
                <div *ngFor="let cat of viewedList" class="category-section" id="category-section-{{ cat.title | removewhitespaces }}">
                    <h3 class="h4 portlet-category border-bottom mt-2" [ngClass]="{ 'd-none': !cat.is_visible }">{{ cat.title }}</h3>
                    <div *ngFor="let c of cat.items" class="portlet-item my-4" [ngClass]="{ 'd-none':!c.is_visible }">
                        <ng-container *ngTemplateOutlet="channelListingTemplate;context:{channel:c}"></ng-container>
                    </div>
                    <div *ngFor="let c of cat.hidden_items" class="portlet-item my-4" [ngClass]="{ 'd-none':!c.is_visible }">
                        <ng-container *ngTemplateOutlet="ineligibleChannelListingTemplate;context:{channel:c}"></ng-container>
                    </div>
                </div>
                <p class="my-4 h5" *ngIf="showNoResultsFound">No apps or services found</p>
                <p class="my-4">
                    <a [routerLink]="['/services']" class="btn btn-primary">Apps and Services</a>
                </p>
            </div>
            <div class="col-sm-12 col-lg-6">

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                            </svg>
                            Knowledgebase Articles
                        </h5>
                        <hr />
                        <p class="mb-3 text-muted">Answers to related questions and common issues:</p>

                        <div class="m-5 p-5" style="text-align: center;" *ngIf="searchResultsIsLoading">
                            <p class="h4 text-muted">Searching Knowledgebase.</p>
                            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            <p class="h5 text-muted">Please wait</p>
                        </div>
                        <div class="m-5 p-5" style="text-align: center;" *ngIf="searchResultsEmpty">
                            <p class="h4 text-muted">No results found</p>
                        </div>
                        <div class="m-5 p-5" style="text-align: center;" *ngIf="refreshToken">
                            <p class="h4 text-muted">Your Authentication token has expired.</p>
                            <p class=""><a class="btn btn-primary" [routerLink]="['/search']" [queryParams]="{keyword:keywords}" [replaceUrl]="true">Resubmit Search</a></p>
                        </div>

                        <ul class="ps-3">
                            <li *ngFor="let item of searchResults | slice: (searchCurrentPage-1) * searchPageSize : searchCurrentPage * searchPageSize"><a href="{{ item.link }}" target="_blank" title=""><strong>{{ item.title }}</strong></a></li>
                        </ul>
                        <div class="d-flex flex-wrap" *ngIf="searchResults.length > 0 && searchResults.length > searchPageSize">
                            <div class="p-1">Showing Results: </div>
                            <ngb-pagination class="d-flex justify-content-start" [collectionSize]="searchCollectionSize" [pageSize]="searchPageSize" [(page)]="searchCurrentPage" />
                        </div>
                    </div>
                </div>
                <p class="fw-bold m-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                        <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                    </svg> More:
                    <a *ngIf="!hasStudentRole" href="{{ CPKBUrl }}" target="_blank">ConnectionPoint KB</a> |
                    <a href="{{ LIBKBUrl }}" target="_blank">Library KB</a> |
                    <a href="{{ ITSKBUrl }}" target="_blank">IT Support KB</a> |
                    <a href="{{ STUKBUrl }}" target="_blank">Students KB</a>
                </p>
            </div>

        </div>
    </div>
    <div class='row mtlg maxWidthWrapper mt-5' role='alert' *ngIf="isSearchFeedbackEnabled">
        <div id='feedback'>
            <p style='text-align:center'>Was this page helpful?<br />
            <button type="button" class='btn btn-primary btn-sm mx-1' (click)="feedback_thanks('helpful')">Yes</button>
            <button type="button" class='btn btn-warning btn-sm mx-1'  id="nothelpful" (click)="feedback_thanks('not_helpful')">No</button></p>
            <div class="m-auto p-1 alert alert-success w-50 align-content-center" style="text-align: center;" *ngIf="feedbackFormStatus=='submitted'">
                <p class="m-1 p-1">Thank you for your feedback !</p>
            </div>
            <div class="m-auto p-1 alert alert-warning w-50 align-content-center" style="text-align: center;" *ngIf="feedbackFormStatus=='failed'">
                <p class="m-1 p-1">There was a problem submitting your request. Please try again later.</p>
            </div>
        </div>
    </div>
</div>
<ng-template #channelListingTemplate let-channel="channel">
    <p class="h5 mb-0">
        <a [routerLink]="['/', channel.goname]" class="portlet-title h5 " (click)="sendGAClickEvent(channel.title)"><b>{{ channel.title }}</b></a>
    </p>
    <p class="portlet-description mb-0">{{ channel.description }}</p>
    <p *ngIf="showChannelEligibility" class="portlet-description mb-0 text-muted"><strong>Eligibility:</strong> {{ channel.eligibility }}</p>
</ng-template>
<ng-template #ineligibleChannelListingTemplate let-channel="channel">
    <p class="h5 mb-0 text-muted">
        <span *ngIf="!channel.has_access" class="h5"><b>{{ channel.title }}</b></span>
    </p>
    <p class="portlet-description mb-0 text-muted">{{ channel.description }}</p>
    <p class="portlet-description mb-0 text-muted"><strong>Eligibility:</strong> {{ channel.eligibility }}</p>
    <p class="portlet-description mb-0 text-muted small"><strong>GoName:</strong> /{{ channel.goname }}</p>
</ng-template>
<ng-template #eligibilityTemplate let-channel="channel">
    <p class="portlet-description mb-0"><strong>Eligibility:</strong> {{ channel.eligibility }}</p>
    <div class="card p-2">
        <p class="card-text mb-0"><strong>Eligibility:</strong> {{ channel.eligibility }}</p>
        <p class="card-text">
            <span *ngIf="channel.is_public" class="ms-2 badge text-bg-info">PUBLIC</span>
            <span *ngFor="let r of channel.included_roles" class="ms-2 badge" [class.text-bg-success]="hasRole(r)" [class.text-bg-light]="!hasRole(r)">{{ r | uppercase }}</span>
            <span *ngFor="let r of channel.excluded_roles" class="ms-2 badge" [class.text-bg-danger]="hasRole(r)" [class.text-bg-light]="!hasRole(r)">{{ r | uppercase }}</span>
        </p>
    </div>
</ng-template>
<ng-template #content let-modal>
    <form [formGroup]="feedbackForm" (ngSubmit)="submit_feedback()">
        <div class="modal-header">
            <p class="m-0 h4">What could make this page better?</p>
        </div>
        <div class="modal-body">
            <textarea id="feedback_text" name="feedback_text" class='form-control' rows="4" formControlName="feedback"></textarea><br />
            <p class="small">Thank you for helping us make PAWS better. Your comment will be forwarded to our support team.</p>
            <div class="m-1 p-1" style="text-align: center;" *ngIf="feedbackFormStatus=='sending'">
                <p class="h4 text-muted">Submitting Feedback.</p>
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <p class="h5 text-muted">Please wait</p>
            </div>
            <div class="m-1 p-1 alert alert-success" style="text-align: center;" *ngIf="feedbackFormStatus=='success'">
                <p class="m-1 p-1">Thank you for your feedback !</p>
            </div>
            <div class="m-1 p-1 alert alert-warning" style="text-align: center;" *ngIf="feedbackFormStatus=='modalFailed'">
                <p class="m-1 p-1">There was a problem submitting your request. Please try again later.</p>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Send feedback</button>
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Dismiss</button>
        </div>
    </form>
</ng-template>
