import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {UserService} from "../../services/user.service";
import {Observable} from "rxjs/internal/Observable";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {AppConfigService} from "../../app-config.service";

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    // styleUrls: ['./notification.component.scss'],
    animations: [
        trigger('TopNotificationState', [
            // ...
            state('open', style({
                top: '0',
                opacity: 1
            })),
            state('closed', style({
                top: '-200px',
                opacity: 0.8
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ]),
    ]
})
export class NotificationComponent {

    /**
     * sessionTimeout | alert
     */
    @Input()
    type: string = "alert";

    /**
     * success || info || warning || danger
     */
    @Input()
    style: string = "warning";

    public isOpen = false;
    public timeLeft: any;

    private interval_1: any;

    constructor(
        private router: Router,
        private userService: UserService,
        private appConfig: AppConfigService
    ) {
    }

    ngOnDestroy() {
        clearInterval(this.interval_1);
    }

    ngOnInit() {
        let me = this;
        if ( me.isSessionTimeoutType ) {
            if ( me.userService.hasAccessToken() ) {
                me.initSessionTimeout();
            } else {
                me.userService.userReady$.subscribe( {
                    next: (resp: any) => {
                        if ( resp.id > 0 && me.userService.hasAccessToken() ) {
                            me.initSessionTimeout();
                        }
                    }
                } );
            }
        }
    }

    public toggle() {
        this.isOpen = !this.isOpen;
    }

    private initSessionTimeout() {
        let me = this;
        this._sessionTimeoutTime$.subscribe({
            next: (value => {
                me.timeLeft = value;
                // console.log( "Time Left: " + value );
                if (me.timeLeft < this.appConfig.getSessionTimeoutNotificationInSeconds() && !me.isOpen) {
                    // console.log( "OPENING" );
                    me.isOpen = true;
                } else if ( me.timeLeft >= this.appConfig.getSessionTimeoutNotificationInSeconds() && me.isOpen ) {
                    // console.log( "CLOSING" );
                    me.isOpen = false;
                } else if (me.timeLeft <= 0) {
                    // console.log( "REDIRECTING" );
                    clearInterval(this.interval_1);
                    me.router.navigate(['/logout']);
                } else if ( me.timeLeft < 0 ) {
                    // console.log( "STOPPING" );
                    clearInterval(me.interval_1);
                }
            })
        });
        me.interval_1 = setInterval(() => {
            me.setTimeleft();
        }, 1000);
    }
    private _sessionTimeoutTimeOut: Subject<any> = new Subject();
    public _sessionTimeoutTime$: Observable<any> = this._sessionTimeoutTimeOut.asObservable();
    private setTimeleft(): void {
        if ( this.userService.hasAccessToken() ) {
            if (this.type == 'sessionTimeout') {
                let now = new Date().getTime();
                let timeLeftInSeconds = Math.floor((this.userService.getCookieExpiresAtInSeconds() - now) / 1000);
                this._sessionTimeoutTimeOut.next(timeLeftInSeconds);
            }
        }
    }
    public get isSessionTimeoutType(): boolean {
        return ( this.type == 'sessionTimeout' );
    }
    public refreshLogin() {
        let me = this;
        me.userService.refreshUserToken();
    }
}
