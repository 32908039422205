import {HttpClient, HttpParameterCodec, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserInfo} from '../model/userInfo';
import {TimelineData} from '../model/timelineData';
import {CalendarData} from "../model/calendarData";
import {Observable} from "rxjs";
import {AccessToken} from "../model/accessToken";
import {CustomChannelWithFavorites} from "../model/customChannelWithFavorites";
import {UserChannel} from '../model/userChannel';
import { AnnouncementResponse } from '../model/announcementResponse';
import {AppConfigService} from "../app-config.service";

/**
 * Custom encoder to use the browser standard URL encode function.
 * Had issues with '+' being converted to '%20' instead of '%2B'
 */
 class CustomEncoder implements HttpParameterCodec {
  // standardEncoding()
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {

    private api: string = "";
    constructor(
        private http: HttpClient,
        private appConfig: AppConfigService
    ) {
        this.api = this.appConfig.getApiUrl();
    }
    /**
     * Build HTTP Params object for POST/PUT/PATCH http calls
     * @param data
     * @private
     * let body = [ { "key": "...", "value": "..." }, ... ]
     */
    private getHTTPParams( data: Array<any> ) {
        let HTTPParams = new HttpParams({encoder: new CustomEncoder()});
        data.forEach( (p) => {
            HTTPParams = HTTPParams.set( p.key, p.value );
        } );
        return HTTPParams;
    }
    /**
     * Build HTTP Params object for GET/DELETE http calls
     * @param data
     * @private
     */
    private getURLParams( data: Array<any> ) {
        let httpParams = new HttpParams({encoder: new CustomEncoder()});
        data.forEach( (p) => {
            httpParams = httpParams.set( p.key, p.value );
        } );
        return httpParams;
    }
    /**
     * Sends API GET request to refresh user info.
     * @returns {Observable<UserInfo>}
     */
    public getUserInfo(): Observable<UserInfo> {
        let url = this.api + "/user/refresh";
        return this.http.get<UserInfo>(url);
    }
    /**
     * Sends API GET request to /login
     * @param code
     * @returns {Observable<UserInfo>}
     */
    public login( code: string ): Observable<UserInfo> {
        let url = this.api + "/public/authenticate/login";
        let body = [
            { "key": "token", "value": code }
        ];
        let options = {
            params: this.getHTTPParams(body)
        };
        return this.http.get<UserInfo>( url, options );
    }
    /**
     * Sends APOI POST request to /refresh-token
     * @returns Observable<AccessToken>
     */
    public refreshToken(): Observable<AccessToken> {
        let url = this.api + "/public/authenticate/refresh-token";
        let body: any[] = [
            // { "key": "refresh_token", "value":  refreshToken }
        ];
        let httpBody = this.getHTTPParams(body);
        // console.log( httpBody );
        return this.http.post<AccessToken>( url, httpBody );
    }
    /**
     * Sends API POST request to /logout
     */
    public logout() {
        let url = this.api + "/user/logout";
        let body: any[] = [
            // { "key": "token", "value": access_token },
            // { "key": "token_type_hint", "value":  "access_token" },
            // { "key": "refresh_token", "value":  refresh_token }
        ];
        let httpBody = this.getHTTPParams(body);
        return this.http.post( url, httpBody );
    }
    /**
     * Sends API GET request to /get-timeline
     * @param start
     * @param end
     * @returns {Observable<TimelineData>}
     */
    public getTimelineData( start: string, end: string ): Observable<TimelineData> {
        let url = this.api + "/feeds/get-timeline";
        let data = [
            { "key": "start", "value": start },
            { "key": "end", "value": end }
        ]
        let options = {
            params: this.getURLParams(data),
        };
        return this.http.get<TimelineData>( url, options );
    }
    /**
     * Sends API GET request to /get-announcements
     * @param start
     * @param end
     * @returns {Observable<TimelineData>}
     */
    public getAnnouncementData( start: string, end: string ): Observable<AnnouncementResponse> {
        let url = this.api + "/feeds/get-announcements";
        let data = [
            { "key": "start", "value": start },
            { "key": "end", "value": end }
        ]
        let options = {
            params: this.getURLParams(data),
        };
        return this.http.get<AnnouncementResponse>( url, options );
    }
    /**
     * Sends API GET request to /find-channels-by-current-user
     * @returns {Observable<Channel[]>}
     */
    public getChannels(): Observable<CustomChannelWithFavorites[]> {
        let url = this.api + "/channels/find-channels-by-current-user";
        return this.http.get<CustomChannelWithFavorites[]>(url);
    }
    /**
     * Sends API GET request to /get-calendar
     * @returns {Observable<Event[]>}
     */
    public getEvents(): Observable<CalendarData> {
        let url = this.api + "/feeds/get-calendar";
        return this.http.get<CalendarData>( url );
    }
    /**
     * Sends API POST request to /favorite/save
     * @param channelID
     */
    public addFavorite(channelID: number) {
        let url = this.api + "/userchannel/favorite/save";
        let body: Array<any> = [
            { "key": "channel_id", "value": channelID }
        ];
        let params = this.getHTTPParams(body);
        return this.http.post(url, params);
    }

    /**
     * Sends API Patch request to /favorite/update
     * @param channelIDs
     * @returns An observerable that can be subscribed to
     */
    public updateFavorite(channelIDs: number[]): Observable<UserChannel[]>{
        let url = this.api + "/userchannel/favorite/update";
        let body: Array<any> = [
            { "key": "favorite_array", "value": channelIDs }
        ];
        let params = this.getHTTPParams(body);
        return this.http.patch<UserChannel[]>(url, params);
    }

    /**
     * Sends API DELETE request to /favorite/delete
     * @param channelID
     */
    public deleteFavorite(channelID: number) {
        let url = this.api + "/userchannel/favorite/delete";
        let data = [
            { "key": "channel_id", "value": channelID }
        ];
        let options = {
            params: this.getURLParams(data),
        };
        return this.http.delete(url,options);
    }
    /**
     * Update Timeline preferences
     */
    public saveTimelinePreferences( data: Array<any> ) {
        let url = this.api + "/user-preference/save-news-preferences";
        return this.http.post(url, JSON.stringify( {data: data} ));
    }

    /**
     * Update Calendar preferences
     */
    public saveCalendarPreferences( data: Array<any> ) {
        let url = this.api + "/user-preference/save-event-preferences";
        return this.http.post(url, JSON.stringify( {data: data} ));
    }

    public getCountDownEvents() {
        let url = this.appConfig.getCountDownEventsUrl();
        return this.http.get(url,{headers:{skip:"true","Content-Type":"application/javascript"}} );
    }

    /**
     * Marks a sticky announcement as deleted so it does not appear anymore.
     * @param action Usually is either an accept or a dismiss
     * @param announcementID - The ID to mark as deleted
     * @returns Observable<Any> Success is blank and error is fail message.
     */
    public markAsDeletedByUserIdAndAnnouncementId(action: String, announcementID: Number) {
        let url = this.api + "/user-preference/mark-as-deleted-by-user-id-and-announcement-id";
        let body: Array<any> = [
            { "key": "action", "value": action },
            { "key": "announcement_id", "value": announcementID }
        ];
        let params = this.getHTTPParams(body);
        return this.http.post(url,params);
    }

    /**
     * Updates the recently viewed
     * @param channelIDs - The IDs that needs to be updated
     * @returns Observable<Any>
     */
    public updateRecentlyViewed(channelIDs: Array<number>){
        let url =  this.api + "/user-preference/update-recently-viewed";
        let body: Array<any> = [
            {"key": "recently_viewed_array", "value": channelIDs}
        ];
        let params = this.getHTTPParams(body);
        return this.http.patch(url,params);
    }

    public sendReportAnnouncement( reportReason: string, comment: string, nsid: string, announcementId: number ) {
        let url = this.api + "/feeds/report-announcement";
        let body : Array<any> = [
            { "key": "comment", "value": comment },
            { "key": "reportReason", "value": reportReason },
            { "key": "username", "value": nsid },
            { "key": "announcementId", "value": announcementId }
        ];
        let params = this.getHTTPParams(body);
        return this.http.post( url, params );
    }

    /**
     * Gets the mail unread number.
     * @returns Observable<Any>
     */
    public getUnreadCount(){
        let url = this.api + "/mail/get-unread-count"
        return this.http.get(url);
    }

    /**
     * Gets the Health Check
     * @returns Observable<Any>
     */
    public getAPIHealthCheck() {
        let url = this.api + "/actuator/health";
        return this.http.get(url);
    }

    public logFrontEndError( message:string ) {
        let url: string = this.api + "/log-frontend-error";
        let body: Array<any> = [
            { "key": "error", "value": message }
        ];
        let params = this.getHTTPParams(body);
        return this.http.post(url,params);
    }

    public searchUsask( search:string ) {
        let url = this.api + "/search?keywords=" + search;
        return this.http.get(url);
    }

}
