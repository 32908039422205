import {Injectable} from '@angular/core';
import {ApiRequestService} from "./api-request.service";
import {delay, Observable, Subject} from "rxjs";
import {UnreadCount} from "../model/unreadCount";
import {UserService} from "./user.service";
import {AppConfigService} from "../app-config.service";

@Injectable({
    providedIn: 'root'
  })
export class MailNotificationService {
    /**
     * A Session Storage object to hold the count in.
     * @type {Storage}
     */
    private storage:Storage = sessionStorage; // sessionStorage or LocalStrage

    /**
     * Value of unread mail count
     */
    public unreadCountValue:string = "";

    /**
     * Mail unread count observable
     */
    private _mailUnreadOut: Subject<string> = new Subject();

    /**
     * Mail unread count watcher that anyone can subscribe to.
     */
    public mailUnreadReady$:Observable<any> = this._mailUnreadOut.asObservable();

    /**
     * To convert milliseconds into a minute
     */
    private toMinutesConstant = 60000;

    /**
     * The name of the session storage variable
     */
    private unreadCountStorageName = "paws_unread_count";

    constructor(
        private api: ApiRequestService,
        private userService: UserService,
        private appConfig: AppConfigService
    ){
        // this.getUnreadCount();
    }

    /**
     * Notifies subscribers due to new content
     * @param count - The content that is new
     */
    public mailUnreadReady(count:string){
        this._mailUnreadOut.next(count);
    }

    /**
     * Stores the count and timestamp in milliseconds in session storage
     * @param count The number of unread emails
     */
    public storeUnreadCount(count:string){
        let currentTime = new Date().getTime();
        let unreadCount:UnreadCount = {
            count: count,
            date: currentTime
        };
        this.storage.setItem(this.unreadCountStorageName, JSON.stringify(unreadCount) );
    }

    /**
     * Checks to see if there is a session storage entry for unread count
     * @returns True if there is a sesssion storage entry, false otherwise.
     */
    public hasUnreadCount(): boolean {
        return this.storage.getItem(this.unreadCountStorageName)?true:false;
    }

    /**
     * Gets the unread mail count and sends it to subscribers
     * @returns The unread mail count in a string value
     */
    public getUnreadCount() {
        let me = this;
        if ( !this.userService.hasAccessToken() ) {
            setTimeout( () => { me.getUnreadCount() }, 5000);
        } else {
            if(this.hasUnreadCount()){
                let unreadCount:UnreadCount = JSON.parse(this.storage.getItem(this.unreadCountStorageName));
                if(this.hasExpired(unreadCount.date)){
                    this.callGetUnreadCount();

                }
                else {
                    me.unreadCountValue = unreadCount.count;
                    this.mailUnreadReady(me.unreadCountValue);
                }
            }
            else{
                this.callGetUnreadCount();
            }
        }
    }



    /**
     * Gets the unread mail count value that was obtained from unreadCountValue
     * @returns The unread mail count in a string value
     */
    public getUnreadCountValue():string {
        return this.unreadCountValue;
    }

    /**
     * Sends the API call to get the unread mail count
     * Stores
     */
    public callGetUnreadCount(){
        let me = this;

        if ( this.userService.hasAccessToken() && !this.userService.isTokenExpired() ) {
            this.api.getUnreadCount().subscribe(
                {
                    next(resp: any) {
                        if (resp.count && resp.count != "0") {
                            me.unreadCountValue = resp.count;
                            me.storeUnreadCount(me.unreadCountValue);
                        } else {
                            me.unreadCountValue = "";
                            me.storeUnreadCount("");
                        }
                        me.mailUnreadReady(resp.count);
                    },
                    error() {
                        me.unreadCountValue = "";
                        me.storeUnreadCount("");
                        me.mailUnreadReady("");
                    }
                });
        }
    }

    /**
     * Checks to see if the time alloted has expired for the session storage
     * @param previousTime The time in milliseconds listed in the session storage
     * @returns True if the time is greater than what was allocated in the environment variable. False otherwise.
     */
    public hasExpired(previousTime:number):boolean {
        if(previousTime){
            let currentTime:number = new Date().getTime();
            let timeDifference = Math.floor((currentTime - previousTime) / this.toMinutesConstant);
            return(timeDifference >= this.appConfig.getUnreadExpiryTimeInSeconds()) ? true:false;
        }
        else{
            return false;
        }
    }

    /**
     * For debugging purposes to make a fake call.
     * @returns An arbitrary number in string format.
     */
    // private fakeCall():any{
    //     let countTest = {
    //         count: "2"
    //     }
    //     return countTest;
    // }

}
