<main id="content" tabindex="-1" class="m-0">
    <div id="homepage-top" class="col col-md-12 col-lg-8 col-xl-9 py-0 px-0">
<!--        <div class="pt-3" id="usask-search">-->

<!--                <div class="input-group">-->
<!--                    <input type="text" class="form-control" id="searchInput" placeholder="Search PAWS (eg. Registration)" [formControl]="searchTerm" (keyup.enter)="submitSearch()" />-->
<!--                    <label class="visually-hidden" for="searchInput">Search the U of S</label>-->
<!--                    <button type="submit" class="btn btn-sm btn-outline-secondary px-3" aria-label="search" (click)="submitSearch()" (keyup.enter)="submitSearch()">-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">-->
<!--                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />-->
<!--                        </svg>-->
<!--                    </button>-->
<!--                </div>-->
<!--        </div>-->

        <app-recommender></app-recommender>
    </div>
    <div id="home-page-tab-controls" class="d-block d-lg-none">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item w-50" role="presentation">
                <button class="nav-link fw-bold w-100 active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#announcement-pane" type="button" role="tab" aria-controls="announcement-pane" aria-selected="false">Announcements</button>
            </li>
            <li class="nav-item w-50" role="presentation">
                <button class="nav-link fw-bold w-100" id="contact-tab" data-bs-toggle="tab" data-bs-target="#events-pane" type="button" role="tab" aria-controls="events-pane" aria-selected="false">Dates and Events</button>
            </li>
        </ul>
    </div>

    <!-- <div [ngClass]="{'tab-content': isMobile(), 'border-start' : isMobile(), 'border-end': isMobile(),'row': !isMobile() }" class="m-0 p-0" style="height:100%;"> -->
    <div [ngClass]="{'tab-content': isMobile(), 'border-start' : isMobile(), 'border-end': isMobile()}" class="m-0 p-0 row" style="height:100%;">
        <div class="" [ngClass]="{'tab-pane':isMobile(),'show':isMobile(),'fade':isMobile(),'active':isMobile(),'col-lg-8 col-xl-9': !isMobile()}" id="announcement-pane" role="tabpanel">
            <app-timeline></app-timeline>
        </div>
        <div class="" [ngClass]="{'bg-white': isMobile(),'tab-pane':isMobile(),'fade':isMobile(),'show':isMobile(),'col-lg-4 col-xl-3': !isMobile()}" id="events-pane" role="tabpanel">
            <app-calendar [ngClass]="{'isMobile': isMobile(), 'isDesktop': !isMobile() }" style="top:-{{calendarOffset}}px"></app-calendar>
        </div>
    </div>

<!--    <div class="col col-xl-9">-->
<!--        <app-timeline></app-timeline>-->
<!--    </div>-->
<!--    <div class="col-lg-5 col-xl-3 px-4 d-none d-lg-block bg-paws">-->
<!--        <app-calendar></app-calendar>-->
<!--    </div>-->
</main>
