import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, convertToParamMap, Params, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {ApiRequestService} from "../../services/api-request.service";
import {ChannelsService} from "../../services/channels.service";
import {UserInfo} from "../../model/userInfo";
import {MetricsService} from "../../services/metrics.service";
import {AppConfigService} from "../../app-config.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public errorMessage: string = "";
    public redirectToCas: boolean = false;
    public redirectToPAWS: boolean = false;
    public loadingUser: boolean = false;
    public casAuthError: boolean = false;
    public userExpiredError: boolean = false;
    public refreshLogin: boolean = false;
    public networkError: boolean = false;
    public userNotFoundError: boolean = false;
    public tokenError: boolean = false;
    public window;
    private interval_1: any;
    private interval_2: any;

    constructor(
        @Inject('Window') window: Window,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private api: ApiRequestService,
        private userService: UserService,
        private channelService: ChannelsService,
        private metricsService: MetricsService,
        private appConfig: AppConfigService
    ) {
        this.window = window;
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }
    public getOauthLoginUrl() {
        let host = this.appConfig.getCasUrl();
        let uri = "/oauth2.0/authorize";
        let params = "response_type=code&client_id="+this.appConfig.getCasClientId()+"&redirect_uri="+encodeURIComponent(this.appConfig.getAppUrl());
        return host + uri + '?' + params;
    }
    ngOnDestroy() {
        clearInterval( this.interval_1 );
        clearInterval( this.interval_2 );
    }
    ngOnInit() {
        let me = this;
        me.setUI('redirectToCas');

        this.activatedRoute.queryParams.subscribe({
            next: (params: Params) => {
                let queryParams: Params = {};
                let queryMap = convertToParamMap(params);
                if (queryMap.keys.length > 0) {
                    queryMap.keys.forEach((k) => {
                        if (k != 'channel' && k != 'code'){
                            queryParams[k] = decodeURIComponent( queryMap.get(k) );
                        }
                    });
                }
                let url = "/";

                if (params['channel'] != undefined && params['channel'] != '/') {
                    let pieces = decodeURIComponent( params['channel'] ).split("?");
                    if ( pieces.length > 1 ) {
                        url = url + pieces[0];
                        let qp = pieces[1].split("&");
                        qp.forEach( (query: string) => {
                            queryParams[ query.split("=")[0] ] = query.split("=")[1];
                        } );
                    } else {
                        url = url + decodeURIComponent( params['channel'] );
                    }
                }
                // console.log( "params: " , params );
                // console.log( "queryParams: " , queryParams );
                // console.log( "getToken: " + me.userService.getToken() );
                // console.log( "isLoggedIn: " + me.userService.isLoggedIn() );
                // console.log( "hasUserToken: " + me.userService.hasUserToken() );
                // console.log( "hasAccessToken: " + me.userService.hasAccessToken() );
                // console.log( "isTokenExpired: " + me.userService.isTokenExpired() );

                if ( !this.userService.hasAccessToken() ) {
                    me.setUI('redirectToCas');
                    // console.log( "code: " + (params['code'] != undefined ? "true" : "false" ) );
                    if (params['code'] != undefined) {
                        // console.log( "loadingUser: " );
                        me.setUI('loadingUser');
                        me.login(params, url, queryParams);
                    } else {
                        // console.log( "NO ACCESS TOKEN: " + this.userService.isTokenExpired() );
                        me.userService.logout();
                        me.setUI("redirectToCas");
                        me.interval_1 = setTimeout(() => {
                            // me.window.location.href = me.getOauthLoginUrl();
                            me.casLogin(params);
                        }, 1000);
                    }
                } else if ( this.userService.hasAccessToken() && !me.userService.hasUserToken() ) {
                    // console.log( "refreshUserInfo: " + this.userService.hasUserToken() );
                    me.refreshUserInfo(params, url, queryParams);
                } else {
                    // If the user is Logged in ...
                    // console.log( "isTokenExpired 3: " + this.userService.isTokenExpired() );
                    if ( me.userService.isTokenExpired()) {
                        me.setUI('refreshLogin');
                        me.refresh(params, url, queryParams);
                    } else {
                        me.router.navigate([url], {queryParams: queryParams});
                    }
                }
            },
            error: (err: Error) => console.error('Observer got an error: ' + err)
        });
        // Refresh Token
        // if ( this.userService.isLoggedIn() && this.userService.isTokenExpired() ) {
        //     console.log( "Refresh 2" );
        //     me.refresh( {}, "/", {} );
        // }
    }
    public login( params: any, url: string, queryParams: any ) {
        let me = this;
        me.api.login(params['code']).subscribe({
            next: (resp: UserInfo) => {
                // console.log( "LOGIN: " , resp );
                me.userService.storeToken(resp.token);
                me.userService.processAPIUser(resp.user);
                me.channelService.reloadChannels(resp.channels);
                if(resp.recently_viewed){
                    me.userService.importRecentlyViewed(resp.recently_viewed);
                }
                me.channelService.reloadRecentlyVieweds(this.userService.getRecentlyViewed());
                // Send Affiliation to GA4
                me.metricsService.initGA( me.userService.getRoles() );
                // me.metricsService.sendError("LOGIN SUCCESS ("+me.userService.getNsid()+") - " + url );
                // console.log( "LOGIN REDIRECT: " + url + ":",  queryParams );
                me.router.navigate([url], {queryParams: queryParams});
            },
            error: error => {
                if (error.status == 401) {
                    me.errorMessage = error.error.message;
                    if ( error.error.message.indexOf("User not authenticated") >= 0 ) {
                        me.setUI('casAuthError');
                    } else if ( error.error.message.indexOf("CAS authentication failed. Invalid Token") >= 0 ) {
                        me.setUI('tokenError');
                    } else if ( error.error.message.indexOf("User not found") >= 0 ) {
                        me.setUI('userNotFound');
                    } else if ( error.error.message.indexOf("CAS authentication failed") >= 0 ) {
                        me.setUI('casAuthError');
                    }
                } else if (error.status == 404) {
                    me.setUI("userExpiredError");
                } else {
                    me.setUI('networkError');
                }
                me.userService.logout();
                me.metricsService.sendError("LOGIN FAILED ("+me.userService.getNsid()+") - " + error.status + " - " + error.error.message );
            }
        });

    }
    public refresh( params: any, url: string, queryParams: any ) {
        let me = this;
        me.setUI('refreshLogin');
        me.api.refreshToken().subscribe({
            next: (resp: any) => {
                // console.log( "REFRESH: ", resp );
                // console.log( "REFRESH: ", url );
                // console.log( "REFRESH: ", queryParams );
                if ( resp == null ) {
                    // me.metricsService.sendError("REFRESH TOKEN ("+me.userService.getNsid()+")- RESP is NULL" );
                    me.setUI('tokenError');
                    me.userService.logout();
                    // me.router.navigate(["/"]);
                    me.router.navigate(["/logout"], {queryParams: queryParams});
                }
                me.userService.storeToken(resp);
                me.api.getUserInfo().subscribe({
                    next: (userresp: UserInfo) => {
                        me.userService.processAPIUser(userresp.user);
                        me.channelService.reloadChannels(userresp.channels);
                        if (resp.recently_viewed) {
                            me.userService.importRecentlyViewed(resp.recently_viewed);
                        }
                        me.channelService.reloadRecentlyVieweds(this.userService.getRecentlyViewed());
                        // me.metricsService.sendError("REFRESH TOKEN 2 ("+me.userService.getNsid()+")- SUCCESS" );
                        // console.log( "REFRESH REDIRECT: " + url + ":" + queryParams.toString() );
                        me.router.navigate([url], {queryParams: queryParams});
                    }
                });
            },
            error: (err: any) => {
                me.userService.logout();
                me.setUI("redirectToCas");
                me.interval_1 = setTimeout(() => {
                    // me.window.location.href = me.getOauthLoginUrl();
                    me.casLogin(params);
                }, 1000);
                me.metricsService.sendError("REFRESH FAILED ("+me.userService.getNsid()+") - " + err.status + " - " + err.error.message );
            }
        });
    }

    public refreshUserInfo( params: any, url: string, queryParams: any ) {
        let me = this;
        me.api.getUserInfo().subscribe({
            next: (userresp: UserInfo) => {
                // console.log( "REFRESH USER: ", userresp );
                me.userService.processAPIUser(userresp.user);
                me.channelService.reloadChannels(userresp.channels);
                // if (resp.recently_viewed) {
                //     me.userService.importRecentlyViewed(resp.recently_viewed);
                // }
                me.channelService.reloadRecentlyVieweds(this.userService.getRecentlyViewed());
                // me.metricsService.sendError("REFRESH USER ("+me.userService.getNsid()+")- SUCCESS" );
                me.router.navigate([url], {queryParams: queryParams});
            }, error: (err) => {
                console.log(err);
                me.router.navigate(["/logout"], {queryParams: queryParams});
            }
        });

    }
    public isLoggedIn() {
        return this.userService.isLoggedIn();
    }
    public get CASOauthURL() {
        return this.getOauthLoginUrl();
    }
    public casLogin( params:Params ) {
        // https://vpfr-mj0jxvdb.usask.ca:4200/?wbraid=WBRAID
        // https://vpfr-mj0jxvdb.usask.ca:4200/login?wbraid=WBRAID
        // https://vpfr-mj0jxvdb.usask.ca:4200/canvas?wbraid=WBRAID
        // https://vpfr-mj0jxvdb.usask.ca:4200/canvas
        // https://vpfr-mj0jxvdb.usask.ca:4200/canvas/subpage.php?wbraid=WBRAID
        // https://vpfr-mj0jxvdb.usask.ca:4200/canvas/subpage.php
        let url = this.CASOauthURL;
        let path: string = "";
        // console.log( "CAS PARAMS: ", params );
        // Must convert URI params to query params so they will survive CAS flow
        // DO NOT include '/login'
        if ( params['channel'] != undefined && params['channel'] != '' && params['channel'] != '/' && params['channel'] != 'login' ) {
            url = url + encodeURIComponent("?channel="+params['channel']);
        } else {
            url = url + "";
        }
        if ( params != undefined ) {
            let strParams: string[] = [];
            Object.entries(params).forEach( ([key, value], index) => {
                if ( key != 'channel' && key != 'code' )
                    strParams.push( key+"="+value );
            } );
            if ( strParams.length > 0 ) {
                path = path + ( params['channel'] != undefined && params['channel'] != '' && params['channel'] != 'login' ? "&" : "?" ) + strParams.join("&");
                url = url + encodeURIComponent(path);
            }
        }
        // console.log( "CAS REDIRECT: " + url );
        this.window.location.href = url;
    }
    private setUI( div: string ) {
        this.redirectToCas = false;
        this.redirectToPAWS = false;
        this.loadingUser = false;
        this.casAuthError = false;
        this.userExpiredError = false;
        this.refreshLogin = false;
        this.networkError = false;
        this.userNotFoundError = false;
        this.tokenError = false;
        switch( div ) {
            case 'redirectToCas':
                this.redirectToCas = true;
                break;
            case 'redirectToPAWS':
                this.redirectToPAWS = true;
                break;
            case 'loadingUser':
                this.loadingUser = true;
                break;
            case 'casAuthError':
                this.casAuthError = true;
                break;
            case 'userExpiredError' :
                this.userExpiredError = true;
                break;
            case 'refreshLogin' :
                this.refreshLogin = true;
                break;
            case 'networkError' :
                this.networkError = true;
                break;
            case 'userNotFound' :
                this.userNotFoundError = true;
                break;
            case 'tokenError' :
                this.tokenError = true;
                break;
        }
    }
    public get helpLink() {
        return this.appConfig.getHelpLink();
    }
}
