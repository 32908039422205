import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
/** Services */
import {UserService} from './services/user.service';
import {ChannelsService} from "./services/channels.service";
import {AuthInterceptor} from "./services/auth-intercept.service";
import {AppHttpInterceptor} from "./services/response-intercept.service";
import {MetricsService} from "./services/metrics.service";
import {CookieService} from "./services/cookie.service";
import {PromptUpdateService} from "./services/prompt-update.service";
/** Components */
import {AppComponent} from './app.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {TimelineComponent} from './components/timeline/timeline.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {HomePageComponent} from './components/home-page/home-page.component';
import {LoginComponent} from "./components/login/login.component";
import {LogoutComponent} from "./components/logout/logout.component";
import {ChannelComponent} from "./components/channel/channel.component";
import {ChannelListComponent} from "./components/channel-list/channel-list.component";
import {RecommenderComponent} from './components/recommender/recommender.component';
import { ErrorpageComponent } from './components/errorpage/errorpage.component';
import { NotificationComponent } from './components/notification/notification.component';
/** Extras */
import {SafeUrlPipe} from "./pipes/safe-url.pipe";
import {RemovewhitespacesPipe} from "./pipes/remove-white-sapces.pipe";
import {KeysPipe} from "./pipes/keys.pipe";
import {FormatHtmlPipe} from './pipes/format-html.pipe';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { SearchComponent } from './components/search/search.component';
import {NgbPagination} from "@ng-bootstrap/ng-bootstrap";
// import {NgbToast} from "@ng-bootstrap/ng-bootstrap";
// import {ToastsContainer} from "./components/toast-global/toasts-container.component";
// import {NgbdToastGlobal} from "./components/toast-global/toast-global.component";

@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        TimelineComponent,
        CalendarComponent,
        HomePageComponent,
        LoginComponent,
        LogoutComponent,
        ChannelComponent,
        ChannelListComponent,
        SafeUrlPipe,
        RemovewhitespacesPipe,
        KeysPipe,
        RecommenderComponent,
        FormatHtmlPipe,
        ErrorpageComponent,
        NotificationComponent,
        SearchComponent,
    ],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		RouterModule,
		ReactiveFormsModule,
		FormsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.hasServiceWorker,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		NgbPagination,
		// NgbToast,
		// ToastsContainer,
		// NgbdToastGlobal,
	],
    providers: [
        { provide: 'Window',  useValue: window },
        UserService,
        ChannelsService,
        MetricsService,
        CookieService,
        PromptUpdateService,
        // CheckForUpdateService,
        // LogUpdateService,
        CacheStorage,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule { }
