import {Component, Input, OnInit,} from '@angular/core';
import {ChannelsService} from 'src/app/services/channels.service';
import {UserService} from 'src/app/services/user.service';
import {CustomChannelWithFavorites} from "../../model/customChannelWithFavorites";
import {UserChannel} from 'src/app/model/userChannel';
import {MailNotificationService} from 'src/app/services/mail-notification.service';
import {MetricsService} from "../../services/metrics.service";
import {User} from "../../model/user";
import {AppConfigService} from "../../app-config.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

    @Input()
    isMobile: string = "false";

    public favorites: Array<CustomChannelWithFavorites> = [];
    public icons: Array<CustomChannelWithFavorites> = [];
    public internalIcons: Array<CustomChannelWithFavorites> = [];
    public externalIcons: Array<CustomChannelWithFavorites> = [];
    public myCollege: Array<CustomChannelWithFavorites> = [];
    public recentlyViewed: Array<CustomChannelWithFavorites> = [];

    public userName: string = '';
    public myProfileLink: string = "";
    public helpLink: string = "";

    public currentDragged: number = null;
    public dragIndex: number = null;
    public dropIndex: number = null;
    public moved: boolean = false;

    // public draggedEvent: DragEvent;
    // public draggedElement: HTMLInputElement;
    private idRecognition: string = "favorite_";

    public hasChannels: boolean = false;

    public hasUnreadCount: boolean = false;
    public unreadCount: string = "";

    // private favoriteNotifierSubscription: Subscription;

    constructor(
        private userService: UserService,
        private channelService: ChannelsService,
        private mailNoticationService: MailNotificationService,
        private metricsService: MetricsService,
        private appConfig: AppConfigService
    ) {
        let me = this;
        me.favorites = me.channelService.getFavorites();
        me.sortIcons( me.channelService.getIcons() );
        me.myCollege = me.channelService.getMyCollege();
        me.userName = me.userService.getUserName();
        me.myProfileLink = this.appConfig.getMyProfileLink();
        me.helpLink = this.appConfig.getHelpLink();
        me.recentlyViewed = me.channelService.getRecentlyViewedAsChannelArray();
    }
    ngOnInit(): void {
        let me = this;
        this.userService.userReady$.subscribe({
            next: (user: User) => {
                me.userName = user.displayName;
            }
        });
        // this.channelService.loadChannels();
        this.channelService.channelsReady$.subscribe( () => {
            // console.log( "Service is READY" );
            me.favorites = me.channelService.getFavorites();
            me.sortIcons( me.channelService.getIcons() );
            me.myCollege = me.channelService.getMyCollege();
            me.userName = me.userService.getUserName();
            me.hasChannels = true;
        } );

        this.channelService.recentlyViewedsReady$.subscribe(() => {
            me.recentlyViewed = me.channelService.getRecentlyViewedAsChannelArray();
        });
        me.getUnreadCount();
    }
    private sortIcons( icons: Array<CustomChannelWithFavorites> ) {
        let me = this;
        me.externalIcons = new Array<CustomChannelWithFavorites>();
        me.internalIcons = new Array<CustomChannelWithFavorites>();
        if ( icons.length > 0 ) {
            icons.forEach( (i:CustomChannelWithFavorites) => {
                if ( i.is_icon ) me.internalIcons.push(i);
                else if ( i.category == "Icon" ) {
                    if ( me.getChannelURL(i).startsWith("/") ) me.internalIcons.push(i);
                    else me.externalIcons.push(i);
                }
                // if ( !me.getChannelURL(i).includes("://paw") ) me.externalIcons.push(i);
                // else me.internalIcons.push(i);
                // if ( i.category == "Icon" ) me.externalIcons.push(i);
                // else
            } );
        }
    }
    public isLoggedIn() {
        return this.userService.isLoggedIn();
        // if ( this.userService.getUserName() !== null )
        //     return true;
        // return false;
    }
    public isExternal( channel:CustomChannelWithFavorites ) {
        if ( channel.category == "Icon" ) return true;
        else return false;
    }
    public go( url: string ) {
        // console.log( url );
        window.open( url );
    }
    public isEmailIcon(channel:CustomChannelWithFavorites ) {
        if(channel.title.includes(this.appConfig.getEmailIconName())) return true;
        else return false;
    }
    public getIconLink( channel: CustomChannelWithFavorites ) {
        if ( channel.category == "Icon" ) {
            let url = this.getChannelURL(channel);
            if ( url.startsWith("/") ) return url.substring(1);
            else return url;
        } else return channel.goname;
    }
    public getChannelURL( channel: CustomChannelWithFavorites ) {
        if ( channel != null ) {
            return channel.url;
        }
        else return '';
    }
    public getFavoriteIdArray(favorites:Array<CustomChannelWithFavorites>){
        return favorites.map(s =>s.id);
    }
    public get revision(): string {
        return this.appConfig.getRevision();
    }
    public get pubDate(): string {
        return this.appConfig.getPublishedDate();
    }
    onDragEnd(ev:DragEvent){
        ev.preventDefault();
        let me = this;
        let dropElement:HTMLElement = me.getHTMLElement(ev);

        if(String(dropElement.id).includes(me.idRecognition)){
            me.dropIndex  = Number(String(dropElement.id).replace(me.idRecognition,""));
        }
        if (me.dragIndex != null && me.dropIndex != null && me.moved ){
            let favoriteIDs = me.getFavoriteIdArray(me.favorites);
            me.channelService.updateFavorite(favoriteIDs).subscribe((favorites:Array<UserChannel>) => {
                favorites.forEach(function(favorite){
                    me.channelService.setFavoriteByChannelId(true, favorite.channelId, favorite.displayOrder);
                })
            });
            me.dragIndex = null;
            me.dropIndex = null;
            me.moved = false;
        }
        this.currentDragged = null;
    }
    onDrag(ev:DragEvent) {
    }
    onDragEnter(ev:DragEvent){
        let me = this;
        let dropElement:HTMLElement = me.getHTMLElement(ev);
        if(String(dropElement.id).includes(me.idRecognition)){
            let dropIndexReplace = Number(String(dropElement.id).replace(me.idRecognition,""));
            if(me.dropIndex != dropIndexReplace){
                me.dropIndex  = Number(String(dropElement.id).replace(me.idRecognition,""));
            }
        }

        if (me.dragIndex != null && me.dropIndex != null && me.dragIndex != me.dropIndex ){
            let dragFavorite = me.favorites[me.dragIndex ];
            let dropFavorite = me.favorites[me.dropIndex ];
            dragFavorite.display_order = me.favorites[me.dropIndex ].display_order;
            dropFavorite.display_order = me.favorites[me.dragIndex ].display_order;

            me.favorites[me.dragIndex ] = dropFavorite;
            me.favorites[me.dropIndex] = dragFavorite;

            let tempDrag = me.dragIndex;
            let tempDrop = me.dropIndex;

            me.dragIndex = tempDrop;
            me.dropIndex = tempDrag;
            me.moved = true;
        }
    }
    onDrop(ev:DragEvent){
        ev.preventDefault();
    }
    onDragOver(ev:DragEvent) {
        ev.preventDefault();
    }
    onDragStart(ev:DragEvent) {
        let me = this;
        me.moved = false;
        me.dragIndex = null;
        me.dropIndex = null;

        let dragElement:HTMLElement = me.getHTMLElement(ev);

        let index = String(dragElement.id).replace(me.idRecognition,"");

        let numIndex = Number(index);
        let currentFav = me.favorites[numIndex];

        me.dragIndex = numIndex;
        me.currentDragged = currentFav.id;
    }
    public getHTMLElement(ev:DragEvent){
        return ev.target as HTMLElement;
    }
    public getUnreadCount() {

        let me = this;
        this.mailNoticationService.mailUnreadReady$.subscribe(() => {
            let count = me.mailNoticationService.getUnreadCountValue();
            if(count){
                me.hasUnreadCount = true;
                me.unreadCount = count;
            }
            else{
                me.hasUnreadCount = false;
                me.unreadCount = "";
            }
        });

    }
    public sendGAFavoriteClickEvent( value: string ) {
        if ( value != '' ) {
            this.metricsService.sendChannelFavoriteClick( value );
        }
    }
    public sendGAOrganizationFavoriteClickEvent( value: string ) {
        if ( value != '' ) {
            this.metricsService.sendOrganizationFavoriteClick( value );
        }
    }
    public sendGAMyCollegeClickEvent( value: string ) {
        if ( value != '' ) {
            this.metricsService.sendChannelMyCollegeClick( value );
        }
    }
    public sendGARecentlyViewedClickEvent( value: string ) {
        if ( value != '' ) {
            this.metricsService.sendChannelRecentlyViewedClick( value );
        }
    }
}
