import {Component, Inject, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ApiRequestService} from "../../services/api-request.service";
import {add, format, sub} from 'date-fns';

import {TimelineData} from "../../model/timelineData";
import {NewsFeed} from "../../model/newsFeed";
import {NewsItem} from "../../model/newsItem";
import {Announcement} from "../../model/announcement";
import {NewsData} from "../../model/newsData";
import {Category} from "../../model/category";
import {TimelineItem} from "../../model/timelineItem";
import {TimelinePreference} from 'src/app/model/timelinePreference';
import {Router} from "@angular/router";
import {AnnouncementResponse} from 'src/app/model/announcementResponse';
import {MetricsService} from "../../services/metrics.service";
import {UserService} from "../../services/user.service";
import {CacheService} from "../../services/cache.service";
import {FormBuilder,Validators} from "@angular/forms";
import {AppConfigService} from "../../app-config.service";

const fadeInAndOutTime = 500;
declare var document: any;

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
    animations: [
        trigger('openCloseSticky', [
            state('open', style({
                opacity: 1,
                height:'auto'
                // visibility: ""
            })),
            state("closed", style({
                opacity: 0,
                height:0
                // visibility:"hidden"
            })),
            transition("open <=> closed", [
                animate("0.1s")
            ])
        ]),
        trigger('fadeIn', [
            transition(':enter', [
              style({ opacity: 0 }),
              animate(fadeInAndOutTime, style({opacity: 1}))
            ])
        ]),
        trigger('fadeOut', [
            transition(':leave', [
              style({ opacity: 1 }),
              animate(fadeInAndOutTime, style({opacity: 0}))
            ])
        ])
    ]
})
export class TimelineComponent implements OnInit {

    // private timeOutIDs:number[]  = [];

    /**
     * Full list of timeline items.
     * @type Array<TimelineItem>
     */
    public timelineList: Array<TimelineItem> = [];
    /**
     * News items from API
     * @type Array<NewsItem>
     */
    public newsList: Array<NewsItem> = [];
    /**
     * Announcements from API
     * @type Array<Announcement>
     */
    public anncList: Array<Announcement> = [];
    /**
     * Sticky from API
     * @type Array<Announcement>
     */
    public stickyList: Array<TimelineItem> = [];
    /**
     * Alerts from API
     * @type Array<Announcement>
     */
    public alertList: Array<TimelineItem> = [];
    /**
     * Current view of announcements and news
     * @type Array<TimelineItem>
     */
    public timelineView: Array<TimelineItem> = [];
    /**
     * Available categories
     * @type Array<Category>
     */
    public categoryList: Array<Category> = [];
    /**
     * User preferences of opted out categories
     * @type Array<UserPreference>
     */
    public userPrefs: Array<TimelinePreference> = [];
    /**
     * Loading flag for timeline
     * @type boolean
     */
    public isLoading: boolean = false;
    /**
     * Failed flag for timeline
     * @type boolean
     */
    public hasFailed: boolean = false;
    /**
     * Shows successful load when user clicks "Show More..."
     * @type boolean
     */
    public showSuccess: boolean = false;
    /**
     * Error Message from API
     * @type string
     */
    public errorMessage: string = '';
    /**
     * Show No Events message
     * @type boolean
     */
    public showNoEventsToday: boolean = false;
    /**
     * Preferences loading flag
     * @type boolean
     */
    public prefLoading: boolean = false;
    /**
     * Preferences failed flag
     * @type boolean
     */
    public prefFailed: boolean = false;
    /**
     * Preferences success flag
     * @type boolean
     */
    public prefSuccess: boolean = false;
    /**
     * Pointer to the current show date
     */
    public currentDate: Date;
    /**
     * Timeline Page size limit
     * @type number
     */
    public pageLimit = 10;
    /**
     * Current Page #
     * @type number
     */
    public currentPage = 0;

    /**
     * Word to dismiss sticky
     */
    private dismissAction = "dismiss";

    /**
     * Word to accept sticky
     */
    private acceptAction = "accept";

    // private successSubscriptions = {};

    private loadMoreCurrentTimeout:NodeJS.Timeout = null;

    /**
     * Length of the current page
     * If we show only the Page limit, this is redundant... But if we show the first and add pages, it is relevent.
     * @type number
     */
    public currentPageLength = this.pageLimit;
    private window;

    constructor(
        private apiService: ApiRequestService,
        private router: Router,
        private metricService: MetricsService,
        private userService: UserService,
        private cache: CacheService,
        private formBuilder: FormBuilder,
        private appConfig: AppConfigService,
        @Inject('Window') window: Window
    ) {
        this.window = window;
    }

    /**
     * On Init -- Call the API and load data.
     */
    ngOnInit(): void {
        if ( this.userService.hasAccessToken() && !this.userService.isTokenExpired() ) {
            this.getData();
        }
    }

    /**
     * Property function to return the formatted Date
     * MMMM - January-December	The full month name
     * d - 1-31	Day of month
     */
    public get currentDateFormated() {
        return format( this.currentDate, "MMMM d" )
    }

    /**
     * Controls <article.isShown>
     * @param item
     */
    public isShown( item: TimelineItem ) {
        if ( this.isNews(item) ) return true;
        // if ( this.isSticky(item) ) return true;
        if ( item.collapsed == true ) return true;
        return false;
    }

    public get newAnnouncementURL() {
        return this.appConfig.getAnnouncementsUrl() + "new";
    }
    public get myAnnouncementURL() {
        return this.appConfig.getAnnouncementsUrl() + "list";
    }
    public get hasMyAnnoucements(): boolean {
        return this.userService.hasRole( this.appConfig.getTARole() );
    }
    public get myProfileLink(): string {
        return this.appConfig.getMyProfileLink();
    }

    /**
     * Returns if the article is a sticky
     * Sets <article.isSticky>
     * @param item
     */
    public isSticky( item: TimelineItem ) {
        if ( item.isSticky == 1 ) return true;
        else return false;
    }

    public isAlert( item: TimelineItem ) {
        if ( item.categoryCode == 'EMRG' ) return true;
        else return false;
    }
    /**
     * Sets <article.isNews>
     * @param item
     */
    public isNews( item: TimelineItem ) {
        if ( item.type == 'news' ) return true;
        else return false;
    }
    /**
     * Sets <article.isAnnouncement>
     * @param item
     */
    public isAnnouncement( item: TimelineItem ) {
        if ( item.type == '2' ) return true;
        else return false;
    }

    /**
     * Sets <article.isBulletin>
     * @param item
     */
    public isBulletin( item: TimelineItem ) {
        if ( item.type == '1' ) return true;
        else return false;
    }

    /**
     * Returns Preference if the user has opted out of the given category.
     * False if the user has NOT.
     * @param c
     */
    public hasPreference( c: Category ): any {
        let ret: any = false;
        if ( this.userPrefs.length > 0 ) {
            this.userPrefs.forEach( (p:TimelinePreference) => {
                if ( c.source == "annc" && c.id == p.categoryId && p.preferenceType == 'OPTOUT' ) {
                    ret = p;
                }
                if ( c.source == "news" &&  c.id == p.categoryId && p.preferenceType == 'OPTOUT' ) ret = p;
            } );
        }
        return ret;
    }

    /**
     * Toggles the collapsed state of an announcement in the timline.
     * @param item
     */
    public toggleCollapsed( item: any ) {
        let me = this;
        if ( item.hasOwnProperty('collapsed') ) {
            if ( item.collapsed )
                me.setAnnouncementLinks( "content_"+item.id );
            item.collapsed = !item.collapsed;
            if ( !item.collapsed && item.type != 'news' ) {
                me.metricService.sendAnnouncementView( item.id );
            }
        }
    }

    /**
     * If there are more items to show, show the load more button
     */
    public get showLoadMore(): boolean {
        if ( !this.isLoading && !this.hasFailed && ( this.timelineView.length > 0 || this.showNoEventsToday ) )
            return true;
        else return false;
    }

    /**
     * If load more has been clicked and the current page is > 1, show the previous button to go back.
     */
    public get showLoadPrevious(): boolean {
        if ( !this.isLoading && !this.hasFailed && this.currentPage > 0 )
            return true;
        else return false;
    }

    /**
     * Returns the list of application categories.
     * The user is NOT allowed to opt out of these.
     */
    public get applicationCategories(): Array<string> {
        return this.appConfig.getApplicationCategories();
    }

    /**
     * Returns if the given category is an application category.
     * @param catCode
     */
    public isApplicationCategory( catCode: string ): boolean {
        return ( !!this.appConfig.getApplicationCategories().includes(catCode) );
    }

    /**
     * Main function to laod timeline data and run initialization functions.
     * - Load data from API
     * - Save user Preferences
     * - Format Announcement Data
     * - Format News Data
     * - Process the feeds into single timeline feed
     * - Initialize categories from API
     * @private
     */
    private getData() {
        let me = this;
        let today = new Date();
        let start = today; //sub(today,{ days: 1 });
        let end = add(today,{ days: 1 });

        me.currentDate = start;
        me.isLoading = true;
        performance.clearMarks("timelineStart");
        performance.clearMarks("timelineComplete");
        performance.clearMeasures("timelineMeasure");

        performance.mark('timelineStart');
        this.apiService.getTimelineData( format(start, "yyyy-MM-dd"), format(end, "yyyy-MM-dd") ).subscribe( {
            next: (resp:TimelineData) => {
                // console.log( resp );
                me.timelineList = [];
                me.newsList = [];
                me.anncList = [];
                me.timelineView = [];
                me.categoryList = [];
                me.userPrefs = [];
                me.userPrefs = resp.userPrefs;
                if ( resp.announcements.status == 'OK' ) {
                    if ( resp.date ) me.currentDate = add( new Date(resp.date), { days: 1} );
                    me.formatAnnouncements(resp.announcements.data);
                } if ( resp.news.status == 'OK' ) {
                    me.formatNews(resp.news);
                }
                me.isLoading = false;
                me.loadTimelineView();
                me.initCategories( resp );

                performance.mark('timelineComplete');
                performance.measure('timelineMeasure', 'timelineStart', 'timelineComplete');
                me.metricService.logMetric( { name: "timelineMeasure Load Time (ms)", value: performance.getEntriesByName("timelineMeasure")[0].duration } );
                // console.log( "timelineMeasure Load Time (ms): " + performance.getEntriesByName("timelineMeasure")[0].duration  );
            },
            error: error => {
                me.isLoading = false;
                me.hasFailed = true;
                me.metricService.sendError("TIMELINE FAILED ("+me.userService.getNsid()+") - " + error.status + " - " + error.error.message );
            }
        } );
    }

    /**
     * Takes the raw news feed from API and formats the data into TimelineItem models and puts them into timelineList.
     * @param newsData
     * @private
     */
    private formatNews( newsData:NewsData ) {
        let me = this;
        newsData.data.forEach( (newsFeed:NewsFeed) => {
            let c: Category = { code: newsFeed.feed.title , name: newsFeed.feed.title, id: newsFeed.id, source: "news" };
            let catPref = me.hasPreference( c );
            if ( catPref === false ) c.optout = false; else c.optout = true;
            me.categoryList.push( c );
            if ( newsFeed.feed.items && newsFeed.feed.items.length > 0 ) {
                newsFeed.feed.items.forEach((newsItem: NewsItem) => {
                    let today = new Date();
                    let pubDate = new Date(newsItem.pubDate);
                    let displayDate = format(pubDate, "MMM d, h:mmaaa");
                    if (format(today, "P") == format(pubDate, "P")) displayDate = "Today, " + format(pubDate, "h:mmaaa");
                    newsItem.pubDate = displayDate;
                    newsItem.enclosure = (newsItem.enclosure ? newsItem.enclosure : "");
                    let i: TimelineItem = {
                        id: null,
                        title: newsItem.title,
                        type: 'news',
                        pubDate: displayDate,
                        description: newsItem.description,
                        link: newsItem.link,
                        category: newsFeed.feed.title,
                        thumb: (newsItem.enclosure ? newsItem.enclosure : ""),
                        timestamp: pubDate.getTime()
                    }
                    me.newsList.push(newsItem);
                    me.timelineList.push(i);
                });
            }
        } );
    }

    /**
     * Takes the raw announcement feed from API and formats the data into TimelineItem models and puts them into timelineList.
     * @param data
     * @private
     */
    private formatAnnouncements( data:Array<Announcement> ) {
        let me = this;
        data.forEach( (annc:Announcement) => {
            let today = new Date();
            let pubDate = new Date( annc.startDateTime );
            let displayDate = format( pubDate, "MMM d, h:mmaaa" );
            if ( format(today,"P") == format(pubDate,"P") ) displayDate = "Today, " + format( pubDate, "h:mmaaa");
            let item: TimelineItem = {
                collapsed: true,
                title: annc.title,
                category: annc.categoryName,
                type: String(annc.type),
                link: '',
                thumb: ( annc.attachment.length > 0 ? annc.attachment[0].url : "" ),
                pubDate: displayDate,
                timestamp: pubDate.getTime(),
                body: annc.body,
                categoryCode: annc.categoryCode,
                actionLabel: annc.actionLabel,
                actionTarget: annc.actionTarget,
                actionUrl: annc.actionUrl,
                attachment: annc.bodyAttachment,
                // attachments: annc.attachments,
                // contact_info: annc.contact_info,
                dismissLabel: annc.dismissLabel,
                // email_addr: annc.email_addr,
                // email_label: annc.email_label,
                // group_title: annc.group_title,
                // group_type: annc.group_type,
                id: annc.id,
                isSticky: annc.isStickyMessage,
                isAlert: ( annc.categoryCode == 'EMRG' ? true : false ),
                sentBy: annc.sentBy,
                sentTo: annc.sentTo,
            }
            // Filter out non-accepted images.
            if ( !me.isAcceptedImage( item.thumb ) ) {
                item.thumb = '';
            }
            // Parse content for images. Show first occurance.
            if ( item.thumb == '' ) {
                let myRegex = /<img.*?src="(.*?)"/g;
                let results = myRegex.exec(item.body);
                if ( results != null && results.length >= 2 ) {
                    item.thumb = encodeURIComponent( results[1] );
                    item.thumb = item.thumb.replace(/%3A%2F%2F/g,"://").replace(/%2F/g,"/");
                    if ( !me.isAcceptedImage( item.thumb) ) {
                        item.thumb = '';
                    }
                }
            }
            // Remove height and width from img tags
            let myRegex = /(height|width)="(.*?)"/g;
            item.body = item.body.replace( myRegex, "" );

            me.anncList.push(annc);
            if ( item.isAlert ) {
                me.alertList.push( item );
            } else if ( annc.isStickyMessage ) {
                me.stickyList.push(item);
            } else {
                me.timelineList.push(item);
            }
        } );
    }

    private isAcceptedImage( url: string ): boolean {
        if ( url != null && url.length > 0 ) {
            if (url.toLowerCase().substring(url.length - 3) == 'jpg'
                || url.toLowerCase().substring(url.length - 4) == 'jpeg'
                || url.toLowerCase().substring(url.length - 3) == 'png') {
                return true;
            }
        }
        return false;
    }

    /**
     * Sorts timeline List and chucks it into a pageinated timelineView for ui.
     * @private
     */
    private loadTimelineView() {
        let me = this;
        me.timelineView = [];
        this.showNoEventsToday = false;
        this.timelineList = this.timelineList.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return b.timestamp - a.timestamp;
        });
        this.stickyList = this.stickyList.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return b.timestamp - a.timestamp;
        });
        this.alertList = this.alertList.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return b.timestamp - a.timestamp;
        });
        let targetDate = format(me.currentDate, "yyyy-MM-dd");
        this.timelineList.forEach( (el:TimelineItem,i:number) => {
            let elDate = new Date(el.timestamp);
            // console.log( el.type + ' - ' + el.title + " - " + format(elDate, "yyyy-MM-dd") + " - " + targetDate );
            if ( format(elDate, "yyyy-MM-dd") >= targetDate || ( "isSticky" in el && el.isSticky == 1 ) ) {
                me.timelineView.push(el);
            }
        } );

        this.alertList.forEach( (el:TimelineItem) => {
            el.collapsed = false;
        });
        this.stickyList.forEach( (el:TimelineItem,i:number) => {
            if ( i > 0 ) el.collapsed = true;
            else el.collapsed = false;
        });
        this.timelineView = this.alertList.concat( this.stickyList.concat(this.timelineView) );
        // this.timelineView = this.timelineList.slice(this.currentPage, this.currentPageLength);
        // console.log( this.timelineView );
        if ( this.timelineView.length == 0 ) {
            this.showNoEventsToday = true;
        }
    }

    /**
     * Updates timelineView object with next set of items for UI.
     * - Option to show as pages
     * - Option ( Commented out ) to show from start and append next set.
     */
    public loadMore() {

        let me = this;
        let start = sub(this.currentDate, {days: 1});
        let end = this.currentDate;
        this.isLoading = true;
        // this.showNoEventsToday = false;

        me.currentDate = start;
        performance.clearMarks("timelineLoadMoreStart");
        performance.clearMarks("timelineLoadMoreComplete");
        performance.clearMeasures("timelineLoadMoreMeasure");

        performance.mark('timelineLoadMoreStart');
        this.apiService.getAnnouncementData(format(start, "yyyy-MM-dd"),format(end, "yyyy-MM-dd")).subscribe({
            next: (resp:AnnouncementResponse) => {
                if ( resp.announcements.status == 'OK' ){
                    let fixDate:Date = new Date(resp.date);
                    fixDate = new Date(fixDate.getTime() + fixDate.getTimezoneOffset() * 60000);
                    this.currentDate = new Date(fixDate);
                    me.formatAnnouncements( resp.announcements.data );
                }

                me.loadTimelineView();
                this.isLoading = false;

                this.showSuccess = true;
                if(this.loadMoreCurrentTimeout){
                    // clearTimeout(this.loadMoreCurrentTimeout);
                    this.loadMoreCurrentTimeout = null;
                }

                performance.mark('timelineLoadMoreComplete');
                performance.measure('timelineLoadMoreMeasure', 'timelineLoadMoreStart', 'timelineLoadMoreComplete');
                // console.log( "timelineLoadMoreMeasure Load Time (ms): " + performance.getEntriesByName("timelineLoadMoreMeasure")[0].duration  );
            },
            error: error => {
                if ( error.status == 401 ) {
                    me.router.navigate(["/"]);
                }
                me.metricService.sendError("ANNOUNCEMENTS FAILED ("+me.userService.getNsid()+") - " + error.status + " - " + error.error.message );
            }
        });
        // this.currentPageLength = this.currentPageLength + this.pageLimit;
        // this.currentPage = this.currentPage + this.pageLimit;
        // // this.timelineView = this.timelineList.slice(this.currentPage, this.currentPageLength);
        // this.findStickies();
        // this.window.scrollTo(0, 0);
    }

    /**
     * Reverse of LoadMore()
     */
    public loadPrevious() {
        this.currentPageLength = this.currentPageLength - this.pageLimit;
        this.currentPage = this.currentPage - this.pageLimit;
        // this.timelineView = this.timelineList.slice(this.currentPage, this.currentPageLength);
        this.findStickies();
    }

    /**
     * Parse through timelineList and pull all stickies to the top,
     * @private
     */
    private findStickies() {
        let stickies: Array<any> = [];
        let nonStickies: Array<any> = [];
        this.timelineView.forEach( (el:TimelineItem,index:number) => {
            if ( "isSticky" in el && el.isSticky == 1 ) {
                if ( stickies.length == 0 ) el.collapsed = false;
                stickies.push(el);
            } else {
                nonStickies.push(el);
            }
        } );
        this.timelineView = stickies.concat(nonStickies);
    }

    /**
     * Initialize Categories from announcements feed and sort lexically.
     * @param data
     * @private
     */
    private initCategories( data: TimelineData ) {
        let me = this;
        if ( data.categories && data.categories.length > 0 ) {
            data.categories.forEach( (c:Category) => {
                c.source = "annc";
                c.optout = false;
                let pref = me.hasPreference(c);

                if ( pref !== false ) {
                    c.unquieCategoryId = pref.id;
                    c.optout = true;
                }
                me.categoryList.push( c );
            } )
        }
        this.categoryList.sort( function(a,b){ return ( b.name < a.name ? 1 : -1 ) });
    }

    public get userCategoryList(): Array<Category> {
        if ( this.categoryList.length > 0 ) {
            let visibleCategories: Array<Category> = [];
            this.categoryList.forEach( (c:Category) => {
                if ( !this.isApplicationCategory(c.code) ) {
                    visibleCategories.push( c );
                }
            } );
            return visibleCategories;
        } else {
            return [];
        }
    }

    /**
     * Remove indexed timlineItem from view.
     * @param index
     */
    public removeMe( index: number ) {
        this.timelineView.splice(index,1);
    }

    /**
     * Toggle optin/out on checkbox click.
     * @param cat
     */
    public togglePreference( cat: Category ) {
        cat.optout = !cat.optout;
    }

    /**
     * Accepts sticky and opens link in a new tab if there is one.
     * @param item The sticky item.
     */
    public doStickyAction( item: TimelineItem ) {
        let me = this;
        let announcementId = item.id;
        if ( item.actionUrl != '' )
            window.open( item.actionUrl );
        me.apiService.markAsDeletedByUserIdAndAnnouncementId(me.acceptAction,announcementId).subscribe(() => {});
        this.cache.clearCacheByName("api_feeds");
        me.rotateStickies();

    }

    public setAnnouncementLinks( id: string ) {
        document.querySelectorAll("#"+id+" a").forEach( (a:Element) => {
            if ( a.getAttribute("href") != null && !a.getAttribute("href").includes("//paws") )
                a.setAttribute("target","_blank");
        } );
    }

    /**
     * Dismisses sticky and removes it from the announcements.
     * @param item The sticky item.
     */
    public doStickyDissmiss( item: TimelineItem ) {
        let me = this;
        let announcementId = item.id;
        me.apiService.markAsDeletedByUserIdAndAnnouncementId(me.dismissAction, announcementId).subscribe(() => {});
        this.cache.clearCacheByName("api_feeds");
        me.rotateStickies();
    }

    public rotateStickies() {
        let firstSticky = null;
        for( let i = 0; i < this.timelineView.length; i++ ) {
            if ( firstSticky == null && this.timelineView[i].isSticky == 1 ) {
                firstSticky = i;
            }
        }
        if ( firstSticky >= 0 ) {
            this.timelineView[firstSticky].collapsed = true;
            this.timelineView.splice(firstSticky, 1);
        }
        if ( this.timelineView.length > 0 && this.timelineView[firstSticky].isSticky )
            this.timelineView[firstSticky].collapsed = false;
        if ( this.timelineView.length == 0 ) {
            this.showNoEventsToday = true;
        }
    }

    /**
     * Save preferences to API
     */
    public savePreferences() {
        let me = this;
        let params: Array<any> = [];
        this.prefLoading = true;
        this.prefSuccess = false;
        this.prefFailed = false;
        this.categoryList.forEach( (cat) => {
            if (cat.optout) {
                params.push({
                    categoryId: cat.id,
                    code: cat.code,
                    unquieCategoryId: (cat.unquieCategoryId != undefined ? cat.unquieCategoryId : 0),
                    source: cat.source
                });
            } //else if (cat.categoryId != undefined) params.push({categoryId: cat.id, unquieCategoryId: 0, source: cat.source});
        } );
        this.apiService.saveTimelinePreferences( params ).subscribe({
            next: (resp) => {
                //console.log(resp);
                me.prefLoading = false;
                me.prefSuccess = true;
            }, error: ( err: any ) => {
                me.prefLoading = false;
                me.prefFailed = true;
            }, complete: () => {
                setTimeout( () => {
                    //console.log( 'closing ' );
                    me.prefLoading = false;
                    me.prefSuccess = false;
                    me.prefFailed = false;
                    me.getData();
                    if ( document.getElementById("announcementSettingsToggle").getAttribute("aria-expanded") == 'true' )
                        document.getElementById("announcementSettingsToggle").click();
                } , this.appConfig.getNotificationTimeoutInMiliSeconds());
            }
        });
    }

    /**
     * Set of functionality to support reporting announcement abuse.
     */

    /**
     * Displays UI Sending message
     * @type boolean
     */
    public reportAbuseSending: boolean = false;
    /**
     * Displays UI Success Message
     * @type boolean
     */
    public reportAbuseSuccess: boolean = false;
    /**
     * Displays UI Error message
     * @type boolean
     */
    public reportAbuseError: boolean = false;

    /**
     * Used to synchronise message blocks.
     * @param status
     */
    public setStatus( status: string ) {
        this.reportAbuseSending = false;
        this.reportAbuseSuccess = false;
        this.reportAbuseError = false;
        if ( status == 'error' ) {
            this.reportAbuseError = true;
        }
        if ( status == 'success' ) {
            this.reportAbuseSuccess = true;
        }
        if ( status == 'sending' ) {
            this.reportAbuseSending = true;
        }
    }

    /**
     * Local variable to store Form Control and Validation ( Angular Reactive )
     */
    reportAnnouncementForm = this.formBuilder.group({
        id: [0, [Validators.required,Validators.min(1)]],
        reason: ['', [Validators.required,Validators.pattern(/(Incorrect|Inappropriate)/g ) ]],
        comment: ['', [Validators.required]]
    });

    /**
     * Getter for announcement ID
     */
    get showReportFormId() {
        return this.reportAnnouncementForm.get("id").value;
    }

    /**
     * Set announcement ID field to 0.
     * Clears the form.
     */
    public cancelReportForm() {
        this.reportAnnouncementForm.get("id").setValue(0);
    }

    /**
     * Function to add futher validation.
     */
    public validateReportForm(): boolean {
        let ret = true;
        if ( this.reportAnnouncementForm.get("id").value <= 0 ) ret = false;
        if ( this.reportAnnouncementForm.get("reason").value == "" ) ret = false;
        if ( this.reportAnnouncementForm.get("comment").value == "" ) ret = false;
        return ret;
    }

    /**
     * Will show the form for a given announcement ID within the announcement block in the UI
     * @param id
     */
    public showReportForm( id: number ) {
        this.reportAnnouncementForm.reset();
        this.reportAnnouncementForm.enable();
        this.setStatus("");
        this.reportAnnouncementForm.get("id").setValue(id);
    }

    /**
     * Sends the form data to PORTAL API
     */
    public sendReport() {
        let me = this;
        if ( this.reportAnnouncementForm.status == "VALID" ) {
            this.setStatus("sending");
            me.apiService.sendReportAnnouncement(
                me.reportAnnouncementForm.get("reason").value,
                me.reportAnnouncementForm.get("comment").value,
                me.userService.getNsid(),
                me.reportAnnouncementForm.get("id").value
            ).subscribe( {
                next: ( resp : any ) => {
                    me.setStatus("success");
                    me.reportAnnouncementForm.disable();
                    setTimeout( () => { me.showReportForm(0); }, 5000 );
                }, error: ( error: any ) => {
                    me.setStatus("error");
                }
            } );
        }
    }
}
