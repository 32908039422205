<div class="pt-3">
    <header class="mx-0 mb-2 d-flex">
        <button class="btn btn-link" aria-label="Previous month" (click)="setMonth(-1)"
                (mouseenter)="caretLeftHover=true" (mouseleave)="caretLeftHover=false" data-cy="btn_calendar_prevMonth">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-caret-left-fill" viewBox="0 0 16 16" *ngIf="caretLeftHover">
                <path
                    d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left"
                 viewBox="0 0 16 16" *ngIf="!caretLeftHover">
                <path
                    d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z"/>
            </svg>
        </button>
        <div class="p-2 w-100 text-center" data-cy="calendar_monthTitle"><strong>{{ title }}</strong></div>
        <button class=" me-auto btn btn-link" aria-label="Next month" (click)="setMonth(+1)"
                (mouseenter)="caretRightHover=true" (mouseleave)="caretRightHover=false"
                data-cy="btn_calendar_nexMonth">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-caret-right-fill" viewBox="0 0 16 16" *ngIf="caretRightHover">
                <path
                    d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right"
                 viewBox="0 0 16 16" *ngIf="!caretRightHover">
                <path
                    d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
            </svg>
        </button>
        <div class="portletsettings calendarsettings px-1">
            <div class="dropdown">
                <button id="calendarsettings-label" class="btn btn-sm btn-light dropdown-toggle my-1"
                        data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-label="Calendar Settings"
                        aria-expanded="false">
                    <svg
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-calendar2-week" viewBox="0 0 16 16">
                        <path
                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                        <path
                            d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                    </svg>
                    <span class="caret"></span>
                </button>
                <div class="dropdown-menu pull-right px-4 calendarsettings-list" id="calendarPreferencesDropdown"
                     data-cy="calendar-preferences-dropdown" style="">
                    <ul class="list-inline" role="menu" aria-labelledby="calendarsettings-label">
                        <li class="dropdown-header ps-0">
                            <div class="h6 text-secondary text-uppercase">Event Categories</div>
                        </li>
                        <li class="list-inline-item w-50 p-0 m-0" *ngFor="let cat of calendarCategories">
                            <label>
                                <input
                                    [ngStyle]="{ 'background-color':( cat.optout ? null : cat.color ), 'border-color':'#ccc' }"
                                    type="checkbox" class="form-check-input" name="{{ cat.id }}" id="{{ cat.id }}"
                                    [attr.checked]="( cat.optout ? null : 'checked' )" (click)="togglePreference(cat)">
                                <i class="fa fa-check" aria-hidden="true"></i> {{ cat.name }}
                            </label>
                        </li>
                        <li class="dropdown-menu-footer mt-3">
                            <div class="row">
                                <div class="col-sm-10">
                                    <div class="alert alert-secondary d-inline-block p-2" role="alert" *ngIf="prefLoading">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                             class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                            <path
                                                d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                        </svg>
                                        Your preferences are being saved .... Please wait.
                                    </div>
                                    <div class="alert alert-success d-inline-block p-2" role="alert" *ngIf="prefSuccess">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                             class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                                        </svg>
                                        Your preferences have been successfully updated !
                                    </div>
                                    <div class="alert alert-danger d-inline-block p-2" role="alert" *ngIf="prefFailed">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                             class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
                                            <path
                                                d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                                            <path
                                                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                        </svg>
                                        Sorry. There was a problem saving your preferences. Please try again or contact IT
                                        Support.
                                    </div>
                                    <div class="alert alert-info d-inline-block p-2" role="alert" *ngIf="prefTryAgain">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                             class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
                                            <path
                                                d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                                            <path
                                                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                        </svg>
                                        Sorry. Your access expired. We've renewed it. Please click Save again.
                                    </div>
                                </div>
                                <!-- <div class="col-sm-2 text-end"> -->
                                <div class="col-md-2 text-end">

                                    <button class="btn btn-primary" (click)="savePreferences()">Save</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </header>
    <div id="calendar_container" data-cy="calendar_container">
        <table class="tblMonth table paws-table-xs" data-cy="calendar-table">
            <tbody>
            <tr align="center" valign="center">
                <th scope="col" *ngFor="let days_label of days_labels">{{days_label}}</th>
            </tr>
            <tr align="center" *ngFor="let week of calendarList">
                <td *ngFor="let day of week" [ngClass]="{'selected': day.is_selected, 'today': day.is_today}"
                    title="{{day.title}}">
                    <button (click)="loadEventOnClick(day.date_stamp)"
                            class="btn btn-sm btn-link text-decoration-none {{day.date_stamp}}"
                            [ngClass]="{'disabled': !day.in_month, 'event-day': day.has_event}">{{day.calendar_day}}</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <hr/>
    <div *ngIf="!isLoaded" class="backgroundMask text-center" id="loading-wrapper">
        <div class="h5 text-muted">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
<!--            <div class="spinner-border ms-2" role="status"><span class="visually-hidden">Loading...</span></div>-->
            <p class="small">Finding your events .... Please wait.</p>
        </div>
    </div>

    <div class="mx-2" *ngFor="let c of countDownEvents" data-cy="countdownEvent_container">
        <p>
            <a href="{{ c.link }}" target="_blank" [ngClass]="{'text-warning': isOneDayBeforeCountDownEvent(c.title),'text-danger': isTodayCountDownEvent(c.title)}" (click)="sendGACountdownEvent(c.title)">
                <strong [innerHTML]="c.title"></strong>
            </a>
        </p>
    </div>

    <div class="list-group mx-2" id="calendar_event_list" data-cy="calendarEventList_container">
        <div class="list-group-item px-0">
            <h2 class="event_date_title m-0">{{ displayTargetDate() }}</h2>
        </div>
        <div class="list-group-item px-0" *ngIf="!hasEvents()">No events.</div>
        <div class="list-group-item px-0" *ngFor="let evt of getEvents(); let i = index" data-cy="eventItem_container">
            <ng-container *ngTemplateOutlet="eventTemplate;context:{evt:evt, i:i}"></ng-container>
        </div>
        <div *ngIf="isToday()" data-cy="event-tomorrow">
            <div class="list-group-item px-0" data-cy="event-tomorrow-display">
                <h2 class="event_date_title m-0">{{ displayTomorrow() }}</h2>
            </div>
            <div class="list-group-item px-0" data-cy="event-tomorrow-no-events" *ngIf="!hasTomorrowEvents()">No
                events.
            </div>
            <div class="list-group-item px-0" *ngFor="let evt of getTomorrowEvents(); let i = index"
                 data-cy="eventItem-tomorrow_container">
                <ng-container *ngTemplateOutlet="eventTemplate;context:{evt:evt, i:i}"></ng-container>
            </div>
        </div>
    </div>
</div>
<ng-template #eventTemplate let-evt="evt" let-i="i">
    <div class="event-summary-wrapper" data-cy="event-summary-wrapper" [class.p-3]="!evt.collapsed"
         [class.collapsed]="evt.collapsed" id="event_{{ i }}" [ngStyle]="{ 'border-left-color': evt.color }"
         [attr.data-bs-parent]="'#calendar_event_list'">
        <button type="button" class="btn-close" data-cy="event-close" (click)="toggleCollapsed(evt)"></button>
        <ul class="event-details-meta">
            <li class="event-details-category" *ngIf="evt.source != ''">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-event" viewBox="0 0 16 16" style="color:#5C7A00;">
                    <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                    <path
                        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                </svg>
                {{ evt.source }}
            </li>
            <li class="calendar-event-title" data-cy="event-details-title">
                <button role="button" data-cy="event-expand" class="text-wrap text-start" data-bs-toggle="collapse"
                        (click)="toggleCollapsed(evt)" tabindex="0" title="{{ evt.source }}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-square-fill me-2" viewBox="0 0 16 16" style="color:{{ evt.color }};">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"/>
                    </svg>
                    <time class="calendar-event-time d-inline-block me-2"
                          *ngIf="evt.startTime!=''">{{ evt.startTime }}</time>
                    {{ evt.summary }}
                </button>
            </li>
            <li class="event-details-time" *ngIf="evt.startTime!=''">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock"
                     viewBox="0 0 16 16">
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                </svg>
                {{ evt.startTime }}
            </li>
            <li class="event-details-url mt-1" *ngIf="evt.url!=''">
                <a href="{{ evt.url }}" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                    Website
                </a>
            </li>
        </ul>
        <div class="event-details-description" data-cy="event-details-description"
             [innerHTML]="evt.description | formatHtml"></div>
    </div>
</ng-template>
