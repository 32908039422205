import {Component, OnInit} from '@angular/core';
import {ChannelsService} from "../../services/channels.service";
import {CustomChannelWithFavorites} from "../../model/customChannelWithFavorites";
import {MetricsService} from "../../services/metrics.service";

@Component({
  selector: 'app-recommender',
  templateUrl: './recommender.component.html',
  styleUrls: ['./recommender.component.scss']
})
export class RecommenderComponent implements OnInit {

    public recommendedChannels: Array<CustomChannelWithFavorites> = [];

    constructor(
        private channelService: ChannelsService,
        private metricsService: MetricsService
    ) { }

    ngOnInit(): void {
        let me = this;
        this.recommendedChannels = this.channelService.getRecommendedChannels();
        this.channelService.channelsReady$.subscribe( {
            next: (channels) => {
                me.recommendedChannels = me.channelService.getRecommendedChannels();
            }
        } );

    }
    public sendGAClickEvent( title: string ) {
        if ( title != '' ) {
            this.metricsService.sendRecommenderClick( title );
        }
    }

}
