
<!--<div class="uofs-page layout-homepage">-->
<!--    <div class="is-flex">-->
<!--        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-0" [class.hidden]="!isLoggedIn()">-->
<!--            <div class="uofs-offcanvas">-->
<!--                <app-navigation></app-navigation>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="col-lg-10 col-md-9 col-sm-9 col-xs-12 layout-content">-->
<!--            <div id="content" class="uofs-page-content">-->
<main id="content" tabindex="-1" class="row m-0">

<div class="bg-paws-header border-bottom content-header">
    <h1 class="d-inline-flex pe-3 mb-0">{{ channeltitle }}</h1>
    <div class="portlet-options d-inline-block align-top mt-1" *ngIf="showIframe">
       <!-- <a class="btn btn-sm btn-default portlet-add" title="Add to Favourites" href="#" style="display: none;"><i class="fa fa-star text-muted"></i> Add to Favourites</a>
        <a class="btn btn-sm btn-warning portlet-remove" title="Remove from Favourites" href="#" style="display: none;"><i class="fa fa-star"></i> <span>Remove from </span>Favourite<span>s</span></a>
        -->
        <button *ngIf="!isChannelFavorite && !isIcon && !isMyCollege" class="btn btn-outline-secondary mb-1" title="Add to Favourites" (click)="addFavorite()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
            </svg>
            <span class="d-none d-sm-inline-block">&nbsp;Add to Favourites</span></button>
        <button *ngIf="isChannelFavorite && !isIcon && !isMyCollege" class="btn btn-outline-primary mb-1" title="Remove from Favourites" (click)="deleteFavorite()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
            </svg>
            <span class="d-none d-sm-inline-block">&nbsp;Remove from Favourites</span>
        </button>
    </div>
</div>
<div class="bg-white content-body">
    <div *ngIf="getShowBackBtn" class="backWrapper" id="backWrapper">
        <button class="btn btn-primary" [routerLink]="['/',currentChannel.goname]">BACK TO CHANNEL</button>
    </div>
    <div *ngIf="subpage && currentChannel != null && subPageTitle != null" class="mb-4">
        <a [routerLink]="['/',currentChannel.goname]" aria-label="back to channel homepage">{{ channeltitle }}</a> > {{ subPageTitle }}
    </div>

    <div *ngIf="state=='not_found'" class="backgroundMask text-center">
        <div class="alert alert-danger" role="alert">
            <div class="h2">Not Found</div>
            <div class="mtlg h5">This can happen if the URL is not correct. Check the <a [routerLink]="['/search']">Apps & Services</a> page.</div>
            <div class="mtlg h5">This could also be because you do not have permission to access this channel. You can contact <a href='{{ getHelpLink() }}'>IT Support</a> for help.</div>
        </div>
    </div>
    <div *ngIf="state=='ineligible'" class="backgroundMask text-center">
        <div class="alert alert-info" role="alert">
            <div class="h2">Access Denied</div>
            <div class="mtlg h5">You do not currently have permission to see this channel. </div>
            <div class="mtlg h5">If you think you should have access, you can contact <a href='{{ getHelpLink() }}'>IT Support</a> for help. Please include the channel title above.</div>
<!--            <p><strong>Eligible Users: </strong> {{ currentChannel.eligibility }}</p>-->
        </div>
    </div>
    <div *ngIf="state=='loading_error'" class="backgroundMask text-center">
        <div class="alert alert-danger" role="alert">
            <div class="h2">Error</div>
            <div class="mtlg h5">There was a problem loading this channel. </div>
            <div class="mtlg h5">If you followed a link to this page, you can look for the channel in the <a [routerLink]="['/search']">Apps & Services</a> page.</div>
            <div class="mtlg h5">Otherwise, this channel could be experiencing technical issues. Please contact <a href='{{ getHelpLink() }}'>IT Support</a> for help.</div>
        </div>
    </div>
    <div *ngIf="state=='loading'" class="backgroundMask text-center" id="loading-wrapper">
        <div class="h5 text-muted">
            <div class="spinner-border ms-2" role="status"><span class="visually-hidden">Loading...</span></div>
        </div>
    </div>
    <iframe
        class="{{ state }}"
        [src]="channelURL | safeUrl"
        (load)="iframeLoaded()"
        (error)="iframeError()"
        width="100%"
        border="0"
        scrolling="auto"
        bordercolor="#000000"
        frameborder="0"
        height="75vh"
        id="iframeChannel"
        name="iframeChannel"
    ></iframe>
</div>
</main>
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
