import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, convertToParamMap, Params, Router, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {UserService} from './user.service';
import {MetricsService} from "./metrics.service";

/**
 * ### AuthGuard Service
 */
@Injectable({
    providedIn: 'root'
})
export class AuthGuardService  {
    /**
     * Service constructor.
     * @param router
     * @param userService
     * @param metricsService
     */
    constructor(
        private router: Router,
        private userService: UserService,
        private metricsService: MetricsService
    ) {
        this.userService = userService;
    }

    /**
     * Main Function called from Routing modules.
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {boolean}
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let me = this;
        // console.log("AUTH GUARD: ", route);
        // console.log( state );
        let queryParams: Params = {};
        if ( route.params['id'] != undefined ) queryParams["channel"] = route.params['id'] + (route.params['sub'] ? "/" + route.params['sub'] : "");
        let queryMap = convertToParamMap(route.queryParams);
        if ( queryMap.keys.length > 0 ) {
            queryMap.keys.forEach( (k) => {
                if ( k != 'channel' && k != 'code' )
                    queryParams[k] = queryMap.get(k);
            } );
        }
        if ( !queryParams['channel'] ) queryParams['channel'] = state.url;
        // console.log("queryParams:",queryParams);

        // console.log( "AUTH GUARD: isLoggedIn: " + this.userService.hasUserToken() + " - " + this.userService.hasUserToken() );
        if (!this.userService.isLoggedIn()) {
            me.metricsService.sendError("AUTH GUARD: isLoggedIn FAILED" );
            this.router.navigate(["/login"], {queryParams: queryParams});
            return false;
        }
        // console.log( "AUTH GUARD: hasAccessToken: " + this.userService.hasAccessToken() );
        if (!this.userService.hasAccessToken()) {
            me.metricsService.sendError("AUTH GUARD: hasAccessToken FAILED ("+me.userService.getNsid()+")" );
            this.router.navigate(["/login"], {queryParams: queryParams});
            return false;
        }
        // console.log( "AUTH GUARD: isTokenExpired: " + this.userService.isTokenExpired() );
        if ( this.userService.isTokenExpired() ) {
            me.metricsService.sendError("AUTH GUARD: isTokenExpired  FAILED ("+me.userService.getNsid()+")" );
            // console.log("TOKEN EXPIRED !! ");
            this.router.navigate(["/login"], {queryParams: queryParams});
            return false;
        }
        return true;
    }

    /**
     * Routes the same call but from child routes.
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {boolean}
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.canActivate(route, state);
    }
}
