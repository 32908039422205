import {Injectable} from '@angular/core';
import {User} from '../model/user';
import {Observable, Subject} from "rxjs";
import {AccessToken} from "../model/accessToken";
import {CookieService} from "./cookie.service";
import {UserPreference} from '../model/userPreference';
import {ApiRequestService} from "./api-request.service";
import {PawsCookie} from "../model/pawsCookie";
import {CustomChannelWithFavorites} from "../model/customChannelWithFavorites";
import {AppConfigService} from "../app-config.service";

/**
 * Singleton Service
 * Manages User and Token cookies
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
    /**
     * A Session Storage object to hold the user info.
     * @type {Storage}
     */
    // private storage:Storage = sessionStorage; // sessionStorage or LocalStrage

    private pawsCookie: PawsCookie;

    /**
     * Cookie name for storing User Information
     * Stringified src/app/interfaces/User.ts
     * @type string
     * @private
     */
    private adminRole: string = "paws_mgt_login";
    private jwtCookieName: string = "paws_access";
    private userCookieName: string = 'paws_user';
    private accessTokenCookieName: string = "paws_token";
    private recentlyViewedCookieName: string = "paws_recently_viewed";
    private pawsInfoCookieName: string = "usask_paws";

    private userCookieLifetime: number = this.appConfig.getRefreshTokenTTLInMinutes(); // in minutes;
    // private accessTokenCookieLifetime: number = 120; // in minutes;
    // private recentlyViewedTokenCookieLifetime: number = 120; // in minutes;

    /**
     * Private user Observable/Worker
     * @type Subject
     * @private
     */
    private _userOut: Subject<any> = new Subject();
    /**
     * UserReady Observable. Used for outside watchers to subscribe to.
     * @type {Observable<any>}
     */
    public userReady$: Observable<any> = this._userOut.asObservable();
    /**
     * UserReady emitter function. Used internally to notify watchers..
     * @param user
     */
    private userReady( user:User ) {
        // console.log( '2: ' + user );
        if ( user != null )
            this._userOut.next( user );
    }

    /**
     * Service Constructor
     * If cookies are present, should load them.
     * @param cookieService
     * @param api
     * @param appConfig
     */
    constructor(
        private cookieService: CookieService,
        private api: ApiRequestService,
        private appConfig: AppConfigService
    ) {
        this.getPawsCookie();
    }

    public storePawsCookie() {
        let cookieParams = { name:this.pawsInfoCookieName, value: JSON.stringify(this.pawsCookie), expireMinutes:this.userCookieLifetime, secure: true, session: true, samesite: "strict" };
        this.cookieService.setCookie( cookieParams );
    }
    public getPawsCookie(): PawsCookie {
        let cookiePayload = this.cookieService.getCookie(this.pawsInfoCookieName);
        if ( cookiePayload != null && cookiePayload != "" ) {
            this.pawsCookie =  JSON.parse( <string>cookiePayload );
            return this.pawsCookie;
        }
        let paws: PawsCookie = {
            user: null,
            token: null,
            recently_viewed: null,
            channels: null
        }
        this.pawsCookie = paws;
        return paws;
    }
    public destroyPawsCookie() {
        this.cookieService.deleteCookie( this.pawsInfoCookieName );
    }
    /**
     * Processes and stores provided User and stores it
     * @param u
     */
    public processAPIUser( u: User ) {
        let user: User = {
            id: u.id,
            nsid: u.nsid,
            displayName: u.displayName,
            roles: u.roles
        };
        if ( user.displayName.length > 45 ) {
            user.displayName = user.displayName.substring(0,45);
        }
        // this.storeUser();
        this.pawsCookie.user = user;
        this.storePawsCookie()
        this.userReady( user );
    }

    /**
     * Logout function
     *  Destroys all cookies
     */
    public logout() {
        this.destroyUserInfo();
        this.destroyTokenInfo();
        this.destroyPawsCookie();
        this.pawsCookie = null;
        this.userReady( null );
    }

    public destroyAccessToken() {
        // this.cookieService.deleteCookie( "paws_access" );
        if( this.pawsCookie != null ) {
            this.pawsCookie.token = null;
            this.storePawsCookie();
        }
    }

    /**
     * Store the current user to its cookie
     */
    public storeToken( token: AccessToken ) {
        let now = new Date().getTime();
        let tokenLifetime = token.expires_in * 1000;
        token.expires_at = now + tokenLifetime;
        token.iat = now;
        // console.log( "NOW: " + now );
        // console.log( "Expires AT: " + token.expires_at );

        // let cookieParams = {name:this.accessTokenCookieName, value: JSON.stringify(token) , expireMinutes: this.accessTokenCookieLifetime };
        // this.cookieService.setCookie( cookieParams );
        this.pawsCookie.token = token;
        this.storePawsCookie();
        // this.storage.setItem( this.accessTokenCookieName, JSON.stringify(token) );
    }

    public getToken() {
        // return JSON.parse( <string>this.cookieService.getCookie(this.accessTokenCookieName) );
        return this.pawsCookie.token;
    }
    /**
     * Store the current recently viewed to its cookie
     */
    public storeRecentlyViewed( recentlyViewed: Array<number> ) {
        //console.log(recentlyViewed.toString());
        // let cookieParams = {name:this.recentlyViewedCookieName, value: JSON.stringify(recentlyViewed) , expireMinutes:this.recentlyViewedTokenCookieLifetime };
        // this.cookieService.setCookie( cookieParams );
        // this.storage.setItem( this.recentlyViewedCookieName, JSON.stringify(recentlyViewed) );
        if( this.pawsCookie != null ) {
            this.pawsCookie.recently_viewed = recentlyViewed;
            this.storePawsCookie();
        }
    }

    /**
     * Destroy User Cookie
     */
    public destroyUserInfo() {
        // this.storage.removeItem(this.userCookieName);
        this.cookieService.deleteCookie(this.userCookieName);
        if( this.pawsCookie != null ) {
            this.pawsCookie.user = null;
            this.storePawsCookie();
        }
    }
    public destroyTokenInfo() {
        // this.storage.removeItem(this.accessTokenCookieName);
        this.cookieService.deleteCookie(this.accessTokenCookieName);
        if( this.pawsCookie != null ) {
            this.pawsCookie.token = null;
            this.storePawsCookie();
        }
    }

    /**
     * Destroy the recently viewed
     */
     public destroyRecentlyViewed() {
        // this.storage.removeItem( this.recentlyViewedCookieName );
        this.cookieService.deleteCookie(this.recentlyViewedCookieName);
        if( this.pawsCookie != null ) {
            this.pawsCookie.recently_viewed = null;
            this.storePawsCookie();
        }
    }

    /**
     * Retrieve contents of User Cookie and return User object
     * @returns {User|boolean}
     */
    public getUser(): User {
        // if ( this.cookieService.getCookie(this.userCookieName) != '' )
        //     return JSON.parse( <string>this.cookieService.getCookie(this.userCookieName) );
        // else return <User>{};
        // return JSON.parse( <string>this.storage.getItem(this.userCookieName) );
        return this.pawsCookie.user;
    }

    /**
     * Retrieve contents of User Cookie and return if there is an object present
     * @returns {boolean}
     */
    public hasUserToken() {
        // let token = this.storage.getItem( this.userCookieName );
        // if ( token != undefined && token != '' ) return true;
        // else return false;
        // return this.storage.getItem( this.userCookieName )?true:false;
        // let user = this.cookieService.getCookie(this.userCookieName);
        // if ( user == '' ) return false;
        // else return true;
        return (this.pawsCookie != null && this.pawsCookie.user != null) ;
    }

    /**
     * Retrieve contents of Token Cookie and :
     *      - Checks if it is present
     *      - Checks if there is a token
     * @returns {boolean}
     */
    public hasAccessToken() {
        let ret: boolean = true;
        // let token = this.storage.getItem( this.accessTokenCookieName );
        // let token = this.cookieService.getCookie(this.accessTokenCookieName);
        if ( this.pawsCookie == null || this.pawsCookie.token == null ) return false;
        let token = this.pawsCookie.token;
        // let data: AccessToken = JSON.parse( <string>token );
        // if ( data == null ) return false;
        if ( token.access_token == null ) return false;
        if ( token.expires_in == null ) return false;
        if ( token.iat == null ) return false;
        return ret;
        // return this.cookieService.getCookie(this.userCookieName)?true:false;
    }

    /**
     * Retrieve contents of Recently Viewed Cookie and return if there is an object present
     * @returns {boolean}
     */
    public hasRecentlyViewed() {
        // return this.storage.getItem( this.recentlyViewedCookieName )?true:false;
        // let user = this.cookieService.getCookie(this.recentlyViewedCookieName);
        // if ( user == '' ) return false;
        // else return true;
        return ( this.pawsCookie && this.pawsCookie.recently_viewed != null) ;
    }

    /**
     * Returns if User is logged in. Should check
     *  - Has a user object stored
     *  - Has Token Object stored
     *  - Token hasn't expired.
     */
    public isLoggedIn() : boolean {
        // return (this.cookieService.getCookie("paws_access")!=''?true:false);
        return ( this.hasUserToken() && this.hasAccessToken() && !this.isTokenExpired() );
    }

    /**
     * Retrieve contents of Token Cookie and return iat ( Token Created timestamp from CAS )
     * @returns {number}
     */
    public getTokenCreatedTime(): number {
        if ( this.hasAccessToken() ) {
            let data = this.getToken();
            // let data = JSON.parse( <string>this.storage.getItem(this.accessTokenCookieName) );
            return data.iat;
        }
        return 0;
    }

    public getTokenExpiredAt(): number {
        if ( this.hasAccessToken() ) {
            let data = this.getToken();
            // let data = JSON.parse( <string>this.storage.getItem(this.accessTokenCookieName) );
            return data.expires_at;
        }
        return 0;
    }
    public getCookieExpiresAtInSeconds(): number {
        let createdAt = this.getTokenCreatedTime();
        let cookieLifetime = this.userCookieLifetime * 60 * 1000;
        return createdAt + cookieLifetime;
        // return ( this.userCookieLifetime * 60 * 1000 ) + ( this.getTokenExpiredAt() - ( this.getTokenExpiresTime() * 60 ) ) ;
    }

    public storeChannels( channels: Array<CustomChannelWithFavorites> ) {
        this.pawsCookie.channels = channels;
        this.storePawsCookie();
    }
    public getChannels(): Array<CustomChannelWithFavorites> {
        return this.pawsCookie.channels;
    }

    /**
     * Converts the userPreference for recently viewed into a channel ID array
     * @param userPref - The preference tha tneeds to be converted
     * @returns Array<number> List of channel IDs
     */
    public importRecentlyViewed( userPref:UserPreference): Array<number> {
        if(userPref.preference != null){
            let newArray:Array<number> = JSON.parse(<string> userPref.preference).id;
            this.storeRecentlyViewed(newArray);
            return newArray;
        }
        else{
            return null;
        }
    }

    /**
     * Gets the recently viewed array from the session storage
     * @returns Array<Number>
     */
    public getRecentlyViewed(): Array<number> {
        if (this.hasRecentlyViewed()){
            // let newArray:Array<number> = JSON.parse( <string>this.cookieService.getCookie(this.recentlyViewedCookieName) );
            let newArray:Array<number> = this.pawsCookie.recently_viewed;
            //console.log(newArray);
            return newArray;
        } else {
            let newArray:Array<number> = new Array<number>();
            //console.log(newArray);
            this.storeRecentlyViewed(newArray);
            return newArray;
        }
    }

    /**
     * Returns the current users display name
     * @returns {string}
     */
    public getUserName(): string {
        return ( this.pawsCookie && this.pawsCookie.user != null ? this.pawsCookie.user.displayName : '' );
    }

    /**
     * Returns the current users NSID
     * @returns {string}
     */
    public getNsid(): string {
        return ( this.hasUserToken() ? this.pawsCookie.user.nsid : '' );
    }

    public getRoles(): any {
        if ( this.hasUserToken() ) {
            let roles: any[] = [];
            this.pawsCookie.user.roles.forEach((r) => {
                roles.push(r.toLowerCase());
            });
            return roles;
        }
        return "";
        // return ( this.user != null ? this.user.roles : '' );
    }

    /**
     * Checks token expired time ( TokenCreated + TokenExpiresIn )
     * @returns {boolean}
     */
    public isTokenExpired( ): boolean {
        let now = Math.round( new Date().getTime() );
        let tokenExpiredAt = this.getTokenExpiredAt();
        // console.log( "now: " + now + " - " + new Date(now).toUTCString() );
        // console.log( "tokenExpiredAt: " + tokenExpiredAt + " - " + new Date(tokenExpiredAt).toUTCString() );
        if ( tokenExpiredAt > 0 ) {
            let ret = ( tokenExpiredAt < now );
            return ret;
        } else {
            return true;
        }
    }

    /**
     * Returns if the user has the provided role
     * @param role
     * @returns {boolean}
     */
    public hasRole( role: String ) {
        let hasRole = false;
        this.pawsCookie.user.roles.forEach( (r) => {
            if ( r.toLowerCase() == role.toLowerCase() ) hasRole = true;
        });
        return hasRole;
    }

    public refreshUserToken( ) {
        let me = this;
        me.api.refreshToken().subscribe({
            next: (resp:any) => {
                me.storeToken(resp);
                return true;
            },
            error: (err:any) => {
                return false;
            }
        });
    }

    public isAdmin(): Boolean {
        return this.hasRole( this.adminRole );
    }

}
