<div *ngIf="redirectToCas" class="backgroundMask text-center">
    <div class="h2" data-cy="errorTitle">Redirect to CAS</div>
    <div class="mtlg h5">Please wait.</div>
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <p>If you are not automatically taken to cas, click <a href='{{ CASOauthURL }}'>here</a></p>
</div>
<div *ngIf="redirectToPAWS" class="backgroundMask text-center">
    <div class="h2" data-cy="errorTitle">Redirect to PAWS</div>
    <div class="mtlg h5">Please wait.</div>
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <p>If you are not automatically taken to cas, click <a href='{{ CASOauthURL }}'>here</a></p>
</div>
<div *ngIf="loadingUser" class="backgroundMask text-center">
    <div class="h2" data-cy="errorTitle">Retrieving your Account</div>
    <div class="mtlg h5">Please wait.</div>
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>
<div *ngIf="refreshLogin" class="backgroundMask text-center">
    <div class="h2" data-cy="errorTitle">Refreshing your authentication</div>
    <div class="mtlg h5">Please wait.</div>
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>
<div *ngIf="casAuthError" class="backgroundMask text-center">
    <div class="h2" data-cy="errorTitle">Authentication Error</div>
    <div class="mt-3 h5">There was an error verifying your login. It may have expired. </div>
    <p class="m-3 h6">{{ errorMessage }}</p>
    <div class="mt-3">
        <a class="btn btn-primary me-2" [routerLink]="['/logout']" queryParamsHandling="preserve">Login Again</a>
        <button class="btn btn-secondary" href="#">My Profile</button>
    </div>
</div>
<div *ngIf="networkError" class="backgroundMask text-center">
    <div class="h2" data-cy="errorTitle">System Error</div>
    <div class="mt-3 h5">There was a problem accessing the server. It may be down or otherwise inaccessible.<br/> Please wait a moment and try again.</div>
    <p class="m-3 h6">{{ errorMessage }}</p>
    <div class="mt-3">
        <a class="btn btn-primary me-2" [routerLink]="['/logout']" queryParamsHandling="preserve">Login Again</a>
        <button class="btn btn-secondary" href="#">My Profile</button>
    </div>
</div>
<div *ngIf="userExpiredError" class="backgroundMask text-center">
    <div class="h2" data-cy="errorTitle">Your account is Disabled</div>
    <div class="mtlg h5">Please contact IT Support Services.</div>
    <div class="mt-3">
        <a class="btn btn-primary me-2" [routerLink]="['/logout']">Logout</a>
    </div>
</div>
<div *ngIf="userNotFoundError" class="backgroundMask text-center">
    <div class="h2" data-cy="errorTitle">Your account was not found</div>
    <div class="mt-3 h5">You do not have permission to access PAWS. You can request access by contacting <a href="{{ helpLink }}">IT Support Services</a></div>
    <p class="m-3 h6">{{ errorMessage }}</p>
    <div class="mt-3">
        <a class="btn btn-primary me-2" [routerLink]="['/logout']">Logout</a>
        <button class="btn btn-secondary" href="#">My Profile</button>
    </div>
</div>
<div *ngIf="tokenError" class="backgroundMask text-center">
    <div class="h2" data-cy="errorTitle">Your Authentication token is missing or expired.</div>
    <div class="mt-3 h5">To renew your token, please login again.</div>
    <div class="mt-3 h5 text-warning">If this message continues, please wait 5 minutes before trying again.</div>
    <p class="m-3 h6">{{ errorMessage }}</p>
    <div class="mt-3">
        <a class="btn btn-primary me-2" [routerLink]="['/logout']" queryParamsHandling="preserve">Login Again</a>
        <button class="btn btn-secondary" href="#">My Profile</button>
    </div>
</div>
