import {Component, OnInit} from '@angular/core';
import {ApiRequestService} from "../../services/api-request.service";
import {Event} from '../../model/event';
import {format} from 'date-fns';
import {CalendarDay} from 'src/app/model/calendarDay';
import {Category} from "../../model/category";
import {TimelinePreference} from 'src/app/model/timelinePreference';
import {CalendarData} from 'src/app/model/calendarData';
import {UserService} from "../../services/user.service";
import {MetricsService} from "../../services/metrics.service";
import {AppConfigService} from "../../app-config.service";

/**
 * ### Calendar Component
 *
 * Creates and displays main page calendar widget.
 */
@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    /**
     * Label used for Calendar header
     */
    public days_labels: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    /**
     * Marker for the currently shown month.
     */
    public currentMonthYear = {
        current_date: new Date(),
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    };
    /**
     * Current focused date
     * @type {Date}
     */
    public targetDate = new Date();

    public today = new Date();
    /**
     * Full list of categories available
     * @type {array}
     */
    public calendarCategories: Array<Category> = [];
    /**
     * Array of user preferences indicating categories opted out.
     */
    public userPrefs: Array<TimelinePreference> = [];
    /**
     * Populate the calendar with a 2D array which is populated in O(n) time
     * @type {CalendarDay}
     */
    public calendarList: CalendarDay[][] = [];


    /**
     * IsLoaded flag to indicate fetch completion
     * @type {boolean}
     */
    public isLoaded: boolean = true;
    /**
     * Calendar title. Month Year
     * @type {string}
     */
    public title = '';
    /**
     * Raw event data from API
     * @type {Array<Event>}
     */
    public eventList: any = [];
    /**
     * Parsed and processed event data suitable for display
     * @type {Array<Event>}
     */
    public eventData = new Map<string, Event[]>();
    /**
     * Flag to include next day events. Should be true if showing today.
     * @type {boolean}
     */
    public includeTomorrow: boolean = false;
    /**
     * Loading flag for saving preferences
     * @type {boolean}
     */
    public prefLoading: boolean = false;
    /**
     * Failed flag for saving preferences
     * @type {boolean}
     */
    public prefFailed: boolean = false;
    /**
     * Flag to try saving again
     */
    public prefTryAgain: boolean = false;
    /**
     * Success flag for saving preferences
     * @type {boolean}
     */
    public prefSuccess: boolean = false;

    public countDownEvents: any = [];

    /**
     * Controls Hover events on Calendar Month controls.
     */
    public caretLeftHover: boolean = false;
    public caretRightHover: boolean = false;

    /**
     * Component Constructor
     * Injects API Service
     * @param apiService
     * @param userService
     * @param metricService
     */
    constructor(
        private apiService: ApiRequestService,
        private userService: UserService,
        private metricService: MetricsService,
        private appConfig: AppConfigService
    ) {
    }

    /**
     * On component Init Method
     * Triggers API fetch call for event data
     */
    ngOnInit(): void {
        // console.log( this.targetDate.getDate() );
        let me = this;
        if ( this.userService.hasAccessToken() ) {
            me.fetchEvents();
            me.loadCountDownEvents();
            me.populateCalendarModels(me.currentMonthYear);
            performance.clearMarks("calendarStart");
            performance.clearMarks("calendarComplete");
            performance.clearMeasures("calendarMeasure");
            performance.mark("calendarStart");
        }
    }

    private loadCountDownEvents() {
        let me = this;

        this.apiService.getCountDownEvents().subscribe({
            next: (resp: any) => {
                me.countDownEvents = resp;
            },
            error: (err: any) => {
                me.metricService.logMetric({name: err.error.name,value: err.error.message});
            }
        });
    }

    public isOneDayBeforeCountDownEvent(title: string) {
        if (title) {
            return title.includes("1 day ");
        } else {
            return false;
        }
    }

    public isTodayCountDownEvent(title: string) {

        let regex: RegExp = /[0-9]?[0-9] [dD]ay[s]?/;

        if (regex.test(title)) {
            return false;
        } else {
            return true;
        }
    }

    public populateCalendarModels(params: any) {
        // let today = new Date();

        var months_labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        var days_in_month = getDaysInMonth(params.month, params.year),
            first_day_date = new Date(params.year, params.month, 1),
            first_day_weekday = first_day_date.getDay();

        var prev_month = params.month == 0 ? 11 : params.month - 1,
            prev_year = prev_month == 11 ? params.year - 1 : params.year,
            prev_days = getDaysInMonth(prev_month, prev_year);

        // calendar header
        this.title = months_labels[params.month] + ' ' + params.year;

        function getDaysInMonth(month: any, year: any) {
            // 0 = last day of the previous month
            return new Date(year, month + 1, 0).getDate();
        }

        // week days labels
        for (var i = 0; i <= 6; i++) {
            this.days_labels[i];

        }

        var w = 0; // week day
        var n = 1; // next days date
        var c = 1; // current date
        let me = this;
        me.calendarList = [];
        // dates loop
        var calendarWeek: CalendarDay[] = [];
        for (var i = 0; i < 6 * this.days_labels.length; i++) {

            //Create Day
            var calendarDay: CalendarDay = {};
            calendarDay.date_stamp = "";
            calendarDay.calendar_day = 0;
            calendarDay.title = "";
            calendarDay.in_month = false;
            calendarDay.is_active = false;
            calendarDay.is_today = false;
            calendarDay.is_selected = false;

            if (w == 0) {
                // first week's day
                // clear array
                calendarWeek = [];
            }

            if (i < new Date(params.year, params.month, 1).getDay()) {
                // previous month's day
                let day = prev_days - first_day_weekday + i + 1;
                calendarDay.calendar_day = day;
                let display_date = new Date(params.year, params.month, day);
                let display_date_class = format(display_date, "yyyy-MM-dd");
                calendarDay.date_stamp = display_date_class;


            } else if (c > days_in_month) {
                // next month's day

                calendarDay.calendar_day = n;
                let display_date = new Date(params.year, params.month, n);
                let display_date_class = format(display_date, "yyyy-MM-dd");
                calendarDay.date_stamp = display_date_class;
                n++;
            } else {
                // current month's day
                // let day:calendarDay =
                let options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'} as const;
                let display_date = new Date(params.year, params.month, c);

                let display_date_class = format(display_date, "yyyy-MM-dd");

                let has_event = me.eventData.get(display_date_class) != undefined;

                //console.log(has_event);

                //console.log(this.eventData.filter(x =>));

                //me.dateList.push(display_date_class);
                //console.log(display_date_class);
                let isToday = (format(display_date, "yyyy-MM-dd") == format(this.today, "yyyy-MM-dd"));
                let isSelected = (format(me.targetDate, "yyyy-MM-dd") == format(display_date, "yyyy-MM-dd"));

                let title = display_date.toLocaleDateString("en-GB", options);

                calendarDay.title = title;
                calendarDay.date_stamp = display_date_class;
                calendarDay.calendar_day = c;
                calendarDay.is_today = isToday;
                calendarDay.is_active = isToday;
                calendarDay.is_selected = isSelected;
                calendarDay.has_event = has_event;
                calendarDay.in_month = true;
                // @ts-ignore
                c++;
            }
            calendarWeek.push(calendarDay);

            if (w == this.days_labels.length - 1) {
                // last week's day
                // push to array
                this.calendarList.push(calendarWeek);
                w = 0;
            } else {
                w++;
            }
        }

        // Session Storage Key: e.g. October2022
        //let calendarKey = params.month + params.year;

        //sessionStorage.setItem(calendarKey, JSON.stringify(me.calendarList) );

        //console.log(this.calendarList);
        //console.log(this.eventData);
    }

    /**
     * Method to format target date for display.
     * Should show 'Today' if todays date.
     */
    public displayTargetDate() {
        if (this.isToday()) {
            return "Today, " + format(this.targetDate, "LLL d")
        } else {
            return format(this.targetDate, "EEEE, LLL d")
        }
    }

    public displayTomorrow() {
        return "Tomorrow, " + format(this.getTomorrow(), "LLL d");
    }

    /**
     * Method to find if targetdate has events present in data
     */
    public hasEvents() {
        return (this.eventData.get(format(this.targetDate, "yyyy-MM-dd")) != undefined);
    }

    /**
     * Returns target Dates events from data array
     */
    public getEvents() {
        let events = this.eventData.get(format(this.targetDate, "yyyy-MM-dd"));
        if (events != undefined && events.length > 0) {
            events.sort((a, b) => a._startTime - b._startTime);
        }
        return events;
    }

    /**
     * Checks for if selected is today
     * @returns True if it is, false otherwise.
     */
    public isToday() {
        return format(this.today, "yyyy-MM-dd") == format(this.targetDate, "yyyy-MM-dd") ? true : false;
    }

    /**
     * Gets the date of tomorrow
     * @returns A date consisting of today plus one day
     */
    public getTomorrow() {
        let tomorrow = new Date(this.today.getTime());
        tomorrow.setDate(this.today.getDate() + 1);
        return tomorrow;
    }

    /**
     * Checks to see if tomorrow has events
     * @returns True if event data has an event for tomorrow, false otherwise
     */
    public hasTomorrowEvents() {
        return (this.eventData.get(format(this.getTomorrow(), "yyyy-MM-dd")) != undefined);
    }

    /**
     * Returns target tomorrow events from data array
     */
    public getTomorrowEvents() {
        let tomorrow = this.getTomorrow();

        let events = this.eventData.get(format(tomorrow, "yyyy-MM-dd"));
        if (events != undefined && events.length > 0) {
            events.sort((a, b) => a._startTime - b._startTime);
        }
        return events;
    }

    /**
     * Main method to process and parse data from API.
     * Converts to a single level array for display.
     * @private
     */
    public processEventList() {
        let me = this;
        if (me.eventList.data.length > 0) {
            me.eventList.data.forEach((source: any) => {
                me.assignColor(source.id, source.color);
                if (source.ical != undefined && source.ical.length == undefined) {
                    let feedName = me.getCategoryName(source.id);
                    for (let d in source.ical) {
                        source.ical[d].forEach((evt: Event) => {
                            let index = d.toString();
                            evt.color = source.color;
                            evt.source = feedName;
                            evt.collapsed = true;
                            if (me.eventData.get(index) == undefined)
                                me.eventData.set(index, new Array());
                            me.eventData.get(index)!.push(evt);
                        });
                    }
                }
            });
        }
        // console.log( "EVENTS: ", me.eventData );
    }

    /**
     * Sets color from api payload to calendarCategories object.
     * Used to show color of category in dropdown
     * @param catId
     * @param color
     * @private
     */
    private assignColor(catId: number, color: string) {
        for (let i = 0; i < this.calendarCategories.length; i++) {
            if (this.calendarCategories[i].unquieCategoryId == catId) {
                this.calendarCategories[i].color = color;
            }
        }
    }

    /**
     * Return category name given ID
     * @param id
     * @private
     */
    private getCategoryName(id: number) {
        let ret = "";
        this.calendarCategories.forEach((cat) => {
            if (cat.unquieCategoryId == id) ret = cat.name;
        });
        return ret;
    }

    /**
     * Method to trigger fetch from API
     * @private
     */
    public fetchEvents() {
        let me = this;
        // let calendarKey = me.currentMonthYear.month + me.currentMonthYear.year;
        me.eventList = [];
        me.eventData = new Map<string, Event[]>();
        me.populateCalendarModels(me.currentMonthYear);
        me.isLoaded = false;
        this.apiService.getEvents().subscribe({
            next: (resp: CalendarData) => {
                //console.log(resp);

                me.eventList = resp;
                me.isLoaded = true;
                me.calendarCategories = resp.categories;
                me.processEventList();

                if (resp.eventPrefs.length > 0) {
                    resp.eventPrefs.forEach((p: TimelinePreference) => {
                        me.calendarCategories.forEach((c: Category) => {
                            if (p.categoryId == c.unquieCategoryId)
                                c.optout = true;
                        });
                    });
                }
                me.calendarCategories.sort((a: any, b: any) => {
                    return (b.name < a.name ? 1 : -1)
                });
                performance.mark('calendarComplete');
                performance.measure('calendarMeasure', 'calendarStart', 'calendarComplete');
                me.metricService.logMetric( { name: "calendarMeasure Load Time (ms)", value: performance.getEntriesByName("calendarMeasure")[0].duration } );
                // console.log("calendarMeasure Load Time (ms): " + performance.getEntriesByName("calendarMeasure")[0].duration);
            },
            error: (error) => {
            },
            complete: () => {
                me.isLoaded = true;
                me.populateCalendarModels(me.currentMonthYear);
            }
        });
    }

    /**
     * Toggles the collapsed state of an calendar event.
     * @param item
     */
    public toggleCollapsed(item: any) {
        if (item.hasOwnProperty('collapsed')) {
            if (item.collapsed) {
                this.metricService.sendCalendarEventView( item.summary );
            }
            item.collapsed = !item.collapsed;
        }
    }

    public sendGACountdownEvent( title: string ) {
        this.metricService.sendCountdownEventClick( title );
    }
    /**
     * Populates Event list when a date is clicked.
     * @param date
     */
    public loadEventOnClick(date: any) {
        let slashedDate: string = date.replace(/-/g, '\/');
        let convertedDate: Date = new Date(slashedDate);
        let me = this;
        me.targetDate = convertedDate;
        //There might be a better way to optimize this
        me.populateCalendarModels(me.currentMonthYear);
    }

    /**
     * Sets new month. Used on month forward and back buttons are clicked.
     * @param inc
     */
    public setMonth(inc: number) {
        let me = this;
        let newMonth = me.currentMonthYear.current_date;
        newMonth.setMonth(newMonth.getMonth() + inc);
        me.currentMonthYear = {
            current_date: newMonth,
            month: newMonth.getMonth(),
            year: newMonth.getFullYear()
        };
        me.targetDate = newMonth;
        me.targetDate.setDate(1);
        // me.fetchEvents();
        me.populateCalendarModels(me.currentMonthYear);
    }

    /**
     * Toggle prefernece optin / out on checkbox click.
     * @param cat
     */
    public togglePreference(cat: Category) {
        cat.optout = !cat.optout;
    }

    /**
     * Save preferences to API
     */
    public savePreferences() {
        let me = this;
        let params: Array<any> = [];
        this.prefLoading = true;
        this.prefSuccess = false;
        this.prefFailed = false;
        me.prefTryAgain = false;
        this.calendarCategories.forEach((cat) => {
            if (cat.optout) {
                params.push({
                    categoryId: cat.unquieCategoryId,
                    code: cat.code,
                    unquieCategoryId: (cat.unquieCategoryId != undefined ? cat.unquieCategoryId : 0),
                    source: cat.source
                });
            } //else if (cat.categoryId != undefined) params.push({categoryId: cat.id, unquieCategoryId: 0, source: cat.source});
        });
        this.apiService.saveCalendarPreferences(params).subscribe({
            next: (resp) => {
                me.prefLoading = false;
                me.prefSuccess = true;
            }, error: (err: any) => {
                if (err.status == 401) {
                    if (me.userService.isTokenExpired()) {
                        me.userService.refreshUserToken();
                        me.prefLoading = false;
                        me.prefTryAgain = true;
                    } else {
                        me.prefLoading = false;
                        me.prefFailed = true;
                    }
                } else {
                    me.prefLoading = false;
                    me.prefFailed = true;
                }
            }, complete: () => {
                setTimeout(() => {
                    //console.log( 'closing ' );
                    me.prefLoading = false;
                    me.prefSuccess = false;
                    me.prefFailed = false;
                    if (document.getElementById("calendarsettings-label").getAttribute("aria-expanded") == 'true')
                        document.getElementById("calendarsettings-label").click();
                    me.fetchEvents();
                }, this.appConfig.getNotificationTimeoutInMiliSeconds());
            }
        });
    }

}
