import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UserService} from "../../services/user.service";
import {SCREEN_SIZE} from '../../services/screen-size.enum';
import {ResizeService} from "../../services/resize.service";
import {Params, Router} from "@angular/router";


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

    public searchTerm = new FormControl('');
    prefix = 'is-';
    sizes = [
        {
            id: SCREEN_SIZE.XS, name: 'xs', css: `d-block d-sm-none`
        },
        {
            id: SCREEN_SIZE.SM, name: 'sm', css: `d-none d-sm-block d-md-none`
        },
        {
            id: SCREEN_SIZE.MD, name: 'md', css: `d-none d-md-block d-lg-none`
        },
        {
            id: SCREEN_SIZE.LG, name: 'lg', css: `d-none d-lg-block d-xl-none`
        },
        {
            id: SCREEN_SIZE.XL, name: 'xl', css: `d-none d-xl-block`
        },
    ];

    public calendarOffset: number = 0;

    constructor(
        private userService: UserService,
        private elementRef: ElementRef,
        private resizeSvc: ResizeService,
        private router: Router
    ) {
    }

    @HostListener("window:resize", [])
    private onResize() {
        this.isMobile();
    }

    ngOnInit(): void {
        this.isMobile();
    }

    ngDoCheck() {
        this.calendarOffset = this.getCalendarAppOffset();
    }
    public isLoggedIn() {
        return this.userService.isLoggedIn();
    }
    public isMobile(): boolean {
        const el = this.elementRef.nativeElement.querySelector(`#home-page-tab-controls`);
        const isVisible = window.getComputedStyle(el).display != 'none';
        this.resizeSvc.onResize(isVisible);
        return isVisible;
    }
    private detectScreenSize() {
        const currentSize = this.sizes.find(x => {
            const el = this.elementRef.nativeElement.querySelector(`#home-page-tab-controls`);
            const isVisible = window.getComputedStyle(el).display != 'none';
            return isVisible;
        });
        // console.log( currentSize );
        // this.resizeSvc.onResize(currentSize.id);
    }
    public getCalendarAppOffset() {
        return document.querySelector("#homepage-top").clientHeight;
    }

    public submitSearch() {
        let queryParams: Params = {};
        queryParams['keyword'] = this.searchTerm.getRawValue();
        this.router.navigate(['/search'],{queryParams: queryParams});
    }
}
