import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ChannelsService} from 'src/app/services/channels.service';
import {UserService} from "../../services/user.service";
import {User} from "../../model/user";
import {MetricsService} from "../../services/metrics.service";
import {CustomChannelWithFavorites} from "../../model/customChannelWithFavorites";
import {AppConfigService} from "../../app-config.service";

declare var Porthole: any;

function _document(): any {
    // return the global native browser window object
    return document;
}

/**
 * ### Channel Component
 *
 * Loads and displays a PAWS channel
 */
@Component({
    selector: 'app-channel',
    templateUrl: './channel.component.html',
    styleUrls: ['./channel.component.scss']
})
export class ChannelComponent implements OnInit, OnDestroy {

    private id: string | null = '';
    public currentChannel: CustomChannelWithFavorites | null = null;

    private window;
    public windowProxy: any;
    private showBackBtn: boolean = false;
    public subpage: string = null;
    public subPageTitle: string = null;
    public bannerUrl: string = null;

    /**
     * ['complete','loading','not_found','ineligible']
     */
    public state: string = 'loading';

    /**
     * Component constructor
     * @param window
     * @param activatedRoute
     * @param channelsService
     * @param userService
     * @param metricService
     * @param router
     */
    constructor(
        private activatedRoute: ActivatedRoute,
        private channelsService: ChannelsService,
        private userService: UserService,
        private router: Router,
        private metricService: MetricsService,
        private appConfig: AppConfigService,
        @Inject('Window') window: Window,
    ) {
        this.window = window;
        let me = this;
        me.currentChannel = null;
        me.setState();
    }

    public get getShowBackBtn() {
        return this.showBackBtn;
    }

    /**
     * Private function to control the state of loading.
     * @private
     */
    private setState() {
        //console.log( this.currentChannel );
        if (this.currentChannel != null) {
            if ( this.currentChannel.has_access ) {
                this.state = "complete";
            } else {
                this.state = 'ineligible';
            }
        } else if (this.state == 'loading' && this.currentChannel == null && this.channelsService.channelLoadComplete) {
            this.state = "not_found";
        }
    }

    /**
     * Component onInit. Triggers ChannelService to load channels if not loaded.
     * Initializes iframe message listener
     */
    ngOnInit(): void {
        let me = this;
        this.activatedRoute.params.subscribe({
            next: (routeParams) => {
                performance.clearMarks("ChannelStart");
                performance.clearMarks("ChannelComplete");
                performance.clearMarks("IFrameLoaded");
                performance.clearMeasures("iframeLoading");
                performance.clearMeasures("channelLoading");
                performance.mark('ChannelStart');
                me.currentChannel = null;
                me.id = null;
                me.state = 'loading';
                me.window.scrollTo(0, 0);
                const iframe = _document().querySelector(".content-body iframe");
                iframe.style.height = "75vh";
                iframe.innerHTML = "";

                //console.log(routeParams);
                if (routeParams['sub'] != undefined && routeParams['sub'] != '') {
                    me.subpage = routeParams['sub'];
                }

                if (routeParams['id'] != '') {
                    me.id = routeParams['id'].replace('#', '');
                    if (me.channelsService.channelLoadComplete) {
                        me.loadChannel();
                    } else {
                        me.channelsService.channelsReady$.subscribe({
                            next: () => {
                                me.loadChannel();
                            }
                        });
                    }
                }

            }
        });
    }

    ngAfterViewInit(): void {
        // console.log( "Channel Component AfterViewInit" );
        let me = this;
        let user: User = <User>this.userService.getUser();

        me.windowProxy = new Porthole.WindowProxy('*', 'iframeChannel');
        me.windowProxy.addEventListener((messageEvent: MessageEvent) => {
            try {
                //console.log( "windowProxy Received: ", messageEvent.data );
                //me.metricService.sendError( JSON.stringify( { name: "windowProxy Received", page: me.currentChannel.url, channel: me.currentChannel.goname, obj: messageEvent.data } ) );
                if (messageEvent.origin.match(/wcmschannels(test|dev)?.usask.ca$/)) {
                    let data = messageEvent.data;
                    if (data.action == 'feedback' && data.event) {
                        me.metricService.sendFeedback( data.event );
                    }
                }
                if (messageEvent.origin.match(/.usask.ca$/)) {
                    const iframe = _document().querySelector(".content-body iframe");
                    let data = messageEvent.data;
                    //console.log( "windowProxy: ", data );
                    if (data.action === 'setHeight') {
                        let newIframeHeight = data.height;
                        iframe.style.height = newIframeHeight + "px";
                    } else if ( data.action == 'getNsid' ) {
                        // @ts-ignore
                        messageEvent.source.post( user.nsid );
                    } else if (data.action == 'hasRole' && data.role) {
                        // @ts-ignore
                        messageEvent.source.post(me.userService.hasRole(data.role));
                    } else if (data.action == 'getScrollPosition') {
                        // @ts-ignore
                        messageEvent.source.post( { action: 'getScrollPosition', result: this.window.pageYOffset - 155 });
                    } else if (data.action == 'gotoTop') {
                        this.window.scrollTo(0, 0);
                    } else if (data.action == 'gotoPosition' && data.height) {
                        var addHeight = data.height + 155;
                        this.window.scrollTo(addHeight, 0);
                    } else if (data.action == 'getDomain') {
                        // @ts-ignore
                        messageEvent.source.post( { action: 'getDomain', result: this.appConfig.getDomain() });
                    } else if (data.action == 'gotoLink' && data.href != '') {
                        me.router.navigate([me.currentChannel.goname + '/' + data.href]);
                    } else if (data.action == 'getRoles') {
                        // @ts-ignore
                        messageEvent.source.post(user.roles, '*');
                    } else if ( data.action == 'hasRoles' && data.roles != '' && data.element != '' ) {
                        let hasRoles = false;
                        let roleList = data.roles.trim().split(',');
                        if ( user.roles.length > 0 ) {
                            user.roles.forEach((role) => {
                                if ( roleList.includes(role) ) hasRoles = true;
                            })
                        }
                        // @ts-ignore
                        messageEvent.source.post( { action: 'hasRoles', result: hasRoles, element: data.element } );
                    } else if (data.action == "BannerURL") {
                        if (data.url.includes("fz_pcard")) {
                            this.window.open(data.url);
                        } else {
                            this.state = "loading";
                            // this.subpage = data.url;
                            this.showBackBtn = true;
                            this.window.scrollTo(0, 0);
                            this.bannerUrl = data.url;
                        }
                    } else if (data.action == 'ChannelSubPage' && data.url != '' ) {
                        let urlPieces = data.url.split('/');
                        let page = urlPieces[ urlPieces.length - 1 ];
                        me.router.navigate([me.currentChannel.goname + '/' + page]);
                    } else if ( data.action == 'OpenPawsChannel' && data.goname != '' ) {
                        me.router.navigate([ data.goname ]);
                    } else if ( data.action == 'setSubPageTitle' && data.title != '' ) {
                        me.subPageTitle = data.title;
                    }
                }
            } catch (e) {
            }
        });
        // This is added to cover BannerURL links
        this.router.events.subscribe({
            next: (evt) => {
                if (evt instanceof NavigationEnd) {
                    // console.log("NavigationEnd ... ", evt);
                    me.bannerUrl = null;
                    me.showBackBtn = false;
                    me.subpage = null;
                    me.subPageTitle = null;
                }
            }
        });
        performance.mark('ChannelComplete');
        performance.measure('channelLoading', 'ChannelStart', 'ChannelComplete');
        me.metricService.logMetric( { name: "ChannelComplete Load Time (ms)", value: performance.getEntriesByName("channelLoading")[0].duration } );
        // console.log("ChannelComplete Load Time (ms): " + performance.getEntriesByName("channelLoading")[0].duration);
    }

    ngOnDestroy() {
        this.windowProxy.removeEventListener();
    }

    public resetIFrameUrl(event: Event) {
        // const iframe = _document().querySelector(".content-body iframe");
        // iframe.src = this.channelURL;
        event.preventDefault();
        this.bannerUrl = null;
        this.subpage = null;
        this.subPageTitle = null;
        this.showBackBtn = false;

    }

    public iframeLoaded() {
        if (this.currentChannel != null) {
            performance.mark('IFrameLoaded');
            performance.measure('iframeLoading', 'ChannelStart', 'IFrameLoaded');
            this.metricService.logMetric( { name: "IFrame Load Time (ms)", value: performance.getEntriesByName("iframeLoading")[0].duration } );
            this.setState();
        }
    }

    public iframeError() {
        // console.log( "IFrame ERROR" );
    }

    /**
     * Load the current channel from service.
     */
    public loadChannel() {
        this.state = "loading";
        if (this.id != '') {
            let c = this.channelsService.getChannel(this.id);
            if (c != null) {
                this.currentChannel = c;
                this.saveRecentlyViewed();
                // const iframe = _document().querySelector(".content-body iframe");
                // if ( iframe != null ) {
                //     iframe.src = this.channelURL;
                //     // this.state = 'complete';
                //     this.saveRecentlyViewed();
                // }
            } else {
                this.state = "not_found";
            }
        } else {
            this.state = "not_found";
        }
    }

    /**
     * Saves the recently viewed locally and in session storage
     */
    public saveRecentlyViewed() {
        let me = this;
        let c = me.currentChannel;
        if (c != null) {
            if (!c.is_favorite && !me.isIcon && !(c.category == "My College")) {
                me.channelsService.updateRecentlyViewed(c.id);
            }
        }
    }

    /**
     * Property method to return Channel Title
     */
    public get channeltitle() {
        if (this.currentChannel != null) return this.currentChannel.title;
        else return null;
    }

    /**
     * Property method to return channel URL. Will consider ENV
     */
    public get channelURL(): string {
        let url = '';

        if ( this.bannerUrl != null ) {
            return this.bannerUrl;
        } else if (this.currentChannel != null) {
            url = this.currentChannel.url;
            if (this.subpage != null) {
                let regex = new RegExp(/https:\/\/portalapps(dev|test)?.usask.ca\//);
                if ( regex.test( this.subpage ) == true ) {
                    url = this.subpage;
                } else {
                    let urlPieces = url.split("/");
                    if (urlPieces[urlPieces.length - 1] == '') urlPieces.pop();
                    if (urlPieces[urlPieces.length - 1].includes(".php")) urlPieces[urlPieces.length - 1] = this.subpage.toString();
                    else urlPieces.push(this.subpage.toString());
                    url = urlPieces.join("/");
                }
            }
        }
        return url;
    }

    /**
     * Property function: is current channel a favorite
     * @return  {boolean}
     */
    public get isChannelFavorite(): boolean {
        if (this.currentChannel != null) return this.currentChannel.is_favorite;
        else return false;
    }

    /**
     * Return if user is logged in.
     */
    public isLoggedIn(): boolean {
        return this.userService.isLoggedIn();
    }

    public getHelpLink() {
        return this.appConfig.getHelpLink();
    }

    public getDomain() {
        return this.appConfig.getDomain();
    }
    /**
     * Determines if it is an icon by seeing if a related channel with a category called "Icon" exists.
     * Usually an icon channel with two channels if it is non-external and the one that previews is the non-icon channel.
     */
    public get isIcon(): boolean {
        if (this.currentChannel != null) {
            return (this.currentChannel.is_icon || this.channelsService.hasAlternateIcon(this.currentChannel));
        } else return false;
    }

    /**
     * Determines if it is a channel with the "My College" category
     */
    public get isMyCollege(): boolean {
        let c = this.currentChannel;
        if (c != null) {
            if (c.category == "My College") {
                return true;
            } else {
                return false
            }
        } else return false;
    }

    public get showIframe() {
        return (this.state == 'loading' || this.state == 'complete');
    }

    /**
     * Adds a favorite after clicking a button. Calls on channel service to make this happen.
     */
    public addFavorite() {
        let me = this;
        me.channelsService.addFavorite(me.currentChannel.id).subscribe(
            {
                next(resp: any) {
                    let displayOrder = resp.displayOrder;
                    if (displayOrder != null || displayOrder != undefined) {
                        me.currentChannel.display_order = displayOrder;
                        me.currentChannel.is_favorite = true;
                        me.channelsService.setFavorite(me.currentChannel.is_favorite, me.currentChannel.goname, displayOrder);
                        me.channelsService.deleteRecentlyViewed(me.currentChannel.id);
                        me.metricService.sendAddFavorite( me.currentChannel.title );
                    }
                },
                error(err) {
                    me.metricService.logMetric({name: err.error.name,value: err.error.message});
                },
                complete() {
                }
            });
    }

    /**
     * Deletes a favorite after clicking a button. Calls on channel service to make this happen.
     */
    public deleteFavorite() {
        let me = this;
        me.channelsService.deleteFavorite(me.currentChannel.id).subscribe(
            {
                next() {
                    me.currentChannel.display_order = null;
                    me.currentChannel.is_favorite = false;
                    me.channelsService.setFavorite(me.currentChannel.is_favorite, me.currentChannel.goname, null);
                    me.metricService.sendRemoveFavorite(me.currentChannel.title);
                    me.saveRecentlyViewed();
                },
                error(err) {
                    me.metricService.logMetric({name: err.error.name,value: err.error.message});
                },
                complete() {
                }
            });
    }
}
