import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {ChannelsService} from 'src/app/services/channels.service';
import {distinctUntilChanged, Subscription} from "rxjs";
import {CustomChannelWithFavorites} from "../../model/customChannelWithFavorites";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MetricsService} from "../../services/metrics.service";
import {UserService} from "../../services/user.service";
import {AppConfigService} from "../../app-config.service";
import {ApiRequestService} from "../../services/api-request.service";
import {ListItem} from "../../model/listItem";
import {CategoryList} from "../../model/categoryList";

/**
 * DOM Object Reference
 */
// declare var document: any;

@Component({
    selector: 'app-channel-list',
    templateUrl: './channel-list.component.html',
    styleUrls: ['./channel-list.component.scss']
})
export class ChannelListComponent implements OnInit {

    public channelList: Array<CustomChannelWithFavorites> = [];
    public sortedList: Array<any> = [];
    public viewedList: Array<CategoryList> = [];

    public excludeCategories = [
        'Icon'
    ];

    public subscriptions = new Subscription();

    public get showIneligibleChannels(): Boolean {
        return ( this.appConfig.getShowIneligibleChannels() );
    }
    public get showChannelEligibility(): Boolean {
        return ( this.appConfig.getShowChannelEligibility() );
    }

    constructor(
        private channelService: ChannelsService,
        private metricsService: MetricsService,
        private userService: UserService,
        private appConfig: AppConfigService,
    ) {
    }

    ngOnInit(): void {
        let me = this;
        me.channelList = me.channelService.getAllChannels();
        me.viewedList = me.sortIntoCategories();
        this.channelService.channelsReady$.subscribe( () => {
            me.channelList = me.channelService.getAllChannels();
            me.viewedList = me.sortIntoCategories();
        } );
    }

    public ngOnDestroy(): void
    {
        this.subscriptions.unsubscribe();
        // ensure when component is destroyed the subscription is also and not left open.
    }

    public get categoryList() {
        let me = this;
        let categories: Array<string> = [];
        me.channelList.forEach( (el:CustomChannelWithFavorites) => {
            if ( !this.excludeCategories.includes( el.category ) ) {
                if ( !categories.includes( el.category ) ) {
                    categories.push( el.category );
                }
            }
        });
        return categories;
    }

    public sortIntoCategories() {
        let me = this;
        // let newList: any = [];
        let categories: Array<string> = [];
        let newViewedList: Array<CategoryList> = [];
        me.channelList = me.channelList.sort((a:any, b:any) => a.title.localeCompare(b.title));
        me.channelList.forEach( (el:CustomChannelWithFavorites) => {
            if ( !this.excludeCategories.includes( el.category ) ) {
                if (categories.includes(el.category)) {
                    newViewedList.forEach( (item: any,index: number) => {
                        if ( newViewedList[index].title == el.category ) {
                            let newItem: ListItem = {
                                title: el.title,
                                description: el.description,
                                keywords: el.keywords,
                                goname: el.goname,
                                is_visible: true,
                                is_public: el.is_public,
                                included_roles: ( el.include_roles == null ? [] : el.include_roles.split(',') ),
                                excluded_roles: ( el.exclude_roles == null ? [] : el.exclude_roles.split(',') ),
                                has_access: this.channelService.hasChannelAccess(el),
                                eligibility: el.eligibility
                            }
                            if ( newItem.has_access ) newViewedList[index].items.push( newItem );
                            else if ( me.showIneligibleChannels ) newViewedList[index].hidden_items.push( newItem );
                       }
                    } );
                } else {
                    categories.push(el.category);
                    let newItem: ListItem = {
                        title: el.title,
                        description: el.description,
                        keywords: el.keywords,
                        goname: el.goname,
                        is_visible: true,
                        is_public: el.is_public,
                        included_roles: ( el.include_roles == null ? [] : el.include_roles.split(',') ),
                        excluded_roles: ( el.exclude_roles == null ? [] : el.exclude_roles.split(',') ),
                        has_access: this.channelService.hasChannelAccess(el),
                        eligibility: el.eligibility
                    };
                    let newList: CategoryList = {
                        title: el.category,
                        is_visible: true,
                        items: [ ],
                        hidden_items: []
                    }
                    if ( newItem.has_access ) newList.items.push( newItem );
                    else if ( me.showIneligibleChannels ) newList.hidden_items.push( newItem );
                    newViewedList.push( newList );
                }
            }
        } );

        newViewedList = newViewedList.sort((a:any, b:any) => a.title.localeCompare(b.title));
        return newViewedList;
    }

    public sendGAClickEvent( title: string ) {
        if ( title != '' ) {
            this.metricsService.sendChannelListClick(title);
        }
    }

    public hasRole( role: string ) {
        return this.userService.hasRole(role);
    }

}
