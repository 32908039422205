import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './services/auth-guard.service';
import {HomePageComponent} from './components/home-page/home-page.component';
import {LoginComponent} from "./components/login/login.component";
import {LogoutComponent} from "./components/logout/logout.component";
import {ChannelComponent} from "./components/channel/channel.component";
import {ChannelListComponent} from "./components/channel-list/channel-list.component";
import {ErrorpageComponent} from "./components/errorpage/errorpage.component";
import {SearchComponent} from "./components/search/search.component";

const routes: Routes = [
    { path: "", component: HomePageComponent, canActivate: [AuthGuardService] },
    { path: "error", component: ErrorpageComponent },
    { path: "login", component: LoginComponent },
    { path: "logout", component: LogoutComponent },
    { path: "go/search", component: SearchComponent, canActivate: [AuthGuardService] },
    { path: "search", component: SearchComponent, canActivate: [AuthGuardService] },
    { path: "services", component: ChannelListComponent, canActivate: [AuthGuardService] },
    { path: "go/:id", redirectTo: ":id", pathMatch: 'full' },
    { path: ":id/:sub", component: ChannelComponent, canActivate: [AuthGuardService] },
    { path: ":id", component: ChannelComponent, canActivate: [AuthGuardService] },
    // { path: "**", component: HomePageComponent, canActivate: [AuthGuardService] },
];

@NgModule({
    imports: [
        RouterModule,
        RouterModule.forRoot(routes, { enableTracing: false, useHash: false, onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
