import {Injectable} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {Subscription} from "rxjs";

@Injectable({providedIn: 'root'})
export class PromptUpdateService {
    isNewVersionAvailable: boolean = false;
    newVersionSubscription: Subscription;
    constructor(
        private swUpdate: SwUpdate,
    ) {
        this.checkForUpdate();
    }
    checkForUpdate(): void {
        this.newVersionSubscription?.unsubscribe();
        console.log("PromptUpdateService");
        this.newVersionSubscription = this.swUpdate.versionUpdates
            //.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY') )
            .subscribe(evt => {
                switch (evt.type) {
                    case 'VERSION_DETECTED':
                        console.log(`Downloading new app version: ${evt.version.hash}`);
                        break;
                    case 'VERSION_READY':
                        console.log(`Current app version: ${evt.currentVersion.hash}`);
                        console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                        document.location.reload();
                        break;
                    case 'VERSION_INSTALLATION_FAILED':
                        console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                        break;
                }
                if ("latestVersion" in evt) {
                    console.log(`New app version ready for use: '${evt.latestVersion.hash}'`);
                }
            });
    }

}
